import { InfoModalComponent } from './../../../../shared/modules/info-modal/info-modal/info-modal.component';
import { AuthenticationService } from './../../../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/model/user.model';
import { ImageCropperModalComponent } from '../image-cropper-modal/image-cropper-modal.component';
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
	CookieStorage
} from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { isObject } from 'rxjs/internal-compatibility';

@Component({
	selector: 'app-profile-page',
	templateUrl: './profile-page.component.html',
	styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
	public hidePW: boolean = true;
	public user: User;

	imageChangedEvent: any = '';
	croppedImage: any = '';
	file: File;

	public pwdRegex = /^(?=.*[A-Za-z])[A-Za-z][A-Za-z\d!@$%&*]{8,}$/;
	public oldPassword: string = '';
	public password: string = '';
	public pwRetype: string = '';

	constructor(
		private event: EventService,
		private router: Router,
		public api: ApiService,
		private route: ActivatedRoute,
		private auth: AuthenticationService,
		private dialog: MatDialog
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);

		this.getUser();
	}

	ngOnInit(): void {
		this.auth.refreshToken();
	}

	public hidePassword() {
		this.hidePW = !this.hidePW;
	}

	getUser() {
		this.user = this.auth.currentUserValue;
		let url = this.user?.profilePicture?.formats?.small?.url
			 || this.user?.profilePicture?.formats?.thumbnail?.url
			 || null;
		if (this.user?.profilePicture && url) this.user.profilePicture.url = url;

	}

	selectTypeHandler(event: any) {}

	compareFn(a, b) {
		// Handle compare logic (eg check if unique ids are the same)
		return a === b;
	}

	async submit() {
		const data = new FormData();

		data.append('refId', String(this.user.id));
		data.append('ref', 'user');
		data.append('source', 'users-permissions');
		data.append('field', 'profilePicture');
		data.append('files', this.file);

		let updateUser = Object.assign({}, this.user);

		if (this.oldPassword && !this.password && !this.pwRetype) {
			// if they haven't filled out the password fields (to change password)
			// then just update the user details
			await this.pushUpdatedUser(data, updateUser);
		} else if (
			this.oldPassword &&
			this.password &&
			this.pwdRegex.test(this.password) &&
			this.password == this.pwRetype
		) {
			// if they have filled out the password fields (to change password)
			// then ONLY change the password, not the user details
			const poolData = {
				UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
				ClientId: environment.cognitoAppClientId, // Your client id here
				Storage: new CookieStorage({ domain: environment.cookie_url })
			};

			var userPool = new CognitoUserPool(poolData);
			var cognitoUser = userPool.getCurrentUser();

			cognitoUser.getSession((err, session) => {
				if (err) {
					console.log('profile-page.component', err, session);
					// alert(err.message || JSON.stringify(err));
					return;
				}
				cognitoUser.changePassword(
					this.oldPassword,
					this.password,
					async (err, result) => {
						if (err) {
							// console.log(err.message || JSON.stringify(err));
							let dialogRef = this.dialog.open(InfoModalComponent, {
								width: '600px',
								panelClass: 'addTagModal',
								data: {
									title: 'Oh no!',
									msg: err.message || JSON.stringify(err)
								}
							});
							return;
						}
						let dialogRef = this.dialog.open(InfoModalComponent, {
							width: '600px',
							panelClass: 'addTagModal',
							data: {
								title: 'Profile Update',
								msg: 'Your password has been changed.'
							}
						});
					}
				);
			});
		} else if (!this.oldPassword && (this.password || this.pwRetype)) {
			let dialogRef = this.dialog.open(InfoModalComponent, {
				width: '600px',
				panelClass: 'addTagModal',
				data: {
					title: 'Sorry!',
					msg: 'You must enter your current password!'
				}
			});
		} else if (this.oldPassword && (!this.password || !this.pwRetype)) {
			let dialogRef = this.dialog.open(InfoModalComponent, {
				width: '600px',
				panelClass: 'addTagModal',
				data: {
					title: 'Sorry!',
					msg: 'Password and Retype password are required!'
				}
			});
		} else if (this.oldPassword && this.password != this.pwRetype) {
			let dialogRef = this.dialog.open(InfoModalComponent, {
				width: '600px',
				panelClass: 'addTagModal',
				data: {
					title: 'Sorry!',
					msg: 'Password and Retype password must be the same!'
				}
			});
		} else if (this.oldPassword && !this.pwdRegex.test(this.password)) {
			let dialogRef = this.dialog.open(InfoModalComponent, {
				width: '600px',
				panelClass: 'addTagModal',
				data: {
					title: 'Sorry!',
					msg:
						'Passwords must be a minimum of 9 characters, start with a letter, and include' +
						' upper and lowercase letters, a special character !@$%&*, and a number.'
				}
			});
		} else {
			await this.pushUpdatedUser(data, updateUser);
		}
	}

	throwErrModal(err) {
		let errTitle = 'Uh-oh!';
		let errMsg =
			'Something went wrong. Please check for any errors or try again later.';
		if (!err || !isObject(err) || !err.error) {
			console.log('err', err);
		} else {
			if (isObject(err.error)) err = err.error;
			// errTitle = err.statusCode && err.error ? `${err.error} - ${err.statusCode}` : err.error || errTitle;
			errTitle = err.error || errTitle;
			errMsg =
				Array.isArray(err.message) && Array.isArray(err.message[0].messages)
					? err.message[0].messages[0].message
					: err.message || errMsg;
		}
		let dialogRef = this.dialog.open(InfoModalComponent, {
			width: '600px',
			panelClass: 'addTagModal',
			data: {
				title: errTitle,
				msg: errMsg
			}
		});
	}

	async updateAvatar(data) {
		// when the user uploads a new avatar, only update the avatar
		const dialogRef = this.dialog.open(InfoModalComponent, {
			width: '600px',
			panelClass: 'addTagModal',
			data: {
				title: 'Updating',
				msg: "We're updating your Profile Picture"
			}
		});
		if (this.file) {
			await this.api
				.upload(data)
				.then(async (res) => {
					let u = await this.api.get('users/me').toPromise();
					this.auth.updateUser(u);
					dialogRef.close();
					this.dialog.open(InfoModalComponent, {
						width: '600px',
						panelClass: 'addTagModal',
						data: {
							title: 'Profile Picture Updated',
							msg: 'Your profile picture has been updated.'
						}
					});
				})
				.catch((err) => {
					this.throwErrModal(err);
				});
		}
	}

	async pushUpdatedUser(data, updateUser) {
		// removes the profile picture from the user object in case 
		// they have changed it, we don't store previous picture details  
		delete updateUser.profilePicture;
		// when the user updates their profile, update the user details only, no avatar
		await this.api
			.put('users/me', updateUser)
			.toPromise()
			.then(
				async (res) => {
					this.auth.updateUser(res);
					const dialogRef = this.dialog.open(InfoModalComponent, {
						width: '600px',
						panelClass: 'addTagModal',
						data: {
							title: 'Profile Updated',
							msg: 'Your personal information has been updated.'
						}
					});
				},
				(err) => {
					this.throwErrModal(err);
				}
			);
	}

	openCropper() {
		const dialogRef = this.dialog.open(ImageCropperModalComponent, {
			width: '600px',
			data: this.croppedImage
		});

		dialogRef.afterClosed().subscribe(async (res) => {
			this.croppedImage = res.croppedImage;
			const resp: Response = await fetch(this.croppedImage);
			const blob: Blob = await resp.blob();
			this.file = new File(
				[blob],
				`avatar_${this.user.username}.jpg`, // give the file a unique name (preveiously no name was set causing upload issues)
				{ type: 'image/png' }
			);
			if (this.croppedImage) {
				// set the fprm data to send to the server
				const data = new FormData();
				data.append('refId', String(this.user.id));
				data.append('ref', 'user');
				data.append('source', 'users-permissions');
				data.append('field', 'profilePicture');
				data.append('files', this.file);

				// update the user's avatar
				// we're now doing this when the user closes the cropping modal
				this.updateAvatar(data);
			}
		});
	}

	get placeholderImg() {
		let img: string = '';
		if (this.isNotEmpty(this.user)) {
			if (this.user.role.name == 'Educator') {
				img = 'assets/images/avatar/educator_avatar.png';
			} else if (this.user.role.name == 'Collaborator') {
				img = 'assets/images/avatar/collaborator_avatar.png';
			} else if (this.user.role.name == 'Infiniscope Member') {
				img = 'assets/images/avatar/infiniscope_avatar.png';
			} else if (this.user.role.name == 'Advisory Member') {
				img = 'assets/images/avatar/advisory_avatar.png';
			}
		}
		return img;
	}

	isNotEmpty(obj) {
		if (obj) {
			return Object.keys(obj).length > 0;
		}
		return false;
	}
}
