import { InfoModalModule } from './../../shared/modules/info-modal/info-modal.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './components/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
	declarations: [ContactComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		InfoModalModule
	]
})
export class ContactModule {}
