<div class="col mx-0 px-0 col-custom">
	<div class="banner-bottom">
		<button disabled class="btn py-0">
			<img [src]="profileImage" alt="infiniscope" />
            <div class="author-info">
                {{ author }}
                <br *ngIf="createdBy">
                {{ createdBy }}
            </div>
		</button>
	</div>
</div>
