import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from 'src/app/services/event/event.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-social-media-modal',
	templateUrl: './social-media-modal.component.html',
	styleUrls: ['./social-media-modal.component.scss']
})
export class SocialMediaModalComponent implements OnInit {
	public modalTitle = 'Follow Us!';
	private share: string;
	public socialMedia;

	constructor(
		private api: ApiService,
		public window: WindowRef,
		public localStorage: LocalstorageService,
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialogRef<SocialMediaModalComponent>,
		private event: EventService,
		private router: Router
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: '/other' }
		};
		this.event.globalEvent(actionOb);

		this.share = data?.share ?? '';
	}

	ngOnInit(): void {
		if (this.share) {
			this.setupShare();
		} else {
			this.getSocial();
		}
	}

	public dismiss(): void {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: '/' }
		};
		this.event.globalEvent(actionOb);
		this.dialog.close();
	}

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	getSocial() {
		this.api.get('social-media').toPromise().then((res: any) => {
			this.socialMedia = res.socialMedia;
		});
	}

	setupShare() {
		this.modalTitle = 'Share';
		this.socialMedia = [
			{
				url: `https://www.facebook.com/share.php?u=${this.share}`,
				logo: {
					url: 'https://infiniscope-space.sfo3.digitaloceanspaces.com/60c40f635fcfc6f9ae917038e1f64370.png',
					alternativeText: 'Facebook',
				},
				name: 'Facebook',
			},
			{
				url: `https://twitter.com/intent/tweet?text=${this.share}`,
				logo: {
					url: 'https://staging-infiniscope.sfo3.digitaloceanspaces.com/e0a48c2b17b76bc717573874bce9724c.png',
					alternativeText: 'Twitter',
				},
				name: 'Twitter',
			},
			{
				url: `http://pinterest.com/pin/create/link/?url=${this.share}`,
				logo: {
					url: 'https://infiniscope-space.sfo3.digitaloceanspaces.com/6341bc3f0c36efd9d085bf9ceeb50a24.png',
					alternativeText: 'Pinterest',
				},
				name: 'Pinterest',
			},
			{
				url: `https://www.linkedin.com/sharing/share-offsite/?url=${this.share}`,
				logo: {
					url: 'https://staging-infiniscope.sfo3.digitaloceanspaces.com/4291233a77ebfeb63778f2385a1fb5f3.png',
					alternativeText: 'LinkedIn',
				},
				name: 'LinkedIn',
			},
		];
	}

	public goToPage(url: string) {
		if (!url) return;
		if(isPlatformBrowser(this.platformId)) {
			this.window.nativeWindow.open(url, '_blank');
		}
	}
}
