import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [BookmarkComponent],
	imports: [CommonModule, MatIconModule]
})
export class BookmarkModule {}
