<body>
	<section>
		<div class="center">
			<div class="container">
				<h1>Report an Issue</h1>
				<div class="subtitle">
					<h2>
						We want to help
					</h2>
				</div>
				<div class="subtitle">
					If you're having any issues please fill in the form below and we'll be in touch with you soon
				</div>
				<form [formGroup]="issueForm" (ngSubmit)="submitIssue()">
					<div class="row">
						<div class="col-12">
							<label for="first-name" class="form-label input-label">Name</label>
						</div>
						<div class="col-md-6 col-12">
							<input aria-required="true"  id="first-name" type="text" class="form-control" formControlName="firstName" placeholder="First name">
						</div>
						<div class="col-md-6 col-12 mt-m">
							<label class="hide-label" for="last-name">Last Name</label>
							<input aria-required="true"  id="last-name" type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-12">
							<label for="email" class="form-label input-label">Email</label>
							<input aria-required="true"  id="email" type="email" class="form-control" formControlName="email" placeholder="name@example.com">
						</div>
						<div class="col-md-6 col-12">
							<label for="phone" class="form-label input-label">Phone</label>
							<input aria-required="true"  id="phone"  type="text" class="form-control" formControlName="phone" placeholder="(123) 456-7890">
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-12">
							<label for="inputGroupSelect01" class="form-label input-label">Nature of Issue</label>
							<select class="form-select select-box" [ngClass]="{'holder': !issueForm.controls.issueType.value}" formControlName="issueType" id="inputGroupSelect01">
								<option label="My issue is with..." [ngValue]="null" disabled>My issue is with...</option>
								<option label="{{item.value}}" *ngFor="let item of natureIssues" [ngValue]="item.value">{{item.name}}</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label for="issues-desc" class="form-label input-label">Issue Description</label>
							<textarea id="issues-desc" class="form-control" placeholder="Be sure to include details such as OS, browser..." formControlName="message" name=""  cols="30" rows="10"></textarea>
						</div>
					</div>
					<div class="row" style="padding-top: 20px;">
						<div class="col-12 col-md-6 btn-div">
							<re-captcha (resolved)="captchaCallback($event)" [siteKey]="recaptchaSiteKey"></re-captcha>

						</div>

						<div class="col-12 col-md-6 btn-div">
							<button [disabled]="btnDisableStatus" type="submit" value="Submit" class="btn">Submit</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</section>

</body>
