<div
	class="tool-banner"
	style="background-image: url(/assets/images/bg-tool.jpg);"
>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="tool-head">
					<h2>Submit Course Content</h2>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- End Tool Banner -->

<div class="container">
	<div class="row">
		<div class="col-12 content-padding">
			<div class="lession-content-right">
				<div class="row">
					<div class="col-lg-6">
						<div class="content">
							<h2>Contributed Content Description</h2>
							<div class="lesson-item-description">
								Contributed content represents lessons and lesson content
								created BY our community, FOR our community. Contributed content
								enables our community to curate the content that is most
								relevant to the learners and communities you engage in your
								work. As a member of the Infiniscope community, you have access
								to a variety of tools that enable you to create your own digital
								lessons and assets. Using our submission and approval proccess,
								you can rest assured that the content you see in contributed
								content meets the community standards listed below.
							</div>
						</div>

						<div class="content">
							<h2>Community Standards for Contributed Content</h2>
							<div class="lesson-item-description">
								Infiniscope strives to ensure all contributed content created by
								our commmunity meets a minimum quality standard. As you explore
								the catalog of contributed content, each item has been approved
								because:
								<ul class="content-list">
									<li>it align with science and engineering content.</li>
									<li>
										the contributed author verified the assets are free from
										copyrights and cited appropriately.
									</li>
									<li>
										there are no broken interactions built within the lesson or
										lesson content.
									</li>
									<li>
										the content lacks misconceptions and/or misrepresentations.
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="col-lg-6">
						<div class="col-lg-12">
							<div class="content-right">
								<h2>How to submit Contributed Content</h2>

								<div class="lesson-item-description">
									<ol class="content-list">
										<li>
											Create a lesson or lesson content using Infiniscope or
											another freely available educational tool.
										</li>
										<li>Collect the URL.</li>
										<li>
											Select the type of contributed cotnent that your lesson
											falls under (Tour it, Argos or Other).
										</li>
										<li>
											Submit your lesson by completing the webform in the next
											step.
										</li>
									</ol>
								</div>
							</div>

							<div class="content-right pre-requesti">
								<h2>What Happens After Contributed Content is Submitted?</h2>

								<div class="lesson-item-description">
									Contributed content that has been submitted by Infiniscope
									members is subject to approval. Once your lesson has been
									reviewed, you will be notified of its status. Should your
									lesson not be approved, you will receive recommendations on
									how to modify the lesson to meet community standards.
								</div>
							</div>
						</div>
					</div>
				</div>

				<hr class="bt-01" />
				<div class="row">
					<div class="col-lg-12">
						<div class="content">
							<h2>Which Type of Content did You Create?</h2>
							<p>
								Currently, there are two primary types of content you can create
								with Infiniscope tools, but any content you create that
								generates a URL can be considered contributed content. Choose
								the one that most closely aligns to the content you wish to
								contribute.
							</p>
						</div>
					</div>
				</div>

				<div class="row row-10">
					<div class="col-lg-4 pad-10" *ngFor="let card of techCards">
						<div class="learn-card">
							<h2>{{card.title}}</h2>
							<p>{{ card.desc }}</p>
							<div class="learn-card-body">
								<div class="content">Getting Ready</div>
								<ul>
									<li *ngFor="let cont of card.card">{{ cont.content }}</li>
								</ul>
								<div class="learn-card-footer" [ngClass]="{'few-ul-list': card.card.length < 4}">
									<button
										class="btn"
										(click)="openModal(card)"
										[disabled]="(!authenticated && card.authenticationRequired) || card.disabled"
										[ngClass]="{
											'btn-blue': authenticated || !card.authenticationRequired,
											'btn-grey': (!authenticated && card.authenticationRequired) || card.disabled
										}"
									>
										{{ card.buttonTitle }}
									</button>
									<span
										class="text-required"
										*ngIf="!authenticated && card.authenticationRequired"
									>
										* Login Required
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
