<div class="lession-content-right">
	<div class="row">
		<div class="col-lg-6">
			<div class="content">
				<h2>Lesson Description</h2>
				<div
					class="lesson-item-description"
					[innerHTML]="lesson.description | safeHtml | markdown"
				></div>
			</div>

			<div class="content">
				<h2>Central Question</h2>
				<div
					class="lesson-item-description"
					[innerHTML]="lesson.centralQuestion | safeHtml | markdown"
				></div>
			</div>

			<div class="content">
				<h2>Instructional Objective</h2>
				<div
					class="lesson-item-description"
					[innerHTML]="lesson.instructionalObjective | safeHtml | markdown"
				></div>
			</div>

			<div class="content">
				<h2>Topics</h2>
				<div
					class="topic-tag"
					[ngClass]="{
						'educator-mode-auth': educatorMode && authenticated
					}"
				>
					<ul>
						<li *ngFor="let topic of topics" (click)="searchForTopic(topic)">
							<button class="btn">{{ topic.title }}</button>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="col-lg-6">
			<app-lesson-right-detail [lesson]="lesson"></app-lesson-right-detail>
		</div>
	</div>

	<hr class="bt-01" />
	<app-lesson-bottom
		[requireAuthentication]="true"
		[lesson]="lesson"
		[contentType]="contentType"
	></app-lesson-bottom>
</div>
