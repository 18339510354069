import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, ApplicationRef, Inject, PLATFORM_ID, Output, EventEmitter, HostListener } from '@angular/core';
import { Lesson } from 'src/app/model/lesson.model';
import { EventService } from 'src/app/services/event/event.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
	selector: 'app-lesson-container',
	templateUrl: './lesson-container.component.html',
	styleUrls: ['./lesson-container.component.scss']
})
export class LessonContainerComponent implements OnInit {
	@Input() sliderTitle: string;
	// @Input() lessons: Lesson[];
	@Input() lessons: any[];
	@Input() slider: boolean = true;
	@Input() maxSize: number = 4;
	@Input() minSize: number = 1;
	@Input() smaller: boolean = false;
	@Input() clipVertically: boolean = false;
	@Input() clipHorizontally: boolean = false;
	@Input() fromCollection: boolean = false;
	@Input() sort: boolean = false;
	@Input() collection: boolean = false;
	@Input() auth: boolean = true;
	@Output() openSeeMore: EventEmitter<any> = new EventEmitter();
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		if (this.selectedLesson) {
			this.closeFlipCard();
		}
	}
	public sliderIndex: number = 0;
	public sliderTransition: boolean = true;
	public sliderTransitionTime: number = 0.5;
	// public selectedLesson: Lesson;
	public selectedLesson: any;
	public flipCardStyle: any;
	public sortBy: string = '';
	private flipping: boolean = false;
	public timerToClose;

	constructor(
		private appRef: ApplicationRef,
		private event: EventService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
	) { }

	ngOnInit(): void {
		this.event.currentData.subscribe((e) => {
			if (e.action === 'closeFlipCard') this.closeFlipCard();
		});
	}

	public async openCard(e: any, lesson: Lesson, i: number) {
		this.clickDelay = Date.now();
		this.event.globalEvent({ action: 'closeFlipCard' });
		if (this.flipping) return;
		delete this.selectedLesson;
		await this.appRef.tick();
		if (this.slider) i -= this.sliderIndex;
		const spacing = this.convertSpacing(this.getCardSpacing());
		const firstRow =
			spacing === 1
				? false
				: Math.floor(i / spacing) === 0 && this.clipVertically;
		const lastRow =
			spacing === 1 || this.lessons.length <= spacing
				? false
				: Math.floor(i / spacing) ===
				Math.floor((this.lessons.length - 1) / spacing) &&
				this.clipVertically;
		const firstColumn =
			spacing === 1 ? false : i % spacing === 0 && this.clipHorizontally;
		const lastColumn =
			spacing === 1
				? false
				: i % spacing === spacing - 1 && this.clipHorizontally;
		// const cardW = e.srcElement.offsetHeight / 2;
		const cardH = e.srcElement.offsetWidth / 2;
		const left =
			spacing === 1
				? 50
				: ((i % spacing) * (1 / spacing) + 1 / (spacing * 2)) * 100;
		this.flipCardStyle = {
			top: lastRow
				? 'unset'
				: firstRow
					? '62px'
					: e.srcElement.offsetTop +
					cardH +
					(this.clipVertically && firstRow ? -cardH : 0) +
					(this.clipVertically && lastRow ? cardH : 0) +
					38 +
					32 +
					'px',
			bottom: lastRow ? '8px' : 'unset',
			left: lastColumn ? 'unset' : firstColumn ? '0px' : left + '%', // (e.srcElement.offsetLeft + cardW + ((this.clipHorizontally && firstColumn) ? - cardW : 0) + ((this.clipHorizontally && lastColumn) ? cardW : 0)) + 'px',
			right: lastColumn ? '0px' : 'unset',
			first: firstColumn,
			last: lastColumn,
			head: firstRow,
			foot: lastRow
		};
		this.selectedLesson = lesson;
	}

	public returnCard(e) {
		if (!this.timerToClose) return;
		const isChildOf = (e: any, classes: string[]): boolean => {
			if (!e) return false;
			if (
				e.className &&
				classes.filter((c) => e.className.indexOf(c) >= 0).length > 0
			)
				return true;
			if (!e.parentElement) return false;
			return isChildOf(e.parentElement, classes);
		};
		if (isChildOf(e.toElement, ['cardFrontContainer', 'cardBackContainer'])) {
			clearTimeout(this.timerToClose);
			delete this.timerToClose;
		}
	}

	public closeCard(e: any) {
		if (this.flipping) return;
		const isChildOf = (e: any, classes: string[]): boolean => {
			if (!e) return false;
			if (
				e.className &&
				classes.filter((c) => e.className.indexOf(c) >= 0).length > 0
			)
				return true;
			if (!e.parentElement) return false;
			return isChildOf(e.parentElement, classes);
		};
		if (!isChildOf(e.target, ['cardFrontContainer', 'cardBackContainer'])) {
			this.timerToClose = setTimeout(() => this.event.globalEvent({ action: 'closeFlipCard' }), 100);
		}
	}

	private closeFlipCard() {
		if (this.flipping) return;
		delete this.selectedLesson;
	}

	public async moveSlider(dir: boolean) {
		let firstSlide = this.sliderIndex === 0;
		let lastSlide = this.sliderIndex >= this.lessons.length - 1; //  - (this.maxSize - 1)
		if ((firstSlide && !dir) || (lastSlide && dir)) {
			this.sliderTransition = false;
			this.sliderIndex = lastSlide ? -1 : this.lessons.length; //  - (this.maxSize - 1)
			await new Promise<void>((resolve) => setTimeout(() => resolve(), 0));
			this.sliderTransition = true;
		}
		this.sliderIndex += dir ? 1 : -1;
	}

	public getLessons(): Lesson[] {
		if (!this.lessons || this.lessons.length === 0) return [];
		const ret = this.lessons;
		return ret;
	}

	public get sortedLessons(): Lesson[] {
		if (this.sortBy === 'name') {
			return this.lessons.sort((a, b) => (a.title > b.title ? 1 : -1));
		} else if (this.sortBy === 'date') {
			return this.lessons.sort(
				(a, b) =>
					new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
			);
		}
		return this.lessons;
	}

	public getCardSpacing() {
		if (isPlatformBrowser(this.platformId)) {
			const width = this.window.nativeWindow.innerWidth;
			let ret = 0;
			if (width >= 992) ret = 25;
			else if (width >= 768) ret = 50;
			else ret = 100;
			return ret >= 100 / this.maxSize ? ret : 100 / this.maxSize;
		}
	}

	public convertSpacing(spacing: number) {
		if (spacing === 100) return 1;
		if (spacing === 50) return 2;
		if (spacing === 25) return 4;
	}

	public updateFlipping(e: boolean) {
		this.flipping = e;
	}

	public updateLesson(lesson, newLesson) {
		lesson = newLesson;
	}

	loginModalToggle() {
		this.openSeeMore.emit(true);
	}

	private clickDelay: number = 0;
	private static CLICK_INTERVAL: number = 100;
	@HostListener('window:click', ['$event']) handleClick(event) {
		if (Date.now() - this.clickDelay < LessonContainerComponent.CLICK_INTERVAL) {
			return;
		}
		if (isPlatformBrowser(this.platformId) && this.selectedLesson) {
			this.closeCard(event);
		}
	}


}
