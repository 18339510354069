<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
	<div class="login-form" [ngClass]="title === 'loginModel' ? 'login-form-modal' : ''" *ngIf="!authenticated">
		<div class="form-group">
			<label for="">Email Address</label>
			<input type="text" class="form-control" placeholder="Enter Your Email" formControlName="username"
				aria-required="true" aria-describedby="email-error" (focus)="forgotPasswordError = false" [ngClass]="{
					'is-invalid': (submitted && f.username.errors) || forgotPasswordError
				}" />
			<div *ngIf="submitted && f.username.errors" id="email-error" class="invalid-feedback">
				<div *ngIf="f.username.errors.required">
					Email is required
				</div>
			</div>
		</div>

		<div class="form-group">
			<label for="">Password</label>
			<input type="password" class="form-control" placeholder="Enter Your password" formControlName="password"
				class="form-control" aria-required="true" aria-describedby="password-error"
				[ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
			<div *ngIf="submitted && f.password.errors" id="password-error" class="invalid-feedback">
				<div *ngIf="f.password.errors.required">
					Password is required
				</div>
			</div>
			<span class="error-msg" *ngIf="errorMsg">{{errorMsg}}</span>
			<span *ngIf="!migratedAccountPresent" class="forgot-link" [ngClass]="{ 'align-right': forgotPasswordRightAlignment }"><a href="/?reset=true" class="btn p-0">Forgot password?</a></span>
			<span *ngIf="forgotPasswordSubmitted" [ngClass]="{ 'align-right': forgotPasswordRightAlignment }">Check your email
				for instructions on how to reset your password.</span>
		</div>
		<div class="btn-groups">
			<button class="btn btn-primary btn-blue" *ngIf="!loading && !migratedAccountPresent">Login</button>
			<button class="btn btn-primary btn-blue" disabled *ngIf="loading && !migratedAccountPresent">
				Login
			</button>
			<div class="text-or" *ngIf="!migratedAccountPresent">
				<span>OR</span>
			</div>
			<a routerLink="/join" class="btn btn-primary btn-yello">Create an account</a>
		</div>
	</div>

	<div class="login-form" [ngClass]="title === 'loginModel' ? 'login-form-modal' : ''" *ngIf="authenticated">
		<div class="row">
			<div class="col-5">
				<div class="hexagon">
					<img alt="user profile picture" [src]="user?.profilePicture ? user.profilePicture.url : placeholderImg">
				</div>
			</div>
			<div class="col-7 profile-info">
				<label class="title">{{user.name ? user.name : ''}}</label>
				<label class="subtitle">{{user.educationalInstitutional?.name ? user.educationalInstitutional?.name : '' }}</label>
				<span><a [routerLink]="['/profile']" routerLinkActive="router-link-active" >View/Update Profile</a></span>
			</div>
			<div class="col-5 px-3 pb-2 text-center profile-info">
				{{ user?.role?.name }}
			</div>
		</div>
		<!--
		<hr class="first-line">
		<div class="bookmark">
			<label class="title">Recent Bookmark</label>
			<div class="bookmark-row">
				<div class="bookmark-box">
					<img src="./../../../../../assets/images/image.jpg" alt=" ">
					<label>Phases of the Moon</label>
				</div>
				<div class="bookmark-box">
					<img src="./../../../../../assets/images/image.jpg" alt=" ">
					<label>Phases of the Moon</label>
				</div>
				<div class="bookmark-box">
					<img src="./../../../../../assets/images/image.jpg" alt=" ">
					<label>Phases of the Moon</label>
				</div>
			</div>
			<div class="see-all-link">
				<span><a [routerLink]="['/bookmarks']" routerLinkActive="router-link-active" >See all</a></span>
			</div>
		</div>
		-->
		<hr class="second-line">
		<div class="btn-groups">
			<button class="btn btn-primary btn-blue" (click)="logout()">
				Log Out
			</button>
		</div>
		<div class="privacy-span">
			<span><a href="">Privacy Policy</a> | <a href="">Terms of Service</a></span>
		</div>
	</div>
</form>