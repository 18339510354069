<body>
	<section>
		<div class="center">
			<div class="container">
				<h1>Contact</h1>
				<div class="subtitle">
					If you have any requests, feedback or comments, please fill in the form below and we'll be in touch with you soon
				</div>
				<form [formGroup]="contactForm" (ngSubmit)="submitContact()">
					<div class="row">
						<div class="col-12">
							<label for="first-name" class="form-label input-label">Name</label>
						</div>
						<div class="col-md-6 col-12">
							<input aria-required="true" id="first-name" type="text" class="form-control" formControlName="firstName" placeholder="First name">
						</div>
						<div class="col-md-6 col-12 mt-m">
							<label for="last-name" class="hide-label">Last Name</label>
							<input aria-required="true" id="last-name" type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-12">
							<label for="email" class="form-label input-label">Email</label>
							<input aria-required="true" id="email" type="email" class="form-control" formControlName="email" placeholder="name@example.com">
						</div>
						<div class="col-md-6 col-12">
							<label for="phone" class="form-label input-label">Phone</label>
							<input aria-required="true" id="phone" type="text" class="form-control" formControlName="phone" placeholder="(123) 456-7890">
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-12">
							<label for="inputGroupSelect01" class="form-label input-label">Role</label>
							<!-- <input type="text" class="form-control" placeholder="I am a..."> -->
							<select class="form-select select-box" [ngClass]="{'holder': !contactForm.controls.role.value}" id="inputGroupSelect01" formControlName="role">
								<option [ngValue]="null" disabled>I am a...</option>
								<option *ngFor="let role of roles" [ngValue]="role.value">
									{{role.name}}
								</option>
							</select>
						</div>
						<div class="col-md-6 col-12">
							<label for="org" class="form-label input-label">Organization</label>
							<input aria-required="true" id="org" type="text" class="form-control" formControlName="organization" placeholder="School name, business name...">
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-12">
							<label for="inputGroupSelect02" class="form-label input-label">Age</label>
							<!-- <input type="text" class="form-control" placeholder="I am a..."> -->
							<select class="form-select select-box" [ngClass]="{'holder': !contactForm.controls.age.value}" formControlName="age" id="inputGroupSelect02">
								<option [ngValue]="null" disabled>I am a...</option>
								<option *ngFor="let age of ages" [ngValue]="age.value">{{age.name}}</option>
							</select>
						</div>
						<div class="col-md-6 col-12">
							<label for="guard-mail" class="form-label input-label">Guardian Email</label>
							<input id="guard-mail" type="email" class="form-control" formControlName="guardian" placeholder="name@example.com">
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label for="message" class="form-label input-label">Message</label>
							<textarea id="message" class="form-control" formControlName="message" placeholder="Tells us about yourself!" name="" maxlength="10000" cols="30" rows="10"></textarea>
						</div>
					</div>
					<div class="row" style="padding-top: 20px;">

								<div class="col-12 col-md-6">
									<re-captcha (resolved)="captchaCallback($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
								</div>

								<div class="col-12 col-md-6 btn-div">
									<button [disabled]="btnDisableStatus" type="submit" value="Submit" class="btn">Submit</button>
								</div>

					</div>
				</form>
			</div>
		</div>
	</section>

</body>
