<div class="lessonContainer row col-12 mx-0 px-0" #lessonContainer>
	<app-lesson-flip-card *ngIf="selectedLesson" [lesson]="selectedLesson" [left]="flipCardStyle.first"
		[right]="flipCardStyle.last" [head]="flipCardStyle.head" [foot]="flipCardStyle.foot" [ngStyle]="flipCardStyle"
		(mouseenter)="returnCard($event)" (flipping)="updateFlipping($event)"
		(closeCard)="closeFlipCard()" (updateLesson)="updateLesson(selectedLesson, $event)"
		[fromCollection]="fromCollection">
	</app-lesson-flip-card>
	<div class="title row col-12 mx-0 px-0 my-2 pl-2" *ngIf="sliderTitle">
		<h2 class="col-9 pl-0 text-white font-weight-bold my-0" *ngIf="(sliderTitle == 'Newest') || (sliderTitle == 'Featured'); else h3Title">{{ sliderTitle }}</h2>
		<ng-template #h3Title>
			<h4 class="col-9 pl-0 text-white font-weight-bold my-0">{{ sliderTitle }}</h4>
		</ng-template>
		<div class="col-3 text-right">
			<button class="btn" *ngIf="!auth" (click)="loginModalToggle()">Sign in for full access</button>
		</div>
	</div>
	<div class="sliderArrowContainer row col-1 mx-0 px-0 py-2" *ngIf="slider">
		<button class="sideArrow border-0 offset-3 col-12 col-md-6 p-2 text-center d-flex" [disabled]="getLessons().length <= 4" (click)="moveSlider(false)" aria-label="previous">
			<i class="fas fa-chevron-left align-self-center mx-auto"></i>
		</button>
	</div>
	<div class="sliderContainer row col-10 mx-0 px-0" *ngIf="slider">
		<app-lesson-card class="col-lg-3 col-md-6 col-12 mx-0 p-2 custom_lesson_card" [ngStyle]="{
				left: getCardSpacing() * (i - sliderIndex) + '%',
				transition: sliderTransition ? sliderTransitionTime + 's ease' : 'none'
			}" *ngFor="let lesson of getLessons(); let i = index"
			[smaller]="smaller" [collection]="collection" [lesson]="lesson"
			[left]="i - sliderIndex === 0 && getCardSpacing() < 100"
			[right]="i - sliderIndex === 3 && getCardSpacing() < 100"
			(click)="openCard($event, lesson, i)" id="{{ sliderTitle + '-' + i }}">
		</app-lesson-card>
	</div>
	<div class="sliderArrowContainer row col-1 mx-0 px-0 py-2" *ngIf="slider">
		<button class="sideArrow border-0 offset-3 col-12 col-md-6 p-2 text-center d-flex" [disabled]="getLessons().length <= 4" (click)="moveSlider(true)" aria-label="next">
			<i class="fas fa-chevron-right align-self-center mx-auto"></i>
		</button>
	</div>
	<div class="row col-12 mx-0 px-0 my-2" *ngIf="!slider">
		<app-lesson-card [ngClass]="{
				'col-lg-6': maxSize === 2,
				'col-6': minSize === 2,
				'col-12': !minSize
			}" class="col-lg-3 col-md-6 col-12 mx-0 p-2" *ngFor="let lesson of sortedLessons; let i = index"
			[smaller]="smaller" [collection]="collection" [lesson]="lesson"
			[left]="i % convertSpacing(getCardSpacing()) === 0 && getCardSpacing() < 100"
			[right]="i % convertSpacing(getCardSpacing()) === convertSpacing(getCardSpacing()) - 1 && getCardSpacing() < 100"
			(click)="openCard($event, lesson, i)"></app-lesson-card>
	</div>
</div>
