import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroImageSliderComponent } from './component/hero-image-slider/hero-image-slider.component';
import { MatIconModule } from '@angular/material/icon';
import { SafeHtmlModule } from 'src/app/pipes/safehtml/safehtml.module';
import { MarkdownModule } from 'src/app/pipes/markdown/markdown.module';

@NgModule({
	declarations: [HeroImageSliderComponent],
	imports: [CommonModule, MatIconModule, SafeHtmlModule, MarkdownModule],
	exports: [HeroImageSliderComponent]
})
export class HeroImageSliderModule {}
