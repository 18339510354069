Begin page content
<main role="main" class="flex-shrink-0 middle-section">
	<div class="middle-inner">
		<section class="signup-section">
			<div class="signup-bg"></div>
			<div class="signup-inner">
				<div class="container container-960">
					<!-- sign up -info -->
					<div class="signup-info">
						<div class="row">
							<div class="col-12">
								<div class="signup-info-box">
									<div class="info-content">
										<h3>Great! You're ready for the next step!</h3>
										<p>
											You responded that you have met the minimum criteria to submit contributed content.
											To request an approval of your content to be shared on the Infiniscope website, please complete the form below.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end -->

					<div class="signup-form">
						<form [formGroup]="contentForm" (ngSubmit)="onSubmit()">
							<div class="signup-header">
								<h2>Submit a Tour it Tour</h2>
							</div>

							<div class="signup-body">
								<h2>Lesson Creator Information</h2>
								<hr />

								<div class="form-group">
									<label for="">Name <i>(required)</i></label>
									<input class="form-control" placeholder="First name" formControlName="firstName" [ngClass]="{
											'is-invalid': f.firstName.errors && f.firstName.touched
										}" />
									<div *ngIf="f.firstName.errors && f.firstName.touched" class="invalid-feedback">
										<div *ngIf="f.firstName.errors.required">
											First Name is required
										</div>
									</div>
								</div>
								<div class="form-group">
									<input class="form-control" placeholder="Last name" formControlName="lastName" [ngClass]="{
											'is-invalid': f.lastName.errors && f.lastName.touched
										}" />
									<div *ngIf="f.lastName.errors && f.lastName.touched" class="invalid-feedback">
										<div *ngIf="f.lastName.errors.required">
											Last Name is required
										</div>
									</div>
								</div>

								<h2>Lesson Overview</h2>
								<hr />

								<div class="form-group">
									<label for="">Password</label>
									<input type="password" class="form-control" placeholder="Password" formControlName="password"
										[ngClass]="{
											'is-invalid': f.password.errors && f.password.touched
										}" />
									<div *ngIf="f.password.errors && f.password.touched" class="invalid-feedback">
										<div *ngIf="f.password.errors.required">
											Password is required
										</div>
										<div *ngIf="f.password.errors.pattern">
											Passwords must contain: a minimum of 1 lower case letter, a minimum of 1 upper case letter, a minimum of 1 numeric character and a minimum of 1 special character.
										</div>
										<div *ngIf="f.password.errors.minLength">
											Passwords must heve at least 8 characters
										</div>
									</div>
								</div>

								<div class="form-group">
									<input type="password" class="form-control" placeholder="Confirm Password"
										formControlName="confirmPassword" [ngClass]="{
											'is-invalid':
												f.confirmPassword.errors && f.confirmPassword.touched
										}" />
									<div *ngIf="
											(f.confirmPassword.errors ||
												(f.confirmPassword.value !== f.password.value)) &&
											f.confirmPassword.touched" class="invalid-feedback">
										<div *ngIf="
												f.confirmPassword.errors.required &&
												!f.confirmPassword.value
											">
											Confirm password is required
										</div>
										<div *ngIf="f.confirmPassword.value !== f.password.value">
											Confirm password does not match the password
										</div>
									</div>
								</div>

								<hr />

								<div class="form-group">
									<label for="">Educational institution</label>
									<input class="form-control" placeholder="Name" formControlName="instituteName" [ngClass]="{
											'is-invalid':
												f.instituteName.errors && f.instituteName.touched
										}" />
									<div *ngIf="f.instituteName.errors && f.instituteName.touched" class="invalid-feedback">
										<div *ngIf="f.instituteName.errors.required">
											Name is required
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="instituteType" class="d-none">Institute Type</label>
									<select class="form-control" id="instituteType" formControlName="instituteType" [ngClass]="{
											'is-invalid':
												f.instituteType.errors && f.instituteType.touched,
											'is-untouched': !f.instituteType.touched
										}" aria-label="Institute Type">
										<option hidden [ngValue]="''" selected>Type</option>
									</select>
									<div *ngIf="f.instituteType.errors && f.instituteType.touched" class="invalid-feedback">
										<div *ngIf="f.instituteType.errors.required">
											Type is required
										</div>
									</div>
								</div>

								<div class="form-group">
									<input class="form-control" placeholder="City" formControlName="instituteCity" [ngClass]="{
											'is-invalid':
												f.instituteCity.errors && f.instituteCity.touched
										}" />
									<div *ngIf="f.instituteCity.errors && f.instituteCity.touched" class="invalid-feedback">
										<div *ngIf="f.instituteCity.errors.required">
											City is required
										</div>
									</div>
								</div>

								<div class="form-group">
									<!-- <input class="form-control" placeholder="State" formControlName="instituteState" [ngClass]="{
											'is-invalid':
												f.instituteState.errors && f.instituteState.touched
										}" /> -->
									<select class="form-control" id="instituteState" formControlName="instituteState" [ngClass]="{
											'is-invalid':
												f.instituteState.errors && f.instituteState.touched,
											'is-untouched': !f.instituteState.touched
										}" aria-label="Institute State">
										<option hidden [ngValue]="''" selected>State</option>
									</select>
									<div *ngIf="f.instituteState.errors && f.instituteState.touched" class="invalid-feedback">
										<div *ngIf="f.instituteState.errors.required">
											State is required
										</div>
									</div>
								</div>

								<div class="form-group">
									<input class="form-control" placeholder="Zip" formControlName="instituteZip" [ngClass]="{
											'is-invalid':
												f.instituteZip.errors && f.instituteZip.touched
										}" />
									<div *ngIf="f.instituteZip.errors && f.instituteZip.touched" class="invalid-feedback">
										<div *ngIf="f.instituteZip.errors.required">
											Zip is required
										</div>
									</div>
								</div>

								<hr />

								<div class="form-group">
									<label for="">Subject taught</label>
									<input class="form-control" placeholder="Subject(s)" formControlName="subjectTaught" [ngClass]="{
											'is-invalid':
												f.subjectTaught.errors && f.subjectTaught.touched
										}" />
									<div *ngIf="
											f.subjectTaught.errors && f.subjectTaught.touched
										" class="invalid-feedback">
										<div *ngIf="f.subjectTaught.errors.required">
											Subject is required
										</div>
									</div>
								</div>

								<div class="form-group">
									<label for="">Grade level</label>
									<!-- <ol class="group-checkbox" [ngClass]="{'is-invalid': !validGradeLevel && gradeLevelTouched}">
										<li class="custom-control custom-checkbox" *ngFor="let gradeLevel of gradeLevelLabel">
											<input type="checkbox" class="custom-control-input" id="{{ gradeLevel.value }}"
												(click)="updateGradeLevel(gradeLevel.value)" [(checked)]="gradeLevel.selected" />
											<label class="custom-control-label btn p-0" for="{{ gradeLevel.value }}">{{ gradeLevel.title
												}}</label>
										</li>
									</ol>
									<div *ngIf="!validGradeLevel && gradeLevelTouched" class="invalid-feedback d-block">
										Grade level is required
									</div> -->
								</div>

								<hr />

								<div class="form-group">
									<label for="hearAbout">How did you hear about us?</label>
									<select id="hearAbout" class="form-control" placeholder="How you did you hear about us?"
										formControlName="hearAbout" [ngClass]="{
											'is-invalid': f.hearAbout.errors && f.hearAbout.touched,
											'is-untouched': !f.instituteType.touched
										}">
										<option hidden [ngValue]="''" selected>How you did you hear about us?</option>
									</select>
								</div>

								<div class="form-group">
									<label for="reasonJoining">Reason for joining</label>
									<textarea class="form-control" name="" id="reasonJoining" cols="30" formControlName="reasonJoining"
										[ngClass]="{
											'is-invalid':
												f.reasonJoining.errors && f.reasonJoining.touched
										}" rows="10"
										placeholder="Please describe why you are interested in joining the Infiniscope network"></textarea>
								</div>
							</div>

							<div class="signup-footer">
								<div class="row">
									<div class="col-lg-12">
										<div class="btn-groups signup-btns">
											<button class="btn btn-primary btn-blue">
												Request Educator Access
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
						<div class="educator-not">
							<div class="row">
								<div class="col-lg-12">
									<p>
										Not an educator? You can access
										<a href="#" class="text-link">exploratory activities </a>from the home page without having to sign
										up!
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</main>
