<div class="modal-content">
	<div class="modal-header">
		<h2 class="modal-title" id="exampleModalLongTitle">
			Interested in seeing more?
		</h2>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true" (click)="dismiss()">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="login-form-main">
			<div class="login-info">
				<p>
					Login or create an account for full access to simulations, community
					created content, and collaboration tools.
				</p>
			</div>
			<app-login
				title="loginModel"
				forgotPasswordRightAlignment="true"
			></app-login>
		</div>
	</div>
</div>
