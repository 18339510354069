import { ApiService } from 'src/app/services/API/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import {
	CognitoUserPool,
	CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import { EventService } from 'src/app/services/event/event.service';
import states from 'src/assets/data/location/states.json';
import { User } from 'src/app/model/user.model';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from 'src/app/shared/modules/info-modal/info-modal/info-modal.component';
import * as uuid from 'uuid';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
	registerForm: FormGroup;
	loading = false;
	submitted = false;
	public gradeLevelTouched: boolean = false;
	public interestedInTouched: boolean = false;
	migratedUser:boolean =false;

	public instituteTypes: string[] = [
		'K-12',
		'School',
		'After-School Program',
		'Museum Program',
		'Summer Program',
		'Library Program',
		'Camp',
		'University',
		'Other'
	];

	public hearAbouts: string[] = [
		'Social media',
		'NASA Express',
		'Email',
		'Friend',
		'Workshop',
		'Other'
	];

	public get states(): string[] {
		return states;
	}

	public gradeLevelLabel: {
		title: string;
		value: string;
		selected: boolean;
	}[] = [
		{
			title: 'K-4',
			value: 'k-4',
			selected: false
		},
		{
			title: '5-8',
			value: '5-8',
			selected: false
		},
		{
			title: '9-12',
			value: '9-12',
			selected: false
		},
		{
			title: 'University',
			value: 'university',
			selected: false
		}
	];

	public interestedInLabel: {
		name: string;
		title: string;
		value: string;
		selected: boolean;
	}[] = [
		{
			name: 'interestReady2UseContent',
			title: 'Ready-to-use content',
			value: 'ready-to-use content',
			selected: false
		},
		{
			name: 'interestVirtualTours',
			title: 'Creating virtual tours',
			value: 'creating virtual tours',
			selected: false
		},
		{
			name: 'interestDigitalLessons',
			title: 'Creating digital lessons',
			value: 'creating digital lessons',
			selected: false
		},
		{
			name: 'interestNASAContent',
			title: 'NASA content and resources',
			value: 'nasa content and resources',
			selected: false
		}
	];

	public recaptcha: boolean = false;
	public recaptchaSiteKey = environment.recaptcha_site_key;

	constructor(
		private event: EventService,
		private formBuilder: FormBuilder,
		private router: Router,
		private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private api: ApiService,
		private dialog: MatDialog
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);

		// redirect to home if already logged in
		if (
			this.authenticationService.currentUserValue &&
			Object.keys(this.authenticationService.currentUserValue).length > 0
		) {
			this.router.navigate(['/']);
		}
	}

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			firstName: new FormControl('', [Validators.required]),
			lastName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.email]),
			instituteName: new FormControl('', [Validators.required]),
			instituteType: new FormControl('', [Validators.required]),
			instituteCity: new FormControl('', [Validators.required]),
			instituteState: new FormControl('', [Validators.required]),
			instituteZip: new FormControl('', [Validators.required]),
			subjectTaught: new FormControl(''),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(8),
				Validators.pattern(
					'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*#?&].{8,}'
				)
			]),
			confirmPassword: new FormControl('', [Validators.required]),
			hearAbout: new FormControl(''),
			reasonJoining: new FormControl('',[Validators.required])
		});
	}

	isForInvalid() {
		return this.submitted && this.registerForm.invalid;
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.registerForm.controls;
	}

	// convenience getter for easy access to form fields
	get value() {
		return this.registerForm.value;
	}

	async onSubmit() {
		this.submitted = true;

		// stop here if form is invalid
		if (
			this.registerForm.invalid ||
			this.value.password !== this.value.confirmPassword ||
			!this.validGradeLevel || !this.validInterestedIn
		) {
			this.gradeLevelTouched = true;
			this.interestedInTouched = true;
			return this.registerForm.markAllAsTouched();
		}

		if (!this.recaptcha) {
			return;
		}

		await this.api.get('migrated-users?email=' + this.value.email).toPromise().then((res) => {
			if (res.length > 0) {
				this.migratedUser = true;
			}
		})

		const user: User = {
			username: uuid.v4(),
			name: this.value.firstName + ' ' + this.value.lastName,
			email: this.value.email,
			password: this.value.password,
			// gradeLevel: this.gradeLevelLabel
			// 	.filter((gl) => gl.selected)
			// 	.map((gl) => gl.title)
			// 	.join(', '),
			// gradeLevel: JSON.stringify({
			// 	'k-12': this.gradeLevelLabel[0].selected,
			// 	'3-5': this.gradeLevelLabel[1].selected,
			// 	'6-8': this.gradeLevelLabel[2].selected,
			// 	'9-12': this.gradeLevelLabel[3].selected,
			// 	university: this.gradeLevelLabel[4].selected
			// }),
			kFour: this.gradeLevelLabel[0].selected,
			fiveEight: this.gradeLevelLabel[1].selected,
			nineTwelve: this.gradeLevelLabel[2].selected,
			university: this.gradeLevelLabel[3].selected,
			interestReady2UseContent: this.interestedInLabel[0].selected,
			interestVirtualTours: this.interestedInLabel[1].selected,
			interestDigitalLessons: this.interestedInLabel[2].selected,
			interestNASAContent: this.interestedInLabel[3].selected,
			notificationFrequency: null,
			newEvent: false,
			newContent: false,
			newAnnouncements: false,
			newTraining: false,
			educationalName: this.value.instituteName,
			educationalCity: this.value.instituteCity,
			educationalState: this.value.instituteState,
			educationalZipcode: this.value.instituteZip,
			educationalType: this.value.instituteType,
			subjectTaught: this.value.subjectTaught,
			reasonForJoin: this.value.reasonJoining,
			hearAboutUs: this.value.hearAbout,
			approvedMember: this.migratedUser,
		};

		this.loading = true;

		this.cognitoSignUp(user);
	}

	private cognitoSignUp(user) {
		var poolData = {
			UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
			ClientId: environment.cognitoAppClientId // Your client id here
		};
		var userPool = new CognitoUserPool(poolData);
		var attributeList = [];

		attributeList.push(new CognitoUserAttribute({
			Name: 'email',
			Value: user.email
		}));

		attributeList.push(new CognitoUserAttribute({
			Name: 'name',
			Value: user.name
		}));

		// }
		userPool.signUp(
			user.username,
			user.password,
			attributeList,
			[],
			(err, result) => {
				if (result) {
					this.authenticationService.login(user.email, user.password).then(
						(data) => {
							this.api
								.put('users/me', user)
								.toPromise()
								.then(
									(res) => {
										console.log(res);
										const dialog = this.dialog.open(InfoModalComponent, {
											width: "600px",
											panelClass: 'addTagModal',
											data: {
												title: 'Thank you!',
												msg: `Congratulations! Your account request has been submitted. Once your account has been approved, you will receive a confirmation email. Please do not attempt to sign in until you receive email confirmation.`,
											}
										});
										dialog.afterClosed().subscribe((e) => {
											this.authenticationService.logout();
											this.router.navigate(['']);
										})
									},
									(err) => {
										console.log('Update error:',err.message);
									}
								);

						},
						(err) => {
							console.log('Login error:',err.message);
						}
					);
				}
				if (err) {
					// alert(err.message || JSON.stringify(err));
					console.log('Cognito error:', err.message);
				}
			}
		);
	}

	public recaptchaCallback(event) {
		if (event) this.recaptcha = true;
	}

	public updateGradeLevel(e: string) {
		this.gradeLevelTouched = true;
		const toValue = this.gradeLevelLabel.find((gl) => gl.value === e).selected;
		this.gradeLevelLabel.find((gl) => gl.value === e).selected = !toValue;
	}
	public get validGradeLevel(): boolean {
		return Boolean(this.gradeLevelLabel.find((gl) => gl.selected));
	}

	public updateInterestedIn(e: string) {
		this.interestedInTouched = true;
		const toValue = this.interestedInLabel.find((iil) => iil.value === e).selected;
		this.interestedInLabel.find((iil) => iil.value === e).selected = !toValue;
	}
	public get validInterestedIn(): boolean {
		return Boolean(this.interestedInLabel.find((iil) => iil.selected));
	}

	measureStrength(pass: string) {
		let score = 0;
		// award every unique letter until 5 repetitions
		let letters = {};
		for (let i = 0; i < pass.length; i++) {
			letters[pass[i]] = (letters[pass[i]] || 0) + 1;
			score += 5.0 / letters[pass[i]];
		}
		// bonus points for mixing it up
		let variations = {
			digits: /\d/.test(pass),
			lower: /[a-z]/.test(pass),
			upper: /[A-Z]/.test(pass),
			nonWords: /\W/.test(pass)
		};

		let variationCount = 0;
		for (let check in variations) {
			variationCount += variations[check] ? 1 : 0;
		}
		score += (variationCount - 1) * 10;
		return Math.trunc(score);
	}
}
