<section
	class="two-column-section"
	*ngIf="props"
	[ngClass]="{ dark: props.darkMode }"
>
	<!-- start wrapper -->
	<div class="container">
		<!-- start column -->
		<div class="row">
			<!-- column 50% -->
			<div
				[ngClass]="{
					'col-12': props.Column.length == 1,
					'col-md-6 col-12': props.Column.length == 2,
					'col-md-4 col-12': props.Column.length == 3
				}"
			>
				<!-- <img *ngIf="!props.Column[0]?.image" src="./../../../../../assets/images/image.jpg" class="default" alt=" " /> -->
				<img
					*ngIf="props.Column[0].image"
					[src]="props.Column[0].image.url"
					[alt]="props.Column[0].image.alternativeText"
				/>

				<div class="spacer"></div>
				<div class="spacer"></div>
				<div class="spacer"></div>

				<div [innerHTML]="props.Column[0].text | safeHtml | markdown"></div>

				<div class="spacer"></div>
			</div>

			<div
				*ngIf="props.Column.length > 1"
				[ngClass]="{
					'col-12 col-md-6': props.Column.length == 2,
					'col-md-4 col-12': props.Column.length == 3
				}"
			>
				<!-- <img *ngIf="!props.Column[1]?.image" src="./../../../../../assets/images/image.jpg" class="default" alt=" " /> -->
				<img
					*ngIf="props.Column[1].image"
					[src]="props.Column[1].image.url"
					[alt]="props.Column[1].image.alternativeText"
				/>

				<div class="spacer"></div>
				<div class="spacer"></div>
				<div class="spacer"></div>

				<div [innerHTML]="props.Column[1].text | safeHtml | markdown"></div>

				<div class="spacer"></div>
			</div>

			<div *ngIf="props.Column.length > 2" class="col-md-4 col-12">
				<!-- <img *ngIf="!props.Column[2]?.image" src="./../../../../../assets/images/image.jpg" class="default" alt=" " /> -->
				<img
					*ngIf="props.Column[2].image"
					[src]="props.Column[2].image.url"
					[alt]="props.Column[2].image.alternativeText"
				/>

				<div class="spacer"></div>
				<div class="spacer"></div>
				<div class="spacer"></div>

				<div [innerHTML]="props.Column[2].text | safeHtml | markdown"></div>

				<div class="spacer"></div>
			</div>
		</div>
		<!-- end column -->
	</div>
	<!-- end wrapper -->
</section>
