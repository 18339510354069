import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-info-modal',
	templateUrl: './info-modal.component.html',
	styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialogRef<InfoModalComponent>,
		private event: EventService,
		private router: Router
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: '/other' }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		document.getElementById('exampleModalLongTitle').focus();
	}

	public dismiss(): void {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: '/' }
		};
		this.event.globalEvent(actionOb);
		this.dialog.close();
	}
}
