<div class="educator" [ngClass]="{ focused: focused }" style="display: none;">
	<p *ngIf="props">{{ props.label }}</p>
	<div class="switch-slider">
		<button
			type="checkbox"
			class="toggle active"
			(focus)="focused = true"
			(blur)="focused = false"
			id="toggle"
			(click)="toggleMode()"
			aria-pressed="true"
			[attr.aria-label]="educatorModeOn ? 'educator mode on' : 'educator mode off'"
			aria-live="assertive"
			[ngClass]="{
				'dark-mode': authenticated && educatorModeOn,
				active: educatorModeOn
			}"
		></button>
		<label for="toggle">
			<span class="on">on</span>
			<span class="off">off</span>
		</label>
	</div>
</div>
