<div class="contentDisplayerContainer mb-4">
	<h3 class="mb-2">{{ title }}</h3>
	<div
		*ngIf="type === 'text'"
		class="content"
		[innerHTML]="mdToHtml(content)"
	></div>
	<div *ngIf="type === 'tech'" class="content">
		<app-technical-details [resources]="content"></app-technical-details>
	</div>
	<div *ngIf="type === 'documents'" class="content links">
		<a target="_blank" [href]="doc.document?.url" *ngFor="let doc of content">
			<span>
				<i class="far fa-file-alt"></i>
			</span>
			{{ doc.title }}
		</a>
	</div>
	<div *ngIf="type === 'enum'" class="content">
		<ul>
			<li *ngFor="let element of breakDownByLine(content)">
				<span>{{ stripHtmlTags(element) }}</span>
			</li>
		</ul>
	</div>
	<div *ngIf="type === 'minimetrics'" class="content minimetrics">
		<ul>
			<li>
				<span
					><i role="img" aria-label="Duration" class="fa fa-stopwatch"></i>
					{{ content.duration }}
				</span>
			</li>
			<li>
				<span
					><i role="img" aria-label="Visualizations" class="fa fa-eye"></i>
					{{ content.watches }}
				</span>
			</li>
			<li>
				<span
					><i role="img" aria-label="Shares" class="fa fa-share-square"></i>
					{{ content.shares }}
				</span>
			</li>
		</ul>
	</div>
	<div *ngIf="type === 'tags'" class="content">
		<div class="topic px-3 mr-2" *ngFor="let tag of content">
			{{ tag.title }}
		</div>
	</div>
</div>
