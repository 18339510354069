import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { LocalstorageService } from './../../../../services/localstorage/localstorage.service';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
	selector: 'app-tool-page',
	templateUrl: './tool-page.component.html',
	styleUrls: ['./tool-page.component.scss']
})
export class ToolPageComponent implements OnInit {
	public public_url: string = environment.infiniscope_url;
	public domain: string;
	public creative: any;
	public hivespace: any;
	public communityId: number = environment.community_id;

	constructor(
		private event: EventService,
		public router: Router,
		private api: ApiService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
		public localStorage: LocalstorageService,
		private authenticationService: AuthenticationService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		this.getTools();

		this.authenticationService.refreshToken();
	}

	btnClicked() {
		window.open("https://www.google.com/")
	}

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	public goToPage(url: string) {
		if (!url) return;
		if(isPlatformBrowser(this.platformId)) {
			this.window.nativeWindow.open(url, '_blank');
		}
	}

	public partnerLinkClicked(link: string) {
		this.goToPage(`${link}?id_token=${this.localStorage.getItem('cognito_id_token')}&community_id=${this.communityId}&error_url=${this.public_url + this.router.url}`);
	}

	getTools() {
		this.api.get('tools').toPromise().then((res: any) => {
			this.creative = res.tool.filter((f) => f.type == 'creative');
			this.hivespace = res.tool.filter((f) => f.type == 'hivespace');
		});
	}
}
