import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonComponent } from './components/lesson/lesson.component';
import { LessonBottomComponent } from './components/lesson-right/lesson-bottom/lesson-bottom.component';
import { LessonLeftComponent } from './components/lesson-left/lesson-left.component';
import { LessonRightComponent } from './components/lesson-right/lesson-right.component';
import { LessonResourcesComponent } from './components/lesson-left/lesson-resources/lesson-resources.component';
import { LessonRightDetailComponent } from './components/lesson-right/lesson-right-detail/lesson-right-detail.component';
import { LessonBannerModule } from 'src/app/shared/modules/lesson-banner/lesson-banner.module';
import { TechnicalDetailsModule } from 'src/app/shared/modules/technical-details/technical-details.module';
import { MarkdownModule } from 'src/app/pipes/markdown/markdown.module';
import { SafeHtmlModule } from 'src/app/pipes/safehtml/safehtml.module';

@NgModule({
	declarations: [
		LessonComponent,
		LessonBottomComponent,
		LessonLeftComponent,
		LessonRightComponent,
		LessonResourcesComponent,
		LessonRightDetailComponent
	],
	imports: [
		CommonModule,
		LessonBannerModule,
		TechnicalDetailsModule,
		MarkdownModule,
		SafeHtmlModule
	],
	exports: [
		LessonComponent,
		LessonBottomComponent,
		LessonLeftComponent,
		LessonRightComponent,
		LessonResourcesComponent,
		LessonRightDetailComponent
	]
})
export class LessonModule {}
