<!-- <input type="file"  /> -->
<div class="mb-3">
  <label for="formFile" class="form-label">Do you want to change your profile picture? Please, choose a file below</label>
  <input class="form-control" type="file" id="formFile" (change)="fileChangeEvent($event)">
</div>

<div>
	<image-cropper
		[imageChangedEvent]="imageChangedEvent"
		[maintainAspectRatio]="true"
		[aspectRatio]="3 / 4"
		format="png"
		(imageCropped)="imageCropped($event)"
		(imageLoaded)="imageLoaded($event)"
		(cropperReady)="cropperReady()"
		(loadImageFailed)="loadImageFailed()"
	></image-cropper>
</div>

<div class="btn-space">
	<button type="submit" value="Submit" (click)="close('cancel')" class="btn">
		Cancel
	</button>
	<button type="submit" value="Submit" (click)="close('submit')" class="btn">
		Update Picture
	</button>
</div>

