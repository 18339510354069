import { TechnicalDetailsModule } from './../../shared/modules/technical-details/technical-details.module';
import { LessonBannerModule } from './../../shared/modules/lesson-banner/lesson-banner.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisualizationComponent } from './component/visualization/visualization.component';
import { MarkdownModule } from 'src/app/pipes/markdown/markdown.module';
import { SafeHtmlModule } from 'src/app/pipes/safehtml/safehtml.module';

@NgModule({
	declarations: [VisualizationComponent],
	imports: [
		CommonModule,
		LessonBannerModule,
		TechnicalDetailsModule,
		MarkdownModule,
		SafeHtmlModule
	]
})
export class VisualizationModule {}
