import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { User } from 'src/app/model/user.model';
import { ApiService } from 'src/app/services/API/api.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	public user: User;
	public apiUrl = environment.api_url;
	public get isMobile(): boolean {
		return this.deviceService.isMobile();
	}

	public focused: boolean = false;

	constructor(
		private api: ApiService,
		private auth: AuthenticationService,
		private deviceService: DeviceDetectorService
	) {
		this.auth.currentUser.subscribe((user) => {
			this.user = user;
			let url = this.user?.profilePicture?.formats?.small?.url
				 || this.user?.profilePicture?.formats?.thumbnail?.url
				 || null;
			if (this.user?.profilePicture && url) this.user.profilePicture.url = url;
	
		});
	}

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	get placeholderImg() {
		let img: string = 'assets/images/user-img.png';
		if (this.isNotEmpty(this.user)) {
			if (this.user.role.name == 'Educator') {
				img = 'assets/images/avatar/educator_avatar.png';
			} else if (this.user.role.name == 'Collaborator') {
				img = 'assets/images/avatar/collaborator_avatar.png';
			} else if (this.user.role.name == 'Infiniscope Member') {
				img = 'assets/images/avatar/infiniscope_avatar.png';
			} else if (this.user.role.name == 'Advisory Member') {
				img = 'assets/images/avatar/advisory_avatar.png';
			}
		}
		return img;
	}

	isNotEmpty(obj) {
		if (obj) {
			return Object.keys(obj).length > 0;
		}
		return false;
	}

	ngOnInit() {
		this.auth.refreshToken();
	}
}
