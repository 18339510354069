import { MatDialogModule } from '@angular/material/dialog';
import { SubmitContentModalModule } from './../../shared/modules/submit-content-modal/submit-content-modal.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitContentComponent } from './submit-content/submit-content.component';

@NgModule({
	declarations: [SubmitContentComponent],
	imports: [CommonModule, SubmitContentModalModule, MatDialogModule]
})
export class SubmitContentModule {}
