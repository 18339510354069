<div class="lession-content-left">
	<div class="content">
		<h2>Lesson Info</h2>

		<div class="lession-item">
			<ul>
				<li>
					<span><i class="fa fa-stopwatch" aria-label="duration"></i> {{ lesson.duration }}m </span>
				</li>
				<li>
					<span><i class="fa fa-eye" aria-label="watches"></i>{{ watches }}</span>
				</li>
				<li>
					<span><i class="fa fa-share-square" aria-label="shares"></i>{{ shares }}</span>
				</li>
			</ul>
		</div>
	</div>

	<div class="content">
		<h2>Lesson Resources</h2>

		<app-lesson-resources *ngIf="lesson.LessonResources && lesson.LessonResources.length > 0" [documents]="lesson.LessonResources">
		</app-lesson-resources>

		<span *ngIf="!lesson.LessonResources || lesson.LessonResources.length === 0">
			None Available
		</span>
	</div>

	<div class="content">
		<h2>Alignment Documents</h2>

		<app-lesson-resources *ngIf="lesson.AlignmentDocuments && lesson.AlignmentDocuments.length > 0" [documents]="lesson.AlignmentDocuments">
		</app-lesson-resources>

		<span *ngIf="!lesson.AlignmentDocuments || lesson.AlignmentDocuments.length === 0">
			None Available
		</span>
	</div>

	<div class="content">
		<h2>Technical Details</h2>
		<!-- <p>{{ lesson.technicalDetails }}</p> -->

		<app-technical-details
			*ngIf="lesson.hardwareCompatibility && lesson.hardwareCompatibility.length > 0"
			[resources]="lesson.hardwareCompatibility"
		></app-technical-details>

		<span *ngIf="!lesson.hardwareCompatibility || lesson.hardwareCompatibility.length === 0">
			None Available
		</span>
	</div>

	<div class="content content-language">
		<h2>Languages</h2>
		<ol *ngIf="lesson.languages && lesson.languages.length > 0">
			<li *ngFor="let lang of lesson.languages">
				<p>{{ lang.name }}</p>
			</li>
		</ol>

		<span *ngIf="!lesson.languages || lesson.languages.length === 0">
			None Available
		</span>
	</div>
</div>
