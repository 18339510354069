import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ISubscription } from 'rxjs/Subscription';
import { Lesson } from 'src/app/model/lesson.model';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
	// seachForm : FormGroup;
	public inputSearch: any = null;
	public educatorModeOn: boolean = this.event.educatorMode;
	public lessonSlides: Lesson[] = [];
	public collectionSlides: any[] = [];
	public visualizationSlides: any[] = [];
	public contributedSlides: any[] = [];
	public filteredLessons: Lesson[] = [];
	public filteredCollections: any[] = [];
	public filteredVisualizations: any[] = [];
	public filteredContributed: any[] = [];
	public sortBy: any = null;
	public slider: boolean = true;
	public toggleModal: boolean = false;
	private searchQuery: string = '';
	private sideMenuSearch: any = {
		checkArray: [],
		languageArray: [],
		audienceTypeArray: [],
		hardwareArray: [],
		exploringArray: [],
		gradeRangeArray: [],
		searchTopic: '',
		popularTopics: []
	};
	public lessonLibrary: boolean = true;
	public collectionLibrary: boolean = true;
	public visualizationLibrary: boolean = true;
	public contributedLibrary: boolean = true;
	private grades: any = {
		'K-4': {
			min: 0,
			max: 4
		},
		'5-8': {
			min: 5,
			max: 8
		},
		'9-12': {
			min: 9,
			max: 12
		},
		'13+': {
			min: 13,
			max: 99
		}
	};

	private onInitFlag: boolean = true;
	private allAudience: number = 0;
	private allCreatedBy: number = 0;
	public loading: boolean = true;
	private subscription: ISubscription;

	constructor(
		public api: ApiService,
		private router: Router,
		private event: EventService,
		private deviceService: DeviceDetectorService,
		private localStorage: LocalstorageService,
		private zone: NgZone,
		private auth: AuthenticationService,
		private route: ActivatedRoute,
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
		// this.inputSearch = localStorage.getItem('inputSearch');
	}

	async ngOnInit() {
		this.searchQuery = this.route.snapshot.queryParams.searchQuery ?? this.event.searchQuery;
		// if educator mode on enable the submit lesson button
		this.event.currentData.subscribe(async (e: any) => {
			const data = e.dataob as any;
			if (!data) return;

			if (e.action === 'search') {
				if ((e as Object).hasOwnProperty('dataob')) {
					if ((e as Object)['dataob']) {
						this.searchQuery = (e.dataob as any).input;
					}
				}
	
				await this.getLessons();
				await this.getCollections();
				await this.getVisualization();
				await this.getContributed();
				this.localFilter();
			}
		});
		this.subscription = this.event.currentData.subscribe(async (data: any) => {
			if (data.action === 'set_flag_mode') {
				this.educatorModeOn = data.dataobj.educatorModeOn;
			}
		});

		await this.getLessons();
		await this.getCollections();
		await this.getVisualization();
		await this.getContributed();
		this.localFilter();

		this.loading = false;

		this.auth.refreshToken();
	}

	public get isMobile(): boolean {
		return this.deviceService.isMobile();
	}

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	private async getLessons() {
		if (this.searchQuery) {
			Promise.all([
				this.api
					.get('lessons?title_contains=' + this.searchQuery, null)
					.toPromise(),
				this.api
					.get('lessons?topicTags.title_contains=' + this.searchQuery, null)
					.toPromise(),
				this.api
					.get('lessons?author_contains=' + this.searchQuery, null)
					.toPromise()
			]).then((res: any[]) => {
				let concat = [].concat(...res);
				concat = concat.map((r) =>
					Object.assign({}, r, { cardType: 'lesson' })
				);
				this.lessonSlides = concat.filter(
					(e, i) => concat.indexOf(concat.find((c) => c.id === e.id)) === i
				);
				this.localFilter();
			});
		} else {
			this.api
				.get('lessons', null)
				.toPromise()
				.then((res) => {
					res = res.map((r) => Object.assign({}, r, { cardType: 'lesson' }));
					this.lessonSlides = res;
					this.localFilter();
				});
		}
	}

	private async getCollections() {
		if (this.searchQuery) {
			Promise.all([
				this.api
					.get('collections?Title_contains=' + this.searchQuery, null)
					.toPromise(),
				this.api
					.get('collections?topicTags.title_contains=' + this.searchQuery, null)
					.toPromise(),
				this.api
					.get('collections?lessons.author_contains=' + this.searchQuery, null)
					.toPromise()
			]).then((res: any[]) => {
				let concat = [].concat(...res);
				concat = concat.map((r) =>
					Object.assign({}, r, { cardType: 'collection_model' })
				);
				this.collectionSlides = concat.filter(
					(e, i) => concat.indexOf(concat.find((c) => c.id === e.id)) === i
				);
				this.localFilter();
			});
		} else {
			this.api
				.get('collections', null)
				.toPromise()
				.then((res) => {
					res = res.map((r) =>
						Object.assign({}, r, { cardType: 'collection_model' })
					);
					this.collectionSlides = res;
					this.localFilter();
				});
		}
	}

	private async getVisualization() {
		if (this.searchQuery) {
			Promise.all([
				this.api
					.get('visualizations?title_contains=' + this.searchQuery, null)
					.toPromise(),
				this.api
					.get(
						'visualizations?topicTags.title_contains=' + this.searchQuery,
						null
					)
					.toPromise(),
				this.api
					.get('visualizations?author_contains=' + this.searchQuery, null)
					.toPromise()
			]).then((res: any[]) => {
				let concat = [].concat(...res);
				concat = concat.map((r) =>
					Object.assign({}, r, { cardType: 'visualization' })
				);
				this.visualizationSlides = concat.filter(
					(e, i) => concat.indexOf(concat.find((c) => c.id === e.id)) === i
				);
				this.localFilter();
			});
		} else {
			this.api
				.get('visualizations', null)
				.toPromise()
				.then((res) => {
					res = res.map((r) =>
						Object.assign({}, r, { cardType: 'visualization' })
					);
					this.visualizationSlides = res;
					this.localFilter();
				});
		}
	}

	private async getContributed() {
		if (this.searchQuery) {
			Promise.all([
				this.api
					.get('contributed-contents?title_contains=' + this.searchQuery, null)
					.toPromise(),
				this.api
					.get(
						'contributed-contents?topicTags.title_contains=' + this.searchQuery,
						null
					)
					.toPromise(),
				this.api
					.get('contributed-contents?author_contains=' + this.searchQuery, null)
					.toPromise()
			])
				.then((res: any[]) => {
					let concat = [].concat(...res);
					concat = concat.map((r) =>
						Object.assign({}, r, { cardType: 'contributed_content' })
					);
					this.contributedSlides = concat.filter(
						(e, i) => concat.indexOf(concat.find((c) => c.id === e.id)) === i
					);
					this.localFilter();
				})
				.catch((err) => console.log(err));
		} else {
			this.api
				.get('contributed-contents', null)
				.toPromise()
				.then((res) => {
					res = res.map((r) =>
						Object.assign({}, r, { cardType: 'contributed_content' })
					);
					this.contributedSlides = res;
					this.localFilter();
				})
				.catch((err) => console.log(err));
		}
	}

	private localFilter() {
		this.event.globalEvent({ action: 'closeFlipCard' });

		this.lessonLibrary =
			this.sideMenuSearch.exploringArray.indexOf('Lessons') >= 0 || this.sideMenuSearch.exploringArray.length === 0;
		this.collectionLibrary =
			this.sideMenuSearch.exploringArray.indexOf('Collections') >= 0 || this.sideMenuSearch.exploringArray.length === 0;
		this.visualizationLibrary =
			this.sideMenuSearch.exploringArray.indexOf('Visualizations') >= 0 || this.sideMenuSearch.exploringArray.length === 0;
		this.contributedLibrary =
			this.sideMenuSearch.exploringArray.indexOf('Contributed Content') >= 0 || this.sideMenuSearch.exploringArray.length === 0;
		this.filteredLessons = [];
		this.filteredCollections = [];
		this.filteredVisualizations = [];
		this.filteredContributed = [];

		if (
			this.onInitFlag &&
			this.sideMenuSearch.exploringArray.indexOf('Lessons') >= 0
		) {
			setTimeout(() => {
				this.allCreatedBy = this.sideMenuSearch.checkArray.length;
				this.allAudience = this.sideMenuSearch.audienceTypeArray.length;
			}, 1500);
			this.onInitFlag = false;
		}

		this.filter(this.lessonSlides, 'lessons');
		this.filter(this.collectionSlides, 'collections');
		this.filter(this.visualizationSlides, 'visualizations');
		this.filter(this.contributedSlides, 'contributed');
		this.changeSortSeletor();
	}

	filter(slides: any[], type: string) {
		for (let index = 0; index < slides.length; index++) {
			const l = slides[index];
			if (this.sideMenuSearch.hardwareArray.length > 0) {
				let flag: boolean = false;
				for (const hardware of this.sideMenuSearch.hardwareArray) {
					if (l.hardwareCompatibility) {
						if (l.hardwareCompatibility.find((h) => h.title === hardware)) {
							flag = true;
						}
					} else {
						continue;
					}
				}
				if (!flag) continue;
			}
			if (this.sideMenuSearch.languageArray.length > 0) {
				let flag: boolean = false;
				for (const language of this.sideMenuSearch.languageArray) {
					if (l.languages) {
						if (l.languages.find((h) => h.name === language)) {
							flag = true;
							continue;
						}
					}
				}
				if (!flag) continue;
			}
			if (
				this.sideMenuSearch.checkArray.length < this.allCreatedBy &&
				type !== 'collections'
			) {
				if (this.sideMenuSearch.checkArray.indexOf(l.createdBy ?? 'Infiniscope') < 0)
					continue;
			}
			if (
				this.sideMenuSearch.audienceTypeArray?.length > 0 &&
				this.sideMenuSearch.audienceTypeArray?.length < this.allAudience &&
				type !== 'collections'
			) {
				let flag: boolean = false;
				for (const audienceType of this.sideMenuSearch.audienceTypeArray) {
					if (l.audience_types) {
						if (l.audience_types.find((h) => h.title === audienceType)) {
							flag = true;
							continue;
						}
					}
				}
				if (!flag) continue;
			}
			if (
				this.sideMenuSearch.gradeRangeArray.length > 0 &&
				this.sideMenuSearch.gradeRangeArray.length <
					Object.keys(this.grades).length
			) {
				let hideItem = true;
				if (l.gradeLevel?.length > 0) {
					for (const [key, gL] of Object.entries(l.gradeLevel)) {
						if (this.sideMenuSearch.gradeRangeArray.includes((gL as any).Level)) {
							hideItem = false;
						}
					};
				}
				if (hideItem) continue;
				// if (this.sideMenuSearch.gradeRangeArray.indexOf(l.grade) < 0) continue;
			}
			if (this.sideMenuSearch.popularTopics.length > 0) {
				if (
					l.topicTags.filter(
						(t) => this.sideMenuSearch.popularTopics.indexOf(t.title) >= 0
					).length === 0
				)
					continue;
			}
			if (
				this.sideMenuSearch.searchTopic &&
				this.sideMenuSearch.searchTopic.length > 0
			) {
				if (
					l.topicTags.filter(
						(t) =>
							t.title
								.toLowerCase()
								.indexOf(this.sideMenuSearch.searchTopic.toLowerCase()) >= 0
					).length === 0
				)
					continue;
			}

			if (type == 'lessons') {
				this.filteredLessons.push(l);
			} else if (type == 'collections') {
				this.filteredCollections.push(l);
			} else if (type == 'visualizations') {
				this.filteredVisualizations.push(l);
			} else {
				this.filteredContributed.push(l);
			}
		}
		this.zone.run(() => {
			this.filteredLessons = this.uniq(this.filteredLessons, (it) => it.id);
			this.filteredCollections = this.uniq(
				this.filteredCollections,
				(it) => it.id
			);
			this.filteredVisualizations = this.uniq(
				this.filteredVisualizations,
				(it) => it.id
			);
			this.filteredContributed = this.uniq(
				this.filteredContributed,
				(it) => it.id
			);
		});
	}

	uniq(a, key) {
		let seen = new Set();
		return a.filter((item) => {
			let k = key(item);
			return seen.has(k) ? false : seen.add(k);
		});
	}

	loginModalToggle() {
		this.toggleModal = !this.toggleModal;
	}

	callLoginModalToggleFromBtn(event) {
		if (event) {
			this.loginModalToggle();
		}
	}

	// on click toggle right side content section
	searchStatus: boolean = false;
	searchToggle($event) {
		this.searchStatus = $event;
	}

	public checkboxUpdate(e) {
		this.sideMenuSearch = e;
		this.localFilter();
	}

	goToSubmitLesson() {
		this.router.navigate(['/submit-content']);
	}

	changeSortSeletor() {
		if (this.sortBy === 'name') {
			this.filteredLessons.sort((a, b) => (a.title > b.title ? 1 : -1));
			this.filteredCollections.sort((a, b) => (a.Title > b.Title ? 1 : -1));
			this.filteredVisualizations.sort((a, b) => (a.title > b.title ? 1 : -1));
			this.filteredContributed.sort((a, b) => (a.title > b.title ? 1 : -1));
		} else if (this.sortBy === 'date') {
			this.filteredLessons.sort(
				(a, b) =>
					new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			);
			this.filteredCollections.sort(
				(a, b) =>
					new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			);
			this.filteredVisualizations.sort(
				(a, b) =>
					new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			);
			this.filteredContributed.sort(
				(a, b) =>
					new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			);
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
