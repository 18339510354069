import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from 'src/app/services/event/event.service';

@Component({
	selector: 'app-submit-content-modal',
	templateUrl: './submit-content-modal.component.html',
	styleUrls: ['./submit-content-modal.component.scss']
})
export class SubmitContentModalComponent implements OnInit {
	editable = false;
	formCheck: boolean = false;
	items = [
		{
			description: 'Does your lesson contain science and/or engineering content?',
			yes: false,
			no: false
		},
		{
			description: 'Does your lesson lack misconceptions or is it designed to correct misconceptions?',
			yes: false,
			no: false
		},
		{
			description: 'Does your lesson contain copyright free materials and/or provide citation and attribution where required?',
			yes: false,
			no: false
		}
	];
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialogRef<SubmitContentModalComponent>,
		private newDialog: MatDialog,
		private event: EventService,
		private router: Router
	) {
	}

	ngOnInit(): void {

	}

	public dismiss(): void {
		this.dialog.close('dismiss');
	}

	valueUpdate(value: string, index: number) {
		if (value == 'yes') {
			this.items[index].yes = true;
			this.items[index].no = false;
		} else {
			this.items[index].yes = false;
			this.items[index].no = true;
		}
		for (let index = 0; index < this.items.length; index++) {
			const element = this.items[index];
			if (!element.yes) {
				this.formCheck = false;
				break;
			}
			this.formCheck = true;
		}
	}

	submit() {
		if (this.formCheck) {
			this.dialog.close(this.formCheck);
		}
	}
}
