<div class="row">
	<div class="col-lg-12">
		<div class="content">
			<h2>Teach with This Lesson</h2>
			<p>
				There are three ways to teach with this lesson. Select the option that
				best fits the needs of you and your learners.
			</p>
		</div>
	</div>
</div>

<div class="row row-10">
	<div class="col-lg-4 pad-10" *ngFor="let card of techCards">
		<div class="learn-card">
			<h2>Do you want to</h2>
			<div class="learn-card-body">
				<ul>
					<li *ngFor="let cont of card.card">{{ cont.content }}</li>
				</ul>
				<div class="learn-card-footer">
					<button [disabled]="!authenticated && card.authenticationRequired" [ngClass]="{
							'btn-blue': authenticated || !card.authenticationRequired,
							'btn-grey': !authenticated && card.authenticationRequired
						}" (click)="handleClick(card.location)">{{ card.buttonTitle }}</button>
					<span class="text-required" *ngIf="!authenticated && card.authenticationRequired">
						* Login Required
					</span>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Model to Torus -->
<div class="modal fade modal-auth" [ngClass]="toggleModelTorus ? 'show' : ''" id="modal-signin"
	[ngStyle]="{ display: toggleModelTorus == true ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 tabindex="-1" class="modal-title" id="exampleModalLongTitle">
					How would you like to enroll?
				</h2>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" (click)="toggleModelTorus = !toggleModelTorus">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<div class="login-info">
						<p>
							Choose the enrollment option that best fits your classroom. Click the button below to start enrolling your students.
						</p>
						<p style="color: #ed0000;">
							*Important note: If you are using an LMS, you or your site administrator will need to configure the LMS connection before enrollment. You may want to consider choosing "I don't have an LMS" if you need to enroll your students before a site administrator can complete the configuration.
						</p>
					</div>
					<button (click)="goToPage(!lesson?.torusUrl.includes('argos.education') ? lesson?.torusUrl : lesson?.torusUrl + '?product_id=' + lesson?.productId + '&id_token=' + localStorage.getItem('cognito_id_token') + '&community_id=' + communityId + '&error_url=' + domain + router.url)"
						class="btn btn-blue">I don't have an LMS or I use Google Classroom</button>
					<button (click)="goToPage(lesson.lmsUrl)"
					class="btn btn-blue">I use an LMS like Canvas, Moodle, or Blackboard</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggleModelTorus ? 'modal-backdrop fade show' : ''"></div>
<!-- End Model to Torus -->

<!-- Model to Duplicate Lesson -->
<div class="modal fade modal-auth" [ngClass]="toggleModelDuplicate ? 'show' : ''" id="modal-signin"
	[ngStyle]="{ display: toggleModelDuplicate == true ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title" id="exampleModalLongTitle">
					Looking to make a copy this lesson?
				</h2>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" (click)="toggleModelDuplicate = !toggleModelDuplicate">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<div class="login-info">
						<p>
							As a temporary solution, our team will need to create a copy of the lesson for you. Click the button below and you will receive an invitation to collaborate on the lesson within 48 hours.
						</p>
						<p style="color: #ed0000;">
							*Note: We will make every effort to respond to your request the same day.
						</p>
					</div>
					<button (click)="requestDuplication()"
					class="btn btn-blue">Request a lesson copy</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggleModelDuplicate ? 'modal-backdrop fade show' : ''"></div>
<!-- End Model to Duplicate Lesson -->
