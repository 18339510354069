<main role="main" class="flex-shrink-0 middle-section">
	<div class="middle-inner">
		<section class="hero-banner-section">
			<app-hero-image-slider [heroBanners]="pageData.heroContent"></app-hero-image-slider>
		</section>

		<!-- <section>
			<div class="container">
				<div class="row">
					<button>Community</button>
					<button>Upcoming Events</button>
				</div>
			</div>
		</section> -->

		<section class="featured-section">
			<div class="featured-inner">
				<div class="container">
					<app-slider-community [ngClass]="educatorModeOn ? 'd-block' : 'd-none'"></app-slider-community>
					<app-lesson-container sliderTitle="Featured" [lessons]="featuredLessons" [clipHorizontally]="true">
					</app-lesson-container>
					<app-lesson-container sliderTitle="Newest" [lessons]="slides" [clipHorizontally]="true">
					</app-lesson-container>
				</div>
				<!--
				<button class="row position-fixed p-2 submitLesson" [ngStyle]="submitLessonStyle" [ngClass]="{ disabled: authenticated === false, 'border-0': authenticated }"
					*ngIf="educatorModeOn" (click)="authenticated ? goToSubmitLesson() : loginModelToggel()">
					<div class="col-3 mx-0 px-0 h-100 text-center">
						<div class="icon mx-auto my-auto align-self-center position-relative">
							<i class="fas fa-plus position-absolute"></i>
						</div>
					</div>
					<div class="offset-1 col-8 px-0 textContent font-weight-bold">
						Submit Your<br />Own Lesson
					</div>
				</button>
				-->
			</div>
		</section>
	</div>
</main>

<div class="modal fade modal-auth" [ngClass]="toggelModel ? 'show' : ''" id="modal-signin"
	[ngStyle]="{ display: toggelModel == true ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title" id="exampleModalLongTitle">
					Interested in seeing more?
				</h2>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" (click)="loginModelToggel()">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<div class="login-info">
						<p>
							Login or create an account for full access to simulations,
							community created content, and collaboration tools.
						</p>
					</div>
					<app-login title="loginModel" forgotPasswordRightAlignment="true"></app-login>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggelModel ? 'modal-backdrop fade show' : ''"></div>

<!-- Model to Embed Visualization -->
<div class="modal fade modal-auth" [ngClass]="toggleResetPassword ? 'show' : ''" id="modal-signin"
	[ngStyle]="{ display: toggleResetPassword == true ? 'block' : 'none' }">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h2 class="modal-title" id="exampleModalLongTitle">
					Reset your password
				</h2>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" (click)="toggleResetPassword = !toggleResetPassword">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="login-form-main">
					<input type="email" name="email" [(ngModel)]="emailToSendReset" placeholder="Enter your email address"/>
					<div class="invalid-feedback">
						{{ forgotPasswordError }}
					</div>
					<div class="valid-feedback">
						{{ successMessage }}
					</div>
					<button (click)="fetchUsername()"
					class="btn btn-blue code-btn">Send Reset Code</button>
					
					<input type="text" name="verification" [(ngModel)]="verificationCode" placeholder="Enter your verification code"/>
					<input type="password" name="newpassword" [(ngModel)]="newPassword" placeholder="Enter your new password"/>
					<div *ngIf="resetPasswordError" class="invalid-feedback">
						<div *ngIf="resetPasswordError">
							{{ resetPasswordError }}
						</div>
					</div>
					<button (click)="confirmPassword()"
					class="btn btn-blue">Confirm Password</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div [ngClass]="toggleResetPassword ? 'modal-backdrop fade show' : ''"></div>