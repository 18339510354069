<ng-container *ngIf="lesson">
	<!-- Flip Card Front -->
	<div
		*ngIf="!isMobile"
		class="cardFrontContainer row mx-0 px-2 pb-2 pt-1"
		[ngClass]="{
			left: left,
			right: right,
			head: head,
			foot: foot,
			delay: flippedCard,
			fromCollection: fromCollection
		}"
	>
		<div class="col-12 mx-0 px-0 title mb-3 text-color font-weight-bold">
			<span tabindex="-1" id="cardTitle" [attr.aria-label]="lesson?.title">
				{{
					lesson?.title?.length > 32
						? (lesson?.title | slice: 0:32) + '&hellip;'
						: lesson?.title
				}}
			</span>
			<button
				*ngIf="
					(educatorModeOn &&
						(lesson?.objectType === 'lesson' ||
							lesson?.objectType === 'collection')) ||
					(educatorModeOn && isAuthenticated())
				"
				class="flipCard col-4 mx-0 px-0 text-right"
				(click)="toggleFlip()"
			>
				<img src="assets/images/flip-left.svg" alt="Flip card" /> Educator Info
			</button>
			<button (click)="onCloseCard()" aria-label="Close card">
				<span class="menu-bar-close"> <i class="fas fa-times"></i></span>
			</button>
		</div>
		<div
			class="col-12 mx-0 px-0 content imageContainer"
			aria-hidden="true"
			[ngStyle]="{
				'background-image': 'url(\'' + lesson?.thumbnail?.url + '\')'
			}"
		>
			<app-community-contribution
				class="author"
				[authorImg]="lesson?.authorImage?.url"
				[author]="lesson?.author"
				[createdBy]="lesson?.createdBy"
			>
			</app-community-contribution>
		</div>
		<div
			class="lessonDescription text-color my-1"
			tabindex="3"
			[attr.aria-label]="lesson?.description"
			[innerHTML]="
				(lesson?.description?.length < 250
					? lesson?.description
					: (lesson?.description | slice: 0:250) + '&hellip;'
				)
					| safeHtml
					| markdown
			"
		>
			<span [attr.aria-label]="lesson?.description"></span>
		</div>
		<hr class="col-12 mx-0 px-0 my-2" />
		<div class="row col-12 mx-0 px-0">
			<div class="row col-6 mx-0 px-0 color-faded">
				<div class="row col-3 mx-0 px-0">
					<i
						class="col-5 mx-0 px-0 far fa-clock align-self-center"
						aria-label="duration"
					></i>
					<span class="col-7 mx-0 px-0 minimetricsInfo align-self-center"
						>{{ lesson?.duration }} m</span
					>
				</div>
				<div class="row offset-1 col-3 mr-0 px-0">
					<i
						class="col-5 mx-0 px-0 fas fa-mobile-alt align-self-center"
						aria-label="responsive"
					></i>
					<span class="col-7 mx-0 px-0 minimetricsInfo align-self-center">{{
						lesson?.responsive ? 'Yes' : 'No'
					}}</span>
				</div>
				<div class="row offset-1 col-3 mr-0 px-0">
					<i
						class="col-5 mx-0 px-0 fas fa-globe align-self-center"
						aria-label="language"
					></i>
					<span class="col-7 mx-0 px-0 minimetricsInfo align-self-center">{{
						reduceLanguages(lesson)
					}}</span>
				</div>
			</div>

			<div class="row offset-1 col-5 mr-0 px-0 color-faded">
				<button
					class="row col-3 mx-0 px-0 minimetric"
					[ngClass]="{ clickable: isAuthenticated() }"
					(click)="toggleLike()"
				>
					<i
						class="col-6 mx-0 px-0 fa-heart align-self-center"
						[ngClass]="{
							far: !liked || !isAuthenticated(),
							fas: liked && isAuthenticated()
						}"
					></i>
					<span
						class="col-6 mx-0 px-0 minimetricsInfo align-self-center fa-heart-text"
						[ngClass]="{ authenticated: isAuthenticated() }"
						>{{ likes }}</span
					>
				</button>
				<button
					class="row col-2 mx-0 px-0 minimetric"
					(click)="shareLesson()"
					[ngClass]="{ clickable: isAuthenticated() }"
				>
					<i class="fas fa-share-alt align-self-center"></i>
				</button>
				<div
					[ngClass]="{ clickable: canLaunch() }"
					class="row offset-1 col-6 px-0 minimetric"
				>
					<button
						[ngClass]="{ 'btn-grey': !canLaunch() }"
						class="w-100 py-2 text-white"
						[disabled]="!canLaunch()"
						(click)="goToPage(lesson?.publicUrl)"
					>
						Launch
					</button>
				</div>
			</div>
		</div>
	</div>

	<div
		*ngIf="isMobile"
		class="cardFrontContainer mobile-card mw-100"
		[ngClass]="{
			left: left,
			right: right,
			head: head,
			foot: foot,
			delay: flippedCard
		}"
	>
		<div class="col-12 text-color font-weight-bold mobile-title">
			<i class="col-1 fas back-icon fa-angle-left" (click)="onCloseCard()"></i>
			<span>
				{{
					lesson?.title?.length > 32
						? (lesson?.title | slice: 0:32) + '&hellip;'
						: lesson?.title
				}}
			</span>
			<span
				*ngIf="educatorModeOn"
				class="float-right flipCard col-4 mx-0 px-0 text-right"
				(click)="toggleFlip()"
			>
				<img src="assets/images/flip-left.svg" alt="educator info" /> Educator Info
			</span>
		</div>
		<div class="row justify-content-center">
			<div
				class="col-10 mx-0 px-0 content imageContainer clickable"
				[ngStyle]="{
					'background-image': 'url(\'' + lesson?.thumbnail?.url + '\')'
				}"
			>
				<app-community-contribution
					class="author"
					[authorImg]="lesson?.authorImage?.url"
					[author]="lesson?.author"
                    [createdBy]="lesson?.createdBy"
				></app-community-contribution>
			</div>
		</div>
		<div class="col-12">
			<button
				[disabled]="!isAuthenticated()"
				[ngClass]="{
					'disabled-btn':
						!isAuthenticated() &&
						(lesson.card == 'visualization' || lesson.card == 'contributed')
				}"
				class="w-100 py-2 text-white background-primary"
				(click)="goToPage(lesson?.publicUrl)"
			>
				Launch
			</button>
		</div>
		<div
			class="col-12 lessonDescription text-color my-1"
			[innerHTML]="
				(lesson?.description?.length < 250
					? lesson?.description
					: (lesson?.description | slice: 0:250) + '&hellip;'
				)
					| safeHtml
					| markdown
			"
		></div>
		<hr class="col-12 mx-0 px-0 my-2" />
		<div class="col-12 icon-row bottom">
			<div class="col-2 mx-0 px-0 text-center icon-container">
				<i class="mr-1 far fa-clock align-self-center"></i>
				<span class="minimetricsInfo align-self-center"
					>{{ lesson?.duration }} m</span
				>
			</div>
			<div class="col-2 mx-0 px-0 text-center icon-container">
				<i class="mr-1 fas fa-mobile-alt align-self-center"></i>
				<span class="minimetricsInfo align-self-center">{{
					lesson?.responsive ? 'Yes' : 'No'
				}}</span>
			</div>
			<div class="col-2 mx-0 px-0 text-center icon-container">
				<i class="mr-1 fas fa-globe align-self-center"></i>
				<span class="minimetricsInfo align-self-center">{{
					reduceLanguages(lesson)
				}}</span>
			</div>
			<div
				class="col-2 mx-0 px-0 text-center icon-container"
				[ngClass]="{ clickable: isAuthenticated() }"
				(click)="toggleLike()"
			>
				<i
					class="mr-1 fa-heart align-self-center"
					[ngClass]="{
						far: !liked || !isAuthenticated(),
						fas: liked && isAuthenticated()
					}"
				></i>
				<span
					class="minimetricsInfo align-self-center fa-heart-text"
					[ngClass]="{ authenticated: isAuthenticated() }"
				>
					{{ likes }}</span
				>
			</div>
			<div
				class="col-2 mx-0 px-0 text-center"
				[ngClass]="{ clickable: isAuthenticated() }"
			>
				<i class="fas fa-share-alt align-self-center"></i>
			</div>
		</div>
	</div>

	<!-- Flip Card Back -->

	<div
		*ngIf="!isMobile"
		class="cardBackContainer row mx-0 px-2 pb-2 pt-1"
		[ngClass]="{
			left: left,
			right: right,
			head: head,
			foot: foot,
			delay: !flippedCard,
			fromCollection: fromCollection
		}"
	>
		<!-- Title -->
		<div class="col-8 mx-0 px-0 title mb-3 text-color font-weight-bold">
			{{
				lesson?.title?.length > 32
					? (lesson?.title | slice: 0:32) + '&hellip;'
					: lesson?.title
			}}
		</div>
		<div
			*ngIf="educatorModeOn"
			class="flipCard col-4 mx-0 px-0 text-right"
			(click)="toggleFlip()"
		>
			<img src="assets/images/flip-left.svg" alt="flip card" /> Flip Card
		</div>
		<!-- Image and description -->
		<div class="row col-12 mx-0 px-0">
			<div
				class="col-5 mx-0 px-0 content imageContainer clickable"
				[routerLink]="isMobile ? undefined : ['/lesson/' + lesson?.id]"
				[ngStyle]="{
					'background-image': 'url(\'' + lesson?.thumbnail?.url + '\')'
				}"
			>
				<app-community-contribution
					class="author"
					[authorImg]="lesson?.authorImage?.url"
					[author]="lesson?.author"
                    [createdBy]="lesson?.createdBy"
				></app-community-contribution>
			</div>
			<div class="col-7 mx-0 px-0">
				<div
					class="lessonDescription col-12 mx-0 pl-2 text-color"
					[innerHTML]="lesson?.description | slice: 0:200 | safeHtml | markdown"
				>
					<br />
					<a
						class="color-primary clickable"
						*ngIf="lesson.cardType == 'lesson'"
						[routerLink]="isMobile ? undefined : ['/lesson/' + lesson?.id]"
						>Read more</a
					>
					<a
						class="color-primary clickable"
						*ngIf="lesson.cardType == 'collection_model'"
						[routerLink]="isMobile ? undefined : ['/collection/' + lesson?.id]"
						>Read more</a
					>
					<a
						class="color-primary clickable"
						*ngIf="lesson.cardType == 'visualization'"
						[routerLink]="isMobile ? undefined : ['/lesson/' + lesson?.id]"
						>Read more</a
					>
					<a
						class="color-primary clickable"
						*ngIf="lesson.cardType == 'contributed_content'"
						[routerLink]="isMobile ? undefined : ['/lesson/' + lesson?.id]"
						>Read more</a
					>
				</div>
				<div class="row col-12 mx-0 px-0 mt-4 color-primary">
					<div class="row col-6 mx-0 px-3">
						<div
							class="row col-2 mx-0 px-0 minimetric"
							[ngClass]="{ clickable: isAuthenticated() }"
						>
							<!--
									<i class="col-4 mx-0 px-0 far fa-bookmark align-self-center"></i>
									<span class="col-8 mx-0 pl-1 minimetricsInfo align-self-center fa-bookmark-text" [ngClass]="{authenticated: isAuthenticated()}">{{
										bookmarks
										}}</span>
									-->
						</div>
						<div
							class="row col-5 mx-0 px-0 minimetric"
							[ngClass]="{ clickable: isAuthenticated() }"
							(click)="toggleLike()"
						>
							<i
								class="col-4 mx-0 px-0 fa-heart align-self-center"
								[ngClass]="{
									far: !liked || !isAuthenticated(),
									fas: liked && isAuthenticated()
								}"
							></i>
							<span
								class="col-8 mx-0 pl-1 minimetricsInfo align-self-center fa-heart-text"
								[ngClass]="{ authenticated: isAuthenticated() }"
								>{{ likes }}</span
							>
						</div>
						<div
							class="row col-2 mx-0 px-0 minimetric"
							[ngClass]="{ clickable: isAuthenticated() }"
						>
							<i class="fas fa-share-alt align-self-center"></i>
						</div>
					</div>
					<div class="col-6 mx-0 px-0">
						<button
							class="w-100 py-2 text-white"
							*ngIf="lesson.cardType == 'lesson'"
							[routerLink]="['/lesson', lesson?.id]"
						>
							View Resources
						</button>
						<button
							class="w-100 py-2 text-white"
							*ngIf="lesson.cardType == 'collection_model'"
							[routerLink]="['/collection', lesson?.id]"
						>
							View Resources
						</button>
						<button
							class="w-100 py-2 text-white"
							[disabled]="!isAuthenticated()"
							[ngClass]="{ 'disabled-btn': !isAuthenticated() }"
							*ngIf="lesson.cardType == 'visualization'"
							[routerLink]="['/visualization', lesson?.id]"
						>
							View Resources
						</button>
						<button
							class="w-100 py-2 text-white"
							[disabled]="!isAuthenticated()"
							[ngClass]="{ 'disabled-btn': !isAuthenticated() }"
							*ngIf="lesson.cardType == 'contributed_content'"
							[routerLink]="['/content', lesson?.id]"
						>
							View Resources
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Lesson Info -->
		<div>
			<hr class="col-12 mx-0 px-0 my-2" />
			<div class="lessonInfos row col-12 mx-0 px-0">
				<div class="borderFadded row col-6 mx-0 px-0 border-right">
					<div
						class="title col-12 mx-0 px-0 mb-2 text-color text-center font-weight-bold"
					>
						Lesson Info
					</div>
					<div class="row col-6 mx-0 px-0 mb-2 color-primary">
						<div class="col-4 mx-0 px-0 text-center">
							<i class="fas fa-desktop"></i>
						</div>
						<div class="col-8 mx-0 px-0">
							Technical Details (Compatibility)
						</div>
					</div>
					<div class="row col-6 mx-0 px-0 mb-2 color-primary">
						<div class="col-4 mx-0 px-0 text-center">
							<i class="fas fa-globe"></i>
						</div>
						<div class="col-8 mx-0 px-0">
							{{ reduceLanguages(lesson) }}
						</div>
					</div>
					<div class="row col-6 mx-0 px-0 mb-2">
						<div class="col-4 mx-0 px-0 text-center">
							<i class="far fa-eye"></i>
						</div>
						<div class="col-8 mx-0 px-0">
							{{ watches }}
						</div>
					</div>
					<div class="row col-6 mx-0 px-0 mb-2">
						<div class="col-4 mx-0 px-0 text-center">
							<i class="fas fa-share-square"></i>
						</div>
						<div class="col-8 mx-0 px-0">
							{{ shares }}
						</div>
					</div>
					<div class="row col-12 mx-0 px-0">
						<div class="col-2 mx-0 px-0 text-center">
							<i class="fas fa-stopwatch"></i>
						</div>
						<div class="col-10 mx-0 px-0">{{ lesson?.duration }} m</div>
					</div>
				</div>
				<div class="row col-6 mx-0 pl-2">
					<div
						class="title col-12 mx-0 px-0 text-color text-center font-weight-bold"
					>
						Lesson Resources
					</div>
					<ng-container *ngIf="documents && documents?.length > 0">
						<div
							class="row col-12 mx-0 px-0 color-primary"
							*ngFor="let document of documents | slice: 0:3"
						>
							<div class="col-2 mx-0 px-0 text-center">
								<i class="far fa-file-alt"></i>
							</div>
							<div class="col-10 mx-0 px-0">
								{{ document.title }}
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<!-- Topics -->
		<hr class="col-12 mx-0 px-0 my-2" />
		<div class="topics row col-12 mx-0 px-0">
			<div class="title col-12 mx-0 px-0 text-color font-weight-bold">
				Topics
			</div>
			<div class="col-12 mx-0 px-0">
				<button
					class="topic px-3 mr-2 text-white"
					*ngFor="let topic of topics"
					[ngClass]="{
						authenticated: isAuthenticated(),
						'text-white': !isAuthenticated()
					}"
					(click)="search(topic.title)"
				>
					{{ topic.title }}
				</button>
				<button
					class="topic px-3 mr-2"
					[ngClass]="{
						authenticated: isAuthenticated(),
						'text-white': !isAuthenticated(),
						unclickable: !isAuthenticated()
					}"
					*ngIf="educatorModeOn"
					(click)="addTag()"
				>
					Add a tag <i class="fas fa-plus-circle"></i>
				</button>
			</div>
		</div>
	</div>

	<div
		*ngIf="isMobile"
		class="cardBackContainer mobile-card bottom-offset"
		[ngClass]="{
			left: left,
			right: right,
			head: head,
			foot: foot,
			delay: !flippedCard
		}"
	>
		<div class="col-12 text-color font-weight-bold mobile-title">
			<i class="col-1 fas back-icon fa-angle-left" (click)="onCloseCard()"></i>
			<span>
				{{
					lesson?.title?.length > 32
						? (lesson?.title | slice: 0:32) + '&hellip;'
						: lesson?.title
				}}
			</span>
			<span
				*ngIf="educatorModeOn"
				class="float-right flipCard col-4 mx-0 px-0 text-right"
				(click)="toggleFlip()"
			>
				<img src="assets/images/flip-left.svg" alt="flip card" /> Flip Card
			</span>
		</div>
		<div class="row justify-content-center">
			<div
				class="col-10 mx-0 px-0 content imageContainer clickable"
				[ngStyle]="{
					'background-image': 'url(\'' + lesson?.thumbnail?.url + '\')'
				}"
			>
				<app-community-contribution
					class="author"
					[authorImg]="lesson?.authorImage?.url"
					[author]="lesson?.author"
                    [createdBy]="lesson?.createdBy"
				></app-community-contribution>
			</div>
		</div>
		<div class="col-12 icon-row">
			<button
				[disabled]="!isAuthenticated()"
				class="col-6 w-80 py-2 text-white max-width-49"
				(click)="goToPage(lesson?.publicUrl)"
			>
				Launch
			</button>
			<button
				[disabled]="!isAuthenticated()"
				class="col-6 w-80 py-2 text-color max-width-49"
			>
				View All <i class="fas fa-external-link-alt ml-2"></i>
			</button>
		</div>
		<div
			class="col-12 lessonDescription text-color my-1"
			[innerHTML]="
				(lesson?.description?.length < 250
					? lesson?.description
					: (lesson?.description | slice: 0:250) + '&hellip;'
				)
					| safeHtml
					| markdown
			"
		></div>
		<div class="col-12 icon-row">
			<div class="col-2 mx-0 px-0 text-center icon-container">
				<!--
								<i class="mr-1 far fa-bookmark align-self-center"></i>
								<span class="minimetricsInfo align-self-center fa-bookmark-text" [ngClass]="{authenticated: isAuthenticated()}"> {{ bookmarks }}</span>
								-->
			</div>
			<div class="col-2 mx-0 px-0 text-center icon-container">
				<i class="mr-1 far fa-heart align-self-center"></i>
				<span
					class="minimetricsInfo align-self-center fa-heart-text"
					[ngClass]="{ authenticated: isAuthenticated() }"
				>
					{{ likes }}</span
				>
			</div>
			<div class="col-2 mx-0 px-0 text-center icon-container">
				<i class="fas fa-share-alt align-self-center"></i>
			</div>
		</div>
		<!-- Lesson Info -->
		<div class="lessonInfos my-2 col-12">
			<div class="borderFadded row col-12 mx-0 px-0">
				<div
					class="title col-12 mx-0 px-0 my-2 text-color text-left font-weight-bold"
				>
					Lesson Info
				</div>
				<div class="col-6 mx-0 px-0 mb-3 color-primary icon-container left">
					<div class="mr-2 text-center">
						<i class="fas fa-desktop"></i>
					</div>
					<div class="align-self-center">
						Technical Details (Compatibility)
					</div>
				</div>
				<div class="col-6 mx-0 px-0 mb-3 color-primary icon-container left">
					<div class="mr-2 text-center">
						<i class="fas fa-globe"></i>
					</div>
					<div class="align-self-center">
						{{ reduceLanguages(lesson) }}
					</div>
				</div>
				<div class="col-6 mx-0 px-0 mb-3 icon-container left">
					<div class="mr-2 text-center">
						<i class="far fa-eye"></i>
					</div>
					<div class="align-self-center">
						{{ watches }}
					</div>
				</div>
				<div class="col-6 mx-0 px-0 mb-3 icon-container left">
					<div class="mr-2 text-center">
						<i class="fas fa-share"></i>
					</div>
					<div class="align-self-center">
						{{ shares }}
					</div>
				</div>
				<div class="col-6 mx-0 px-0 mb-3 icon-container left">
					<div class="mr-2 text-center">
						<i class="fas fa-stopwatch"></i>
					</div>
					<div class="align-self-center">{{ lesson?.duration }} m</div>
				</div>
			</div>
		</div>
		<div class="lessonInfos my-2 col-12">
			<div class="borderFadded row col-12 mx-0 px-0">
				<div
					class="title col-12 mx-0 px-0 my-2 text-color text-left font-weight-bold"
				>
					Lesson Resources
				</div>
				<div
					class="row col-12 mx-0 px-0 mb-3 color-primary icon-container left"
				>
					<div class="mr-2 text-center">
						<i class="far fa-file-alt"></i>
					</div>
					<div class="align-self-center">
						Lesson Documents
					</div>
				</div>
				<div
					class="row col-12 mx-0 px-0 mb-3 color-primary icon-container left"
				>
					<div class="mr-2 text-center">
						<i class="far fa-file-alt"></i>
					</div>
					<div class="align-self-center">
						Alignment Documents
					</div>
				</div>
				<div
					class="row col-12 mx-0 px-0 mb-3 color-primary icon-container left"
				>
					<div class="mr-2 text-center">
						<i class="fas fa-check-circle"></i>
					</div>
					<div class="align-self-center">
						Learning Outcomes and Prerequisites
					</div>
				</div>
			</div>
		</div>
		<!-- Topics -->
		<div class="lessonInfos my-2 col-12">
			<div class="topics borderFadded my-2 col-12 mx-0 px-0">
				<div
					class="title col-12 mx-0 my-2 px-0 text-color text-left font-weight-bold"
				>
					Topics
				</div>
				<div class="col-12 mx-0 px-0">
					<div
						class="topic px-3 mr-2 text-white"
						*ngFor="let topic of lesson?.topicTags"
						(click)="search(topic.title)"
					>
						{{ topic.title }}
					</div>
					<!-- <div class="topic px-3 mr-2 text-color">
						Add a tag <i class="fas fa-plus-circle"></i>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</ng-container>
