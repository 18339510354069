import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-technical-details',
	templateUrl: './technical-details.component.html',
	styleUrls: ['./technical-details.component.scss']
})
export class TechnicalDetailsComponent implements OnInit {
	private _resources: any[];

	get resources(): any[] {
		return this._resources;
	}

	@Input() set resources(value: any[]) {
		this._resources = value;
		if (this._resources) {
			this.hardwares.forEach((h) => {
				h.available = Boolean(this._resources.find((r) => r.title === h.title));
			});
			this.browsers.forEach((b) => {
				b.available = Boolean(this._resources.find((r) => r.title === b.title));
			});
		}
	}

	public hardwares: {
		title: string;
		icon: string;
		available?: boolean;
	}[] = [
		{
			title: 'Android Phone / Tablet',
			icon: 'fa fa-mobile-alt'
		},
		{
			title: 'iPad / iPhone',
			icon: 'fa fa-mobile-alt'
		},
		{
			title: 'Mac',
			icon: 'fas fa-desktop'
		},
		{
			title: 'PC',
			icon: 'fas fa-desktop'
		},
		{
			title: 'Chromebook',
			icon: 'fas fa-desktop'
		}
	];

	public browsers: {
		title: string;
		icon: string;
		available?: boolean;
	}[] = [
		{
			title: 'Chrome',
			icon: 'assets/images/icon/chrome.png'
		},
		{
			title: 'Safari',
			icon: 'assets/images/icon/safari.png'
		},
		{
			title: 'FireFox',
			icon: 'assets/images/icon/firfox.png'
		}
	];

	ngOnInit(): void {}

	public hasSupport(name: string): boolean {
		return this.resources.find((r) => r.title === name);
	}
}
