<!-- Start Tool Banner -->
<div class="tool-banner" style="background-image: url(/assets/images/bg-tool.jpg);">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="tool-head">
					<h2>Infiniscope Tools</h2>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- End Tool Banner -->

<!-- remove "not-signed" class to signed in mode -->
<div class="tool-content" [ngClass]="{'not-signed': !authenticated}">
	<div class="container">
		<!-- start first block -->
		<div class="row">
			<!-- start title -->
			<div class="col-md-6 col-12 title-tool">
				<h2>Creative Tools</h2>
				<p>
					Create your own exploratory, digital content to meet the needs of your
					individual learner
				</p>
			</div>
			<!-- end title -->

			<!-- start tool list -->
			<div class="col-12 tool-wrap opt-sign" *ngFor="let tool of creative">
				<div class="row tool-lists">
					<div class="col-md-2 col-12">
						<div class="tool-icon">
							<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText" />
							<h3>{{tool.name}}</h3>
						</div>
					</div>
					<div class="col-md-8 col-12">
						<p class="text-normal">
							{{tool.description}}
						</p>
						<p class="text-hover">
							{{tool.hoverDescription}}
						</p>
					</div>
					<div class="col-md-2 col-12">
						<a *ngIf="tool.externalLink && !(tool.url.includes('argos') || tool.url.includes('tourit'))" [href]="tool.url" target="_blank">
							<button class="btn-tool">Launch</button>
						</a>
						<span *ngIf="tool.externalLink && (tool.url.includes('argos') || tool.url.includes('tourit'))">
							<button class="btn-tool" (click)="partnerLinkClicked(tool.url)">Launch</button>
						</span>
						<a *ngIf="!tool.externalLink" [routerLink]="[tool.url]" routerLinkActive="router-link-active" >
							<button class="btn-tool">Launch</button>
						</a>
					</div>
				</div>
			</div>
			<!-- end tool list -->
		</div>
		<!-- end first block -->

		<!-- start second block -->
		<div class="row">
			<!-- start title -->
			<div class="col-md-6 col-12 title-tool">
				<h2>Hivespace Community</h2>
				<p>
					Create your own exploratory, digital content to meet the needs of your
					individual learner
				</p>
			</div>
			<!-- end title -->

			<!-- start tool list -->
			<div class="col-12 tool-wrap opt-sign" *ngFor="let tool of hivespace">
				<div class="row tool-lists">
					<div class="col-md-2 col-12">
						<div class="tool-icon">
							<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText" />
							<h3>{{tool.name}}</h3>
						</div>
					</div>
					<div class="col-md-8 col-12">
						<p class="text-normal">
							{{tool.description}}
						</p>
						<p class="text-hover">
							{{tool.hoverDescription}}
						</p>
					</div>
					<div class="col-md-2 col-12">
						<a *ngIf="tool.externalLink" [href]="tool.url" target="_blank">
							<button class="btn-tool">Launch</button>
						</a>
						<a *ngIf="!tool.externalLink" [routerLink]="[tool.url]" routerLinkActive="router-link-active" >
							<button class="btn-tool">Launch</button>
						</a>
					</div>
				</div>
			</div>
			<!-- end tool list -->

		</div>
		<!-- end second block -->
	</div>
</div>
