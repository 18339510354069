<div class="col mx-0 px-0 col-custom">
	<div class="banner-bottom">
		<button class="btn py-0" disabled>
			<img [src]="profileImage" alt=" " />
            <div class="author-info">
                {{ author }}
                <br *ngIf="createdBy">
                {{ createdBy }}
            </div>
		</button>
	</div>
</div>
