import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialMediaModalComponent } from './social-media-modal.component';

@NgModule({
	declarations: [SocialMediaModalComponent],
	imports: [CommonModule, MatIconModule]
})
export class SocialMediaModalModule {}
