<div class="user" [ngClass]="{ focused: focused }">
	<div class="user-drop-down user-signin">
		<div class="dropdown">
			<button
				class="btn btn-secondary dropdown-toggle"
				type="button"
				id="dropdownMenuButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				(focus)="focused = true"
				(blur)="focused = false"
				aria-label="profile menu"
			>
				<!-- <i class="fas fa-user"></i> -->
				<img
					[src]="authenticated
						? (user?.profilePicture
							? user.profilePicture?.url
							: placeholderImg
						) : placeholderImg
					"
					id="img-user-profile-icon"
					class="img-fluid img-user"
					[ngClass]="{ mobile: isMobile }"
					alt="user"
				/>
			</button>
			<div id="userDropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<div class="login-form-main">
					<div class="login-header">
						<div class="user-info">
							<h2 *ngIf="authenticated">Your Account</h2>
							<h2 *ngIf="!authenticated">Login</h2>
						</div>
						<div class="user-picture">
							<img
								*ngIf="authenticated"
								[src]="
									user?.profilePicture?.url
										? user.profilePicture.url
										: placeholderImg
								"
								alt="profile icon"
								class="user-login-img"
							/>
							<img
								*ngIf="!authenticated"
								[src]="placeholderImg"
								alt=" "
								class="user-login-img"
							/>
						</div>
					</div>
					<app-login></app-login>
				</div>
			</div>
		</div>
	</div>
</div>
