import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/API/api.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EventService } from 'src/app/services/event/event.service';
import { SubmitErrorComponent } from 'src/app/shared/modules/submit-content-modal/submit-error/submit-error.component';

@Component({
	selector: 'app-submit-tour-it',
	templateUrl: './submit-tour-it.component.html',
	styleUrls: ['./submit-tour-it.component.scss']
})
export class SubmitTourItComponent implements OnInit {
	contentForm: FormGroup;
	loading = false;
	submitted = false;

	constructor(
		private event: EventService,
		private formBuilder: FormBuilder,
		private router: Router,
		private auth: AuthenticationService,
		private alertService: AlertService,
		private api: ApiService,
		private dialog: MatDialog
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit() {
		this.contentForm = this.formBuilder.group({
			firstName: new FormControl('', [Validators.required]),
			lastName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, Validators.email]),
			instituteName: new FormControl('', [Validators.required]),
			instituteType: new FormControl('', [Validators.required]),
			instituteCity: new FormControl('', [Validators.required]),
			instituteState: new FormControl('', [Validators.required]),
			instituteZip: new FormControl('', [Validators.required]),
			subjectTaught: new FormControl(''),
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(8),
				Validators.pattern(
					'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*#?&].{8,}'
				)
			]),
			confirmPassword: new FormControl('', [Validators.required]),
			hearAbout: new FormControl(''),
			reasonJoining: new FormControl('',[Validators.required])
		});

		this.auth.refreshToken();
	}

	isForInvalid() {
		return this.submitted && this.contentForm.invalid;
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.contentForm.controls;
	}

	// convenience getter for easy access to form fields
	get value() {
		return this.contentForm.value;
	}

	onSubmit() {
		const dialogRef2 = this.dialog.open(SubmitErrorComponent, {
			width: '600px',
			panelClass: 'addTagModal',
			// data: res
		});
	}
}
