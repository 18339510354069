import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-submit-error',
	templateUrl: './submit-error.component.html',
	styleUrls: ['./submit-error.component.scss']
})
export class SubmitErrorComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialogRef<SubmitErrorComponent>,
	) {}

	ngOnInit(): void {
		document.getElementById('exampleModalLongTitle').focus();
	}

	public dismiss(): void {
		this.dialog.close();
	}
}
