<div class="mostpopular-div">

	<h3>Most Popular</h3>
	<div class="tag-list">
		<button
			*ngFor="let popular of mostPopulars | slice: 0:3; let i = index"
			class="tag text-white"
			(click)="activateClass(popular)"
			[ngClass]="{ active: popular.active }"
		>
			{{ popular.name }}
		</button>
		<span class="tags" [ngClass]="tagsShow ? 'tags-show' : ''">
			<button
				class="tag text-white"
				[ngClass]="{ active: popular.active }"
				(click)="activateClass(popular)"
				*ngFor="let popular of mostPopulars | slice: 3; let i = index"
				aria-pressed="false"
			>
				{{ popular.name }}
			</button>
		</span>
		<button (click)="showAll()" class="showall" aria-label="show all">
			{{ tagsShow ? '-Show less' : '+Show all' }}
		</button>
	</div>
</div>
