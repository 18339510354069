<div class="sub-header" [class.sub-dark]="currentPage == '/search'">
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12 nav-container">
				<div class="logo" *ngIf="logoUrl">
					<a routerLink="/" aria-label="Infiscope Logo">
						<img src="{{ logoUrl }}" class="menu-logo" alt="menu logo" />
					</a>
				</div>
				<button class="navbar-toggler" [attr.aria-expanded]="naveOpen" [attr.aria-hidden]="!naveOpen"
					(click)="toggleNav()" type="button">
					<span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
				</button>
				<nav class="collapse1 navbar-collapse sidenav-new-menu" id="mySidenav2" [class.width-menu]="naveOpen">
					<a href="javascript:void(0)" class="closebtn" (click)="toggleNav()" *ngIf="naveOpen">
						<span>
							<span class="menu-bar-logo"> <i class="fas fa-bars"></i></span>
							<img src="assets/images/mobile-logo-gray.png" class="menu-mobile-logo" alt="mobile logo" />
						</span>
						<button>
							<span class="menu-bar-close"> <i class="fas fa-times"></i></span>
						</button>
					</a>
					<ul role="menubar" class="navbar-nav" *ngIf="props">

						<li role="none" class="nav-item dropdown" *ngIf="props.firstMenuData">
							<button role="menuitem" class="nav-link smoth-scroll dropdown-toggle" [attr.aria-hidden]="!naveOpen" href="#" id="#menu1"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{{ props.firstMenuData.title }}
							</button>
							<ul role="menu" class="dropdown-menu" aria-labelledby="" aria-label="about">
								<li role="none" class="dropdown-item" *ngFor="let link of props.firstMenuData.Links" >
									<a role="menuitem" (click)="openLink(link)" tabindex="-1">{{ link.linkText }}</a>
								</li>
							</ul>
						</li>

						<li role="none" class="nav-item dropdown" *ngIf="props.secondMenuData">
							<button role="menuitem" class="nav-link smoth-scroll dropdown-toggle" [attr.aria-hidden]="!naveOpen" href="#" id="#menu2"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
								{{ props.secondMenuData.title }}
							</button>
							<ul role="menu"class="dropdown-menu" aria-labelledby="" aria-label="stories">
								<li role="none" class="dropdown-item" *ngFor="let link of props.secondMenuData.Links" >
									<a role="menuitem" [target]="link.openInNewTab ? '_blank' : '_self'" [href]="[link.linkUrl]" >{{ link.linkText }}</a>
								</li>
							</ul>
						</li>

						<li role="none" class="nav-item dropdown" *ngIf="props.thirdMenuData">
							<button role="menuitem" class="nav-link smoth-scroll dropdown-toggle" [attr.aria-hidden]="!naveOpen" href="#" id="#menu2"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{{ props.thirdMenuData.title }}
							</button>
							<ul role="menu" class="dropdown-menu" aria-labelledby="" aria-label="Educator PD">
								<li role="none" class="dropdown-item" *ngFor="let link of props.thirdMenuData.Links" >
									<a role="menuitem" [target]="link.openInNewTab ? '_blank' : '_self'" [href]="[link.linkUrl]">{{ link.linkText }}</a>
								</li>
							</ul>
						</li>

						<li role="none" class="nav-item dropdown" *ngIf="props.fourthMenuData">
							<button role="menuitem" class="nav-link smoth-scroll dropdown-toggle" [attr.aria-hidden]="!naveOpen" href="#" id="#menu2"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{{ props.fourthMenuData.title }}
							</button>
							<ul role="menu" class="dropdown-menu" aria-labelledby="" aria-label="Connect">
								<li role="none" class="dropdown-item" *ngFor="let link of props.fourthMenuData.Links" >
									<a role="menuitem" [target]="link.openInNewTab ? '_blank' : '_self'" [href]="[link.linkUrl]" >{{ link.linkText }}</a>
								</li>
							</ul>
						</li>

						<li role="none" class="nav-item dropdown" *ngIf="props.fifthMenuData">
							<button role="menuitem" class="nav-link smoth-scroll dropdown-toggle" [attr.aria-hidden]="!naveOpen" href="#" id="#menu2"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
								{{ props.fifthMenuData.title }}
							</button>
							<ul role="menu" class="dropdown-menu" aria-labelledby="" aria-label="media">
								<li role="none" class="dropdown-item" *ngFor="let link of props.fifthMenuData.Links" >
									<a role="menuitem" [target]="link.openInNewTab ? '_blank' : '_self'" [href]="[link.linkUrl]" >{{ link.linkText }}</a>
								</li>
							</ul>
						</li>

					</ul>
				</nav>

				<!-- layer modile and ipad menu -->
				<div id="cd-shadow-layer" onclick="closeNav2()" style="display: none;"></div>
				<!-- laeyer end -->
			</div>
		</div>
	</div>
</div>
