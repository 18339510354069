import { VisualizationContainerModule } from './../../shared/modules/visualization-container/visualization-container.module';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SliderCommunityModule } from 'src/app/shared/modules/slider-community/slider-community.module';
import { LessonContainerModule } from 'src/app/shared/modules/lesson-container/lesson-container.module';
import { SearchComponent } from './components/search/search.component';
import { SearchFormModule } from './components/search-form/search-form.module';
import { AuthModule } from '../auth/auth.module';
import { CollectionContainerModule } from 'src/app/shared/modules/collection-container/collection-container.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [SearchComponent],
	imports: [
		CommonModule,
		AuthModule,
		FormsModule,
		SliderCommunityModule,
		LessonContainerModule,
		CollectionContainerModule,
		SearchFormModule
	]
})
export class SearchModule {}
