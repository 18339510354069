import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';

@Component({
	selector: 'app-bookmark',
	templateUrl: './bookmark.component.html',
	styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {

	public selected: boolean = false;

	// public arr = [1,2,3,4, 5, 6, 7, 8, 9, 10];
	public arr = [1,2,3];
	// public arr = [];

	constructor(
		private event: EventService,
		private router: Router,
		public api: ApiService,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		this.authenticationService.refreshToken();
	}

	public get deleteButton() {
		return this.selected;
	}

	changeColso(event) {
		this.selected = !this.selected
	}

	deleteSelected() {

	}
}
