import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
	selector: 'app-hero-image-slider',
	templateUrl: './hero-image-slider.component.html',
	styleUrls: ['./hero-image-slider.component.scss']
})
export class HeroImageSliderComponent implements OnInit, OnDestroy {
	@Input() heroBanners: any[];

	public bannerIndex: number = 0;

	public lastUpdate: number = Date.now();
	public changeDelay: number = 1000 * 5; // 5 seconds
	public updater: any;
	public playButton: boolean = false;

	public get windowWidth(): number {
		if(isPlatformBrowser(this.platformId)) {
			return this.window.nativeWindow.innerWidth;
		}
		return 1024;
	}

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
	) {}

	ngOnInit(): void {
		if(isPlatformBrowser(this.platformId)) {
			this.play();
		}
	}

	ngOnDestroy(): void {
		if (this.updater) {
			this.stop();
		}
	}

	play() {
		this.updater = setInterval(() => {
			if (Date.now() - this.lastUpdate > this.changeDelay) {
				this.moveIndex(1);
				this.lastUpdate = Date.now();
			}
		}, 1000);
		this.playButton = false;
	}

	stop() {
		clearInterval(this.updater);
		this.playButton = true
	}

	openUrl(url: string) {
		window.location.href = url;
	}

	public moveIndex(d: number) {
		this.bannerIndex += d;
		if (this.bannerIndex < 0) this.bannerIndex = this.heroBanners.length - 1;
		if (this.bannerIndex > this.heroBanners.length - 1) this.bannerIndex = 0;
		this.lastUpdate = Date.now();
	}
}
