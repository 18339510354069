import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './components/page.component';
import { BannerModule } from 'src/app/shared/blocks/banner/banner.module';
import { ColumnsModule } from 'src/app/shared/blocks/columns/columns.module';
import { GalleryModule } from 'src/app/shared/blocks/gallery/gallery.module';

@NgModule({
	declarations: [PageComponent],
	imports: [
		CommonModule,
		BannerModule,
		ColumnsModule,
		GalleryModule
	]
})
export class PageModule {}
