import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiService } from './../../../services/API/api.service';
import { environment } from 'src/environments/environment';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact/contact.service';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';

@Component({
	selector: 'app-report-issue',
	templateUrl: './report-issue.component.html',
	styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent implements OnInit {
	issueForm: FormGroup;

	natureIssues = [
		{ value: 'abuse', name: 'Abuse' },
		{ value: 'account', name: 'Account' },
		{ value: 'exploration', name: 'Exploration' },
		{ value: 'resources', name: 'Resources' },
		{ value: 'other', name: 'Other' }
	];

	public btnDisableStatus: boolean = true;

	private captchaToken: string;
	public recaptchaSiteKey = environment.recaptcha_site_key;

	constructor(
		private formBuilder: FormBuilder,
		private contact: ContactService,
		private router: Router,
		private event: EventService,
		private api: ApiService,
		private authenticationService: AuthenticationService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
		this.issueForm = formBuilder.group({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			email: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.email])
			),
			phone: new FormControl('', Validators.required),
			issueType: new FormControl(null, Validators.required),
			message: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.minLength(4)])
			),
			token: new FormControl(null, Validators.required),
			hasToken: new FormControl(true),
			subject: new FormControl('Reporting an issue'),
			to: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.email])
			),
		});
	}

	ngOnInit(): void {
		this.api.get('emails').toPromise().then((res) => {
			this.issueForm.patchValue({ to: res.issueEmail });
		}).catch((err) => {
			this.ngOnInit();
		});

		this.authenticationService.refreshToken();
	}

	public captchaCallback(token: string) {
		this.btnDisableStatus = !this.btnDisableStatus;
		this.captchaToken = token;
	}

	public submitIssue() {
		this.issueForm.patchValue({ token: this.captchaToken });
		if (this.issueForm.valid) {
			this.contact
				.sendEmail(this.issueForm.value)
				.then((res) => {
					console.log(res);
					this.issueForm.reset();
				})
				.catch((err) => console.log(err));
		}
	}
}
