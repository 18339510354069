import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-community-contribution',
	templateUrl: './community-contribution.component.html',
	styleUrls: ['./community-contribution.component.scss']
})
export class CommunityContributionComponent implements OnInit {
	@Input() author: string;
	@Input() createdBy: string;
	@Input() authorImg: string;
	ngOnInit(): void {}

	get profileImage() {
		return this.authorImg || 'assets/images/avatar/educator_avatar.png'
	}
}
