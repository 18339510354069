import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-content-displayer',
	templateUrl: './content-displayer.component.html',
	styleUrls: ['./content-displayer.component.scss']
})
export class ContentDisplayerComponent implements OnInit {
	@Input() title: string;
	@Input() type:
		| 'text'
		| 'documents'
		| 'tech'
		| 'enum'
		| 'minimetrics'
		| 'tags';

	@Input() content: any;
	public api: string = environment.api_url;

	constructor() {}

	ngOnInit(): void {}

	public stripHtmlTags(htmlString) {
		const div = document.createElement('div');
		div.innerHTML = htmlString;
		return div.textContent || div.innerText || '';
	}

	public breakDownByLine(content: string = "") {
		content = content ? content : "";
		return content.split('\n');
	}

	public mdToHtml(content: string = "") {
		content = content ? content : "";
		const translateList = [
			{
				from: '\n',
				to: '<br>'
			}
		];
		for (const translation of translateList) {
			content = content.split(translation.from).join(translation.to);
		}
		return content;
	}
}
