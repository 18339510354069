import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApplicationRef, Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Collection } from 'src/app/model/collection';
import { Lesson } from 'src/app/model/lesson.model';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';
import { ITopic } from 'src/app/shared/modules/add-tag-modal/add-tag-modal/add-tag-modal.component';
import { environment } from 'src/environments/environment';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
	selector: 'app-collection',
	templateUrl: './collection.component.html',
	styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
	public techCards = [
		// {
		// 	card: [
		// 		{
		// 			content: 'see what is included in this collection?'
		// 		},
		// 		{
		// 			content: 'see an overview of the content?'
		// 		},
		// 		{
		// 			content: 'open individual components?'
		// 		}
		// 	],
		// 	button: 'Preview Collection',
		// 	authenticationRequired: true,
		// 	location: 'preview',
		// 	url: '',
		// 	howToTxt: 'How to preview',
		// 	howToUrl: 'https://round-currency-221.notion.site/Previewing-a-Collection-Under-Development-32df72fb0e4c4c7f920de330c6584eb6'
		// },
		{
			card: [
				{
					content: 'enroll your learners in the collection?'
				},
				{
					content: 'monitor your learners\' progress?'
				},
				{
					content: 'integrate the collection into your pre-existing online course?'
				}
			],
			button: 'Enroll Students',
			authenticationRequired: true,
			location: 'auth',
			url: '',
			howToTxt: 'How to enroll',
			howToUrl: 'https://round-currency-221.notion.site/Enrolling-Learners-in-a-Collection-Under-Development-23a6306101994d13840e432b9dc1fc48'
		},
		// {
		// 	card: [
		// 		{
		// 			content: 'add content?'
		// 		},
		// 		{
		// 			content: 'delete content?'
		// 		}
		// 	],
		// 	button: 'Customize this Collection',
		// 	authenticationRequired: true,
		// 	location: 'customize',
		// 	url: '',
		// 	howToTxt: 'How to customize',
		// 	howToUrl: 'https://round-currency-221.notion.site/Customizing-a-Collection-Under-Development-562530f98bda4d1bb36845993e2c8eeb'
		// },
	];

	private collectionId: string;
	public collection: Collection;
	public visualizations: any[];
	public contributedContent: any[];
	public lessons: Lesson[];
	public nasaResources: any[]; 
	public likes: number = 0;
	public shares: number = 0;
	public watches: number = 0;
	public bookmarks: number = 0;
	public toggleModelTorus: boolean = false;

	public get educatorMode(): boolean {
		return this.event.educatorMode;
	}

	public get authenticated(): boolean {
		return this.api.authenticated;
	}

	constructor(
		public localStorage: LocalstorageService,
		private route: ActivatedRoute,
		private appRef: ApplicationRef,
		private api: ApiService,
		private router: Router,
		private event: EventService,
		private miniMetricService: MiniMetricsService,
		private authenticationService: AuthenticationService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this.collectionId = params.id;
			this.likes = 0;
			this.bookmarks = 0;
			this.shares = 0;
			this.watches = 0;

			this.api
				.get('collections/' + this.collectionId, null)
				.subscribe((collection) => {
					this.collection = collection;
					this.likes += collection.likes || 0;
					this.watches += collection.watches || 0;
					this.bookmarks += collection.bookmarks || 0;
					this.shares += collection.shares || 0;
					// console.log(this.likes);
					if(this.collection?.lessons?.length) {
						this.api
							.get(
								'lessons?id=' +
									this.collection.lessons.map((l) => l.id).join('&id='),
								null
							)
							.subscribe((lessons) => {
								this.lessons = lessons.map((r) =>
									Object.assign({}, r, { cardType: 'lesson' })
								);
							});
					}
					// console.log('this.collection', this.collection)
					if(this.collection?.NasaResources?.length) {
						this.nasaResources = this.collection.NasaResources;
					}
					if(this.collection?.visualizations?.length) {
						this.api
							.get(
								'visualizations?id=' +
									this.collection.visualizations.map((l) => l.id).join('&id='),
								null
							)
							.subscribe((visualizations) => {
								this.visualizations = visualizations.map((r) =>
									Object.assign({}, r, { cardType: 'visualization' })
								);
							});
					}
					if(this.collection?.contributedContent?.length) {
						this.api
							.get(
								'contributed-contents?id=' +
									this.collection.contributedContent
										.map((l) => l.id)
										.join('&id='),
								null
							)
							.subscribe((contributedContent) => {
								this.contributedContent = contributedContent.map((r) =>
									Object.assign({}, r, { cardType: 'contributed_content' })
								);
							});
					}
				});
			this.miniMetricService
				.getMiniMetrics('collection_model', parseInt(this.collectionId))
				.subscribe((res) => {
					this.likes += res.likes;
					this.bookmarks += res.bookmarks;
					this.shares += res.shares;
					this.watches += res.watches;
				});
		});

		this.authenticationService.refreshToken();
	}

	public async searchForTopic(topic: ITopic) {
		if (!this.authenticated || !this.educatorMode) {
			return;
		}
		if (this.router.url.indexOf('/search') !== 0) {
			await this.router.navigate(['search']);
			await this.appRef.tick();
		}
		this.event.globalEvent({
			action: 'search',
			dataob: {
				input: topic.title
			}
		});
	}
	public get topics() {
		if (!this.collection.topicTags) return [];
		return this.collection.topicTags.filter((t) => t.approved);
	}

	public breakDownElements(content, target, separation) {
		let cont = [...content];
		if (target) cont = cont.map((c) => c[target]);
		return cont.join(separation);
	}

	public goToPage(url: string) {
		if (!url) return;
		if (this.toggleModelTorus) this.toggleModelTorus = false;
		if (url.includes('argos.education')) {
			url += '?product_id=' + this.collection?.productId
				+ '&id_token=' + this.localStorage.getItem('cognito_id_token')
				+ '&community_id=' + environment.community_id
				+ '&error_url=' + environment.infiniscope_url + this.router?.url;
		}
		this.window.nativeWindow.open(url, '_blank');
	}

	getInt(value) {
		return parseInt(value)
	}

	public handleTorusClick() {
		this.toggleModelTorus = true;
		document.getElementById('exampleModalLongTitle').focus();
	}
}
