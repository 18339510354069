import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';
import { MarkdownModule } from 'src/app/pipes/markdown/markdown.module';
import { SafeHtmlModule } from 'src/app/pipes/safehtml/safehtml.module';

@NgModule({
	declarations: [GalleryComponent],
	imports: [CommonModule, MarkdownModule, SafeHtmlModule],
	exports: [GalleryComponent]
})
export class GalleryModule {}
