import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/API/api.service';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';

@Component({
	selector: 'app-lesson-banner',
	templateUrl: './lesson-banner.component.html',
	styleUrls: ['./lesson-banner.component.scss']
})
export class LessonBannerComponent implements OnInit {
	public apiUrl = environment.api_url;
	@Input() lesson: any;
	@Input() likes: number = 0;
	@Input() bookmarks: number = 0;
	@Input() bannerType: string;
	private loading: boolean = false;
	public liked: boolean = false;
	private minimetricId: string;
	public showVideo: boolean = false;

	constructor(private sanitizer: DomSanitizer, private api: ApiService, private miniMetricService: MiniMetricsService) {}

	ngOnInit(): void {
		this.loading = false;
		
		if (this.api.authenticated) {
			this.api
				.get(
					`minimetrics?${this.miniMetricService.getQuery(this.bannerType)}=${this.lesson.id}&user.id=${this.api.user.id}&type=like`
				)
				.subscribe((res) => {
					res = res || [];
					this.liked = res.length > 0;
					if (this.liked) this.minimetricId = res[0].id;
				});
		}
	}

	public sanitizeHtml(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	public toggleLike() {
		this.miniMetricService.toggleLike(this.bannerType, this.lesson.id).subscribe((res) => {
			this.liked = res > 0;
			this.likes += res;
		})
	}
}
