import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import 'rxjs/Rx';
@Component({
	selector: 'app-import',
	templateUrl: './import.component.html',
	styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

	// Variable to store shortLink from api response
	errorMsg: string = "";
	successMsg: string = "";
	loading: boolean = false; // Flag variable
	apiKey: string = "";
	file: File = null; // Variable to store file

	constructor(private http:HttpClient) { }

	ngOnInit(): void {
	}

	// On file Select
	onChange(event) {
		this.file = event.target.files[0];
	}

	// OnClick of button Upload
	onUpload() {
		this.errorMsg = "";
		this.successMsg = "";
		if(this.file) {
			this.loading = !this.loading;
			this.upload(this.file).subscribe(
				(event: any) => {
					if (typeof (event) === 'object') {
						this.successMsg = 'Success';
						this.loading = false; // Flag variable
					}
				},
				(msg: any) => {
					if(msg.statusText === 'OK') {
						this.successMsg = 'Success';
					} else {
						this.errorMsg = msg.statusText;
					}
					this.loading = false;
				}
			);
		} else {
			this.errorMsg = 'No file selected';
		}
	}

	// OnClick of button Upload
	onDownload() {
		this.errorMsg = "";
		this.successMsg = "";
		this.loading = !this.loading;
		this.http.post(environment.api_url + 'users/export', {
			'key': this.apiKey
		}, {
			responseType: 'blob'
		}).subscribe(
			(data: any) => {
				const blob = new Blob([data], { type: 'text/csv' });
				const url= window.URL.createObjectURL(blob);
				window.open(url);
				this.successMsg = 'Success';
				this.loading = false; // Flag variable
			},
			(msg: any) => {
				if(msg.statusText === 'OK') {
					this.successMsg = 'Success';
				} else {
					this.errorMsg = msg.statusText;
				}
				this.loading = false;
			}
		);
	}

	upload(file):Observable<any> {

		// Create form data
		const formData = new FormData();

		// Store form name as "file" with file data
		formData.append("file", file, file.name);
		formData.append("key", this.apiKey)

		// Make http post request over api
		// with formData as req
		return this.http.post(environment.api_url + 'users/import', formData);
	}
}
