import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTagModalComponent } from './add-tag-modal/add-tag-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
	declarations: [AddTagModalComponent],
	imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule],
	exports: [AddTagModalComponent]
})
export class AddTagModalModule {}
