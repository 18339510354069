import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/API/api.service';

@Injectable({ providedIn: 'root' })
export class MiniMetricsService {

	constructor(private api: ApiService) {
		
	}

	getMiniMetrics(contentType: string, contentId: number): Observable<{likes: number, shares: number, watches:number, bookmarks: number}> {
		return new Observable((subscriber) => {
			this.api
				.get(`minimetrics?${this.getQuery(contentType)}=${contentId}`, null)
				.toPromise()
				.then((res) => {
					if (!res) return;
					let likes = res.filter((r) => r.type === 'like').length;
					let shares = res.filter((r) => r.type === 'share').length;
					let watches = res.filter((r) => r.type === 'watch').length;
					let bookmarks = res.filter((r) => r.type === 'bookmark').length;
					subscriber.next({likes, shares, watches, bookmarks})
			});
		});
	}

	increaseWatch(contentType: string, contentId: number) {
		let minimetricPayload = {
			type: 'watch'
		};

		minimetricPayload[contentType] = contentId;

		this.api.post('minimetrics', minimetricPayload).subscribe();
	}

	increaseShare(contentType: string, contentId: number) {
		let minimetricPayload = {
			type: 'share'
		};

		minimetricPayload[contentType] = contentId;

		this.api.post('minimetrics', minimetricPayload).subscribe();
	}

	toggleLike(contentType: string, contentId: number, ): Observable<number> {
		return new Observable((subscriber) => {
			if (!this.api.authenticated) {
				subscriber.next(0);
				return;
			}

			this.api
				.get(
					`minimetrics?${this.getQuery(contentType)}=${contentId}&user.id=${this.api.user.id}&type=like`
				)
				.subscribe((res) => {
					res = res || [];
					if(res.length > 0) {
						this.api.delete('minimetrics/' + res[0].id)
						.subscribe(() => subscriber.next(-1));
					} else {
						let minimetricPayload = {
							type: 'like',
							user: this.api.user.id
						};

						minimetricPayload[contentType] = contentId;

						this.api
							.post('minimetrics', minimetricPayload)
							.subscribe(() => subscriber.next(1));
					}
				});
		});
	}

	getQuery(contentType: string): string {
		let query = '';

		if(contentType === 'lesson') {
			query = 'lesson.id';
		} else if(contentType === 'contributed_content') {
			query = 'contributed_content.id';
		} else if(contentType === 'collection_model') {
			query = 'collection_model.id';
		} else if(contentType === 'visualization') {
			query = 'visualization.id';
		}
		return query;
	}
}
