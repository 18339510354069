<body>
	<section>
		<div class="container">
			<ng-container *ngIf="events && events.length > 0">
				<ng-container *ngFor="let event of events; let i = index">
					<div class="first-event" *ngIf="i==0">
						<img *ngIf="!event.picture" src="./../../../../../assets/images/image.jpg" alt=" " />
						<img *ngIf="event.picture" [src]="event.picture.url" [alt]="event.picture.alternativeText || event.title" />
						<h2>{{event.title}}1</h2>
						<p><i>{{event.date | date: 'MM/dd/YYYY'}}</i></p>
						<p class="description">{{event.description}}</p>
						<button class="btn" (click)="goTo(event.url)">Read More</button>
					</div>
					<hr *ngIf="(events.length > 1) && (i == 0)">
					<div class="general-event" *ngIf="((i%2)==0) && (i!=0)">
						<div class="row">
							<h4>{{event.title}}2</h4>
							<div class="col-6">
								<p><i>{{event.date | date: 'MM/dd/YYYY'}}</i></p>
								<p class="description">{{event.description}}</p>
								<button class="btn" (click)="goTo(event.url)">Read More</button>
							</div>
							<div class="col-6">
								<img *ngIf="!event.picture" src="./../../../../../assets/images/image.jpg" alt=" " />
								<img *ngIf="event.picture" [src]="event.picture.url" [alt]="event.picture.alternativeText || event.title" />
							</div>
						</div>
					</div>
					<div class="general-event" *ngIf="(i%2)!=0">
						<div class="row">
							<h4>{{event.title}}3</h4>
							<div class="col-6">
								<img *ngIf="!event.picture" src="./../../../../../assets/images/image.jpg" alt=" " />
								<img *ngIf="event.picture" [src]="event.picture.url" [alt]="event.picture.alternativeText || event.title" />
							</div>
							<div class="col-6">
								<p><i>{{event.date | date: 'MM/dd/YYYY'}}</i></p>
								<p class="description">{{event.description}}</p>
								<button class="btn" (click)="goTo(event.url)">Read More</button>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>
			<ng-template *ngIf="!events || events.length < 1">
				<div class="no-events">
					<!-- <div>
					</div> -->
					<h2>
						No events yet!
					</h2>
				</div>
			</ng-template>
		</div>
	</section>
</body>
