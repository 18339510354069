<div class="submission-page">
	<div class="container">
		<div class="row">
			<div class="col-md-8 offset-md-2 col-12">
				<!-- Start Submission Header -->
				<div class="submit-heading">
					<h1>Join the Infiniscope Slack Hivespace today.</h1>
					<p style="font-size: 20px;">
						Already connected to Slack? <a href="https://infiniscope-hivespace.slack.com" target="_blank">Click here to access.</a>
					</p>
					<p>
						Join the Infiniscope Slack Hivespace to connect with othe educators
						in the network and share the best practices fot digital teaching and
						learning
					</p>
				</div>
				<!-- End Submission Header -->

				<!-- Start Form -->
				<div class="submit-form">
					<form [formGroup]="submissionForm" (ngSubmit)="submit()">
						<div class="submit-header">
							<h2>Infiniscope Slack Hivespace Request</h2>
						</div>

						<div class="submit-body">
							<div class="sub-group">
								<label for="first-name">Name <span>(Required)</span></label>
								<input aria-required="true"  id="first-name" type="text" formControlName="firstName" placeholder="First Name" />
							</div>

							<div class="sub-group">
								<label for="last-name" class="hide-label">Last Name</label>
								<input aria-required="true"  id="last-name" type="text" formControlName="lastName" placeholder="Last Name" />
							</div>

							<div class="sub-group mt">
								<label for="email">Email <span>(Required)</span></label>
								<input aria-required="true"  id="email" type="text" formControlName="email" placeholder="Email Address" />
							</div>

							<div class="sub-group">
								<p>
									*please provide the email address associated your existing
									Slack account (if applicable)
								</p>
							</div>
						</div>

						<div class="submit-footer">
							<button [disabled]="!submissionForm.valid" type="submit" value="Submit" class="btn-submit">
								Send Request
							</button>
						</div>
					</form>
				</div>
				<!-- End Form -->
			</div>
		</div>
	</div>
</div>
