import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentFooterComponent } from './content-footer/content-footer.component';

@NgModule({
	declarations: [ContentFooterComponent],
	imports: [CommonModule],
	exports: [ContentFooterComponent]
})
export class ContentFooterModule {}
