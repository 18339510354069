import { Component, Input, OnInit } from '@angular/core';
import { type } from 'os';
import { Image } from '../../types/image'

type Column = {
	id: number;
	image: Image;
	text: string;
}

type Columns = {
	__component: string;
	id: number;
	columns: Column[]
	darkMode: boolean;
}

@Component({
	selector: 'app-columns',
	templateUrl: './columns.component.html',
	styleUrls: ['./columns.component.scss']
})
export class ColumnsComponent implements OnInit {

	@Input() props: Columns;

	constructor() {}

	ngOnInit(): void {

	}
}
