import {
	ApplicationRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	PLATFORM_ID
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ISubscription } from 'rxjs/Subscription';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AddTagModalComponent } from '../../add-tag-modal/add-tag-modal/add-tag-modal.component';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';
import { SocialMediaModalComponent } from '../../social-media-modal/social-media-modal/social-media-modal.component';

@Component({
	selector: 'app-lesson-flip-card',
	templateUrl: './lesson-flip-card.component.html',
	styleUrls: ['./lesson-flip-card.component.scss']
})
export class LessonFlipCardComponent implements OnInit {
	@Input() lesson: any;
	@Input() left: boolean;
	@Input() right: boolean;
	@Input() head: boolean;
	@Input() foot: boolean;
	@Input() fromCollection: boolean = false;
	@Output() flipping = new EventEmitter();
	@Output() closeCard: EventEmitter<any> = new EventEmitter();
	@Output() updateLesson = new EventEmitter();
	public flippedCard: boolean = false;
	public flipTime: number;
	public apiUrl: string = environment.api_url;
	public liked: boolean = false;
	public likes: number = 0;
	public shares: number = 0;
	public watches: number = 0;
	public bookmarks: number = 0;
	public get educatorModeOn(): boolean {
		return this.event.educatorMode;
	}
	private minimetricId: string;
	private loading: boolean = false;
	private subscription: ISubscription;
	public isMobile: boolean = false;
	public domain: string;

	constructor(
		private api: ApiService,
		private event: EventService,
		private deviceService: DeviceDetectorService,
		private dialog: MatDialog,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
		private router: Router,
		private localStorage: LocalstorageService,
		private alertService: AlertService,
		private miniMetricService: MiniMetricsService
	) {}

	ngOnInit(): void {
		this.domain = window.location.origin;
		this.loading = false;
		this.likes += this.lesson.likes || 0;
		this.watches += this.lesson.watches || 0;
		this.shares += this.lesson.shares || 0;
		this.bookmarks += this.lesson.bookmarks || 0;

		this.miniMetricService
			.getMiniMetrics(this.lesson.cardType, this.lesson.id)
			.subscribe((res) => {
				this.likes += res.likes;
				this.bookmarks += res.bookmarks;
				this.shares += res.shares;
				this.watches += res.watches;
			});

		if (this.api.authenticated) {
			this.api
				.get(
					`minimetrics?${this.miniMetricService.getQuery(
						this.lesson.cardType
					)}=${this.lesson.id}&user.id=${this.api.user.id}&type=like`
				)
				.toPromise()
				.then(
					(res) => {
						res = res || [];
						this.liked = res.length > 0;
						if (this.liked) this.minimetricId = res[0].id;
					},
					(err) => {
						console.log(err);
					}
				);
		}

		this.isMobile = this.deviceService.isMobile();
		if (this.lesson.cardType == 'visualization') {
			this.documents;
		}
		window.setTimeout(() => {
			document.getElementById('cardTitle').focus();
		}, 0);

		// console.log(this.lesson);
	}

	public isAuthenticated() {
		return this.api.authenticated;
	}

	public canLaunch() {
		return (
			this.api.authenticated ||
			this.lesson?.objectType === 'lesson' ||
			this.lesson?.objectType === 'collection'
		);
	}

	public toggleLike() {
		this.miniMetricService
			.toggleLike(this.lesson.cardType, this.lesson.id)
			.subscribe((res) => {
				this.liked = res > 0;
				this.likes += res;
			});
	}

	public reduceLanguages(lesson) {
		return lesson.languages.map((l) => l.name.substr(0, 2)).join(', ');
	}

	public onCloseCard() {
		this.closeCard.emit();
	}

	public toggleFlip() {
		this.flipping.emit(true);
		this.flippedCard = !this.flippedCard;
		this.flipTime = Date.now();
		setTimeout(() => this.flipping.emit(false), 1000);
	}

	// Unsubscribe event before leave component
	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}

	public async addTag() {
		if (!this.api.authenticated) return;
		const dialog = await this.dialog.open(AddTagModalComponent, {
			width: '400px',
			panelClass: 'addTagModal',
			data: {
				lessonId: this.lesson.id
			}
		});
		dialog.afterClosed().subscribe((lesson) => {
			if (lesson) {
				this.updateLesson.emit(lesson);
			}
		});
	}

	public async addBookMark() {
		if (!this.api.authenticated) return;
		this.alertService.error('You will be able to add bookmarks soon!');
	}

	public get topics() {
		return this.lesson.topicTags.filter((t) => t.approved);
	}

	public get documents() {
		if (
			this.lesson.cardType == 'lesson' ||
			this.lesson.cardType == 'contributed_content'
		) {
			return [
				...this.lesson.AlignmentDocuments,
				...this.lesson.LessonResources
			];
		} else if (this.lesson.cardType == 'visualization') {
			return this.lesson.Documents;
		}
	}

	public goToPage(url: string) {
		if (!url) return;
		if (isPlatformBrowser(this.platformId)) {
			this.miniMetricService.increaseWatch(
				this.lesson.cardType,
				this.lesson.id
			);
			this.window.nativeWindow.open(url, '_blank');
		}
	}

	public search(query: string) {
		this.event.globalEvent({
			action: 'search',
			dataob: {
				input: query
			}
		});
	}

	public shareLesson() {
		if (navigator && navigator.share) {
			navigator.share({
				url: this.lesson.publicUrl
			});
		} else {
			const dialogRef = this.dialog.open(SocialMediaModalComponent, {
				width: '600px',
				panelClass: 'addTagModal',
				data: {
					share: this.lesson.publicUrl
				}
			});
		}
	}
}
