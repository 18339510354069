<div class="cardContainer">
	<button
		class="cardThumbnailContainer px-2 pb-2 pt-1"
		(mouseOver)="openCard = true"
	>
		<div class="title mb-3 text-white font-weight-bold">
			{{
				collection.Title.length > 22
					? (collection.Title | slice: 0:22) + '&hellip;'
					: collection.Title
			}}
		</div>
		<div
			class="content imageContainer"
			[ngClass]="{ smaller: smaller }"
			[ngStyle]="{
				'background-image': 'url(\'' + collection.cardImage?.url + '\')'
			}"
		></div>
	</button>
</div>
