import { Component, Input, OnInit } from '@angular/core';

import { EventService } from 'src/app/services/event/event.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';

@Component({
	selector: 'app-educator-mode',
	templateUrl: './educator-mode.component.html',
	styleUrls: ['./educator-mode.component.scss']
})
export class EducatorModeComponent implements OnInit {
	public educatorModeOn: boolean = this.event.educatorMode;
	public focused: boolean = false;

	@Input() props:any;

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	constructor(
		private event: EventService,
		private router: Router,
		public api: ApiService
	) {}

	ngOnInit(): void {
		if (!this.educatorModeOn) this.toggleMode();
	}

	// On change mode
	toggleMode() {
		this.educatorModeOn = !this.educatorModeOn;
		this.event.SetEducatorMode(this.educatorModeOn);

		const ob = {
			action: 'set_flag_mode',
			dataobj: { educatorModeOn: this.educatorModeOn, page: this.router.url }
		};
		this.event.globalEvent(ob);
	}
}
