import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Image } from '../../types/image'

interface Item {
	id: number;
	name: string;
	role: string;
	bio: string;
	photo: Image;
}

interface Gallery {
	__component: string;
	id: number;
	title: string;
	subtitle: string;
	description: string;
	items: Item[];
}

@Component({
	selector: 'app-gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit {

	@Input() props: any;
	public data: Gallery

	constructor(
		private _renderer2: Renderer2,
		@Inject(DOCUMENT) private _document: Document 
	) {}

	ngOnInit(): void {
		this.data = <Gallery>this.props
	}

	ngAfterViewInit(): void {
		var isFound = false;
		var scripts = document.getElementsByTagName("script")
		for (var i = 0; i < scripts.length; ++i) {
			if (scripts[i].getAttribute('id') != null && scripts[i].getAttribute('id').includes("gallery-custom")) {
				isFound = true;
			}
		}
		if(!isFound) {
			let script = this._renderer2.createElement('script');
			script.type = `text/javascript`;
			script.id = 'gallery-custom';
			script.text = `
				// Gallery
				const btns = document.querySelector('.slider-thumb');
				const pgThumb = document.querySelector('.page-thumb');
				const items = document.querySelector('.item-list');
				const gallery = document.querySelector('.gallery');
				const mnTitle = document.querySelector('.main-title');
				const itemWidth = items.clientWidth;
				let itemIndex = 0;


				// Thumb Slide
				var thumbList = document.querySelector('.js-thumb');
				var thumbListWidth = 0;
				var thumbListSteps = 0;
				var thumbs = document.querySelectorAll('.thumb-img');
				var thumbsAmount = 0;
				var thumbsAmountVisible = 10;


				// Close Gallery
				function closeGallery() {
					gallery.classList.remove('on');
					pgThumb.classList.remove('off');
					mnTitle.classList.remove('off');
					document.getElementById('app-footer-section').style.display = "block";
					document.getElementById('app-header').style.display = "flex";
					document.getElementById('mobile-eductor-bar').setAttribute('style', 'display:flex !important');
				}


				//Count and click Thumbnail
				for (let i = 0; i < btns.children.length; i++) {
					const btn = btns.children[i];
					btn.addEventListener('click', function () {
						unCheckAllBtns();
						btn.classList.add('active');
						changeImage(i);
					});
				}

				//Card
				for (let i = 0; i < pgThumb.children.length; i++) {
					const pgbtn = pgThumb.children[i];
					pgbtn.addEventListener('click', function () {
						document.getElementById('app-footer-section').style.display = "none";
						document.getElementById('app-header').style.display = "none";
						document.getElementById('mobile-eductor-bar').setAttribute('style', 'display:none !important');
						unCheckAllBtns();
						pgbtn.classList.add('active');
						changeImage(i);
						btns.children[i].classList.add('active');
						gallery.classList.add('on');
						pgThumb.classList.add('off');
						mnTitle.classList.add('off');
						document.body.scrollTop = 0; // For Safari
						document.documentElement.scrollTop = 0;
					});
				}


				//Uncheck 'active' class
				function unCheckAllBtns() {
					for (const btn of btns.children) {
						btn.classList.remove('active');
					}
				}

				//Change on click(Thumbnail)
				function changeImage(index) {
					let pos = 100 * itemIndex;
					var interval = setInterval(slide, 0);

					function slide() {
						if (pos === 100 * index) {
							itemIndex = index;
							clearInterval(interval);
						} else {
							if (index > itemIndex) {
								pos += 100;
							} else if (index < itemIndex) {
								pos -= 100;
							}
							items.style.transform = "translateX(-" + pos + "%)";
						}
					}
				}


				// Next Slide
				function nextSlide() {

					if (itemIndex !== btns.children.length - 1) {

						unCheckAllBtns();
						nextThumb();
						let index = itemIndex + 1;

						btns.children[index].classList.add('active');
						changeImage(index);

					}
				}

				// Prev Slide
				function prevSlide() {
					if (itemIndex > 0) {
						unCheckAllBtns();
						prevThumb();
						let index = itemIndex - 1;
						btns.children[index].classList.add('active');
						changeImage(index);
					}

				}


				//Count all the thumb
				[].forEach.call(thumbs, function (thumb) {
					thumbsAmount++;
					thumbListWidth += 120;
					thumbList.style.width = thumbListWidth + "px";
				});

				//Next Thumb
				function nextThumb() {
					if (thumbListSteps < thumbsAmount - thumbsAmountVisible) {
						thumbListSteps++;
						moveThumbList();
					}
				}

				//Prev Thumb
				function prevThumb() {
					if (thumbListSteps > 0) {
						thumbListSteps--;
						moveThumbList();
					}
				}

				// Move Thumb
				function moveThumbList() {
					thumbList.style.transform = "translateX(-" + 112 * thumbListSteps + "px)";
				}
			`;

			this._renderer2.appendChild(this._document.body, script);
		}
	}
}
