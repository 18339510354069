<section class="banner-inner-page">
	<div class="banner-inner-div" *ngIf="lesson" [ngStyle]="{ 'background-image': 'url(' + lesson.cardImage?.url + ')' }"></div>

	<div class="banner-inner-content">
		<div class="container">
			<div class="banner-details">
				<div class="playbutton" *ngIf="lesson && lesson.YouTubeId">
					<button class="clickable" (click)="showVideo = true">
						<img src="assets/images/icon/play-btn.png" alt="play button" />
					</button>
				</div>
				<app-lesson-title class="w-100" [title]="lesson.title || lesson.Title"
					[createdAt]="lesson.releaseDate || lesson.createdAt">
				</app-lesson-title>
				<div class="row row-custom">
					<div class="col-6">
						<app-community-contribution *ngIf="lesson.author" [authorImg]="lesson.authorImage?.url" [author]="lesson.author" [createdBy]="lesson.createdBy"></app-community-contribution>
					</div>
					<div class="col-6 counts-col">
						<app-lesson-counts [bookmarks]="bookmarks" [likes]="likes" (toggleLike)="toggleLike()"></app-lesson-counts>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="youtubeContainer" *ngIf="showVideo">
	<div class="closeContainer" (click)="showVideo = false">
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-dialog modal-dialog-centered" *ngIf="lesson && lesson.YouTubeId">
		<div class="modal-content">
			<div class="modal-body">
				<iframe width="100%" height="100%" [src]="
						sanitizeHtml('https://www.youtube.com/embed/' + lesson.YouTubeId)
					" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
					title="Lesson video"></iframe>
			</div>
		</div>
	</div>
</div>
