import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-lesson-counts',
	templateUrl: './lesson-counts.component.html',
	styleUrls: ['./lesson-counts.component.scss']
})
export class LessonCountsComponent implements OnInit {
	@Input() likes: number = 0;
	@Input() bookmarks: number = 0;
	@Output() toggleLike: EventEmitter<void> = new EventEmitter();

	ngOnInit(): void {}
}
