import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiService } from './../../../services/API/api.service';
import { environment } from 'src/environments/environment';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact/contact.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from 'src/app/services/event/event.service';
import { InfoModalComponent } from 'src/app/shared/modules/info-modal/info-modal/info-modal.component';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
	contactForm: FormGroup;

	roles = [
		{ value: 'student', name: 'Student' },
		{ value: 'educator', name: 'Educator' },
		{ value: 'other', name: 'Other' }
	];

	ages = [
		{ value: 'Under 13', name: 'Are you 13 or under?' },
		{ value: '14-17', name: '14-17' },
		{ value: 'Over 18', name: 'Over 18' }
	];

	public btnDisableStatus: boolean = true;

	private captchaToken: string;
	public recaptchaSiteKey = environment.recaptcha_site_key;

	constructor(
		private formBuilder: FormBuilder,
		private contact: ContactService,
		private router: Router,
		private event: EventService,
		private api: ApiService,
		private authenticationService: AuthenticationService,
		private dialog: MatDialog
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
		this.contactForm = formBuilder.group({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			email: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.email])
			),
			phone: new FormControl('', Validators.required),
			role: new FormControl(null, Validators.required),
			organization: new FormControl('', Validators.required),
			age: new FormControl(null, Validators.required),
			guardian: new FormControl(''),
			message: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.minLength(4)])
			),
			token: new FormControl(null, Validators.required),
			hasToken: new FormControl(true),
			subject: new FormControl('Contact'),
			to: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.email])
			),
		});
	}

	ngOnInit(): void {
		this.api.get('emails').toPromise().then((res) => {
			this.contactForm.patchValue({ to: res.contactEmail });
		}).catch((err) => {
			this.ngOnInit();
		});

		this.authenticationService.refreshToken();
	}

	public captchaCallback(token: string) {
		this.btnDisableStatus = !this.btnDisableStatus;
		this.captchaToken = token;
	}

	public submitContact() {
		console.log('as')
		this.contactForm.patchValue({ token: this.captchaToken });
		if (this.contactForm.valid) {
			this.contact
				.sendEmail(this.contactForm.value)
				.then((res) => {
					this.contactForm.reset();
					const dialog = this.dialog.open(InfoModalComponent, {
						width: "600px",
						panelClass: 'addTagModal',
						data: {
							title: 'Thank you for contacting us!',
							msg: `Your message was sent.`,
						}
					});
				})
				.catch((err) => {
					console.log(err);
					const dialog = this.dialog.open(InfoModalComponent, {
						width: "600px",
						panelClass: 'addTagModal',
						data: {
							title: 'Sorry! We\'ve run into an issue',
							msg: `There was an error submitting your response. Please, try again later.`,
						}
					});
				});
		} else {
			const dialog = this.dialog.open(InfoModalComponent, {
				width: "600px",
				panelClass: 'addTagModal',
				data: {
					title: 'There is an error!',
					msg: `Please make sure to fill all required fields!`,
				}
			});
		}
	}
}
