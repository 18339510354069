export const environment = {
	production: true,
	api_url: 'https://staging.api.infiniscope.org/',
	aws_region: 'us-east-1',
	cognitoUserPoolId: 'us-east-1_6Z69kIzKP',
	cognitoAppClientId: 'f80olnq3dopaj80d9fsunim1c',
	recaptcha_site_key: '6Lf62KgdAAAAAGIZblcWe299Rd_CYG_sFkTYBDgy',
	torus_url: 'https://torus.qa.argos.education/cognito/launch',
	infiniscope_url: 'https://staging.infiniscope.org',
	cookie_url: 'staging.infiniscope.org',
	community_id: 44
};
