import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnicalDetailsComponent } from './technical-details/technical-details.component';

@NgModule({
	declarations: [TechnicalDetailsComponent],
	imports: [CommonModule],
	exports: [TechnicalDetailsComponent]
})
export class TechnicalDetailsModule {}
