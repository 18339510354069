import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonFlipCardComponent } from './lesson-flip-card/lesson-flip-card.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { AddTagModalModule } from '../add-tag-modal/add-tag-modal.module';
import { CommunityContributionComponent } from './lesson-flip-card/community-contribution/community-contribution.component';
import { MarkdownModule } from 'src/app/pipes/markdown/markdown.module';
import { SafeHtmlModule } from 'src/app/pipes/safehtml/safehtml.module';

@NgModule({
	declarations: [LessonFlipCardComponent, CommunityContributionComponent],
	imports: [
		CommonModule,
		RouterModule,
		MatDialogModule,
		AddTagModalModule,
		MarkdownModule,
		SafeHtmlModule
	],
	exports: [LessonFlipCardComponent, CommunityContributionComponent]
})
export class LessonFlipModuleModule {}
