import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ApiService } from './../../../services/API/api.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact/contact.service';
import { EventService } from 'src/app/services/event/event.service';
import { InfoModalComponent } from 'src/app/shared/modules/info-modal/info-modal/info-modal.component';

@Component({
	selector: 'app-submission-form',
	templateUrl: './submission-form.component.html',
	styleUrls: ['./submission-form.component.scss']
})
export class SubmissionFormComponent implements OnInit {
	submissionForm: FormGroup;

	constructor(
		private event: EventService,
		private router: Router,
		private formBuilder: FormBuilder,
		private contact: ContactService,
		private dialog: MatDialog,
		private api: ApiService,
		private authenticationService: AuthenticationService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
		this.submissionForm = formBuilder.group({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			email: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.email])
			),
			to: new FormControl(
				'',
				Validators.compose([Validators.required, Validators.email])
			),
			hasToken: new FormControl(false)
		});
	}

	ngOnInit(): void {
		this.api.get('emails').toPromise().then((res) => {
			this.submissionForm.patchValue({ to: res.slackEmail });
		}).catch((err) => {
			this.ngOnInit();
		});

		this.authenticationService.refreshToken();
	}

	submit() {
		if (this.submissionForm.valid) {
			this.contact
				.sendEmail(this.submissionForm.value)
				.then((res) => {
					const dialog = this.dialog.open(InfoModalComponent, {
						width: "600px",
						panelClass: 'addTagModal',
						data: {
							title: 'Your request has been submitted',
							msg: `It is great to hear that you want to join our Slack. The admin will be notified. Please wait our response.`,
						}
					});

					dialog.afterClosed().subscribe((e) => {
						this.submissionForm.reset();
					})
				})
				.catch((err) => {
					console.log(err);
					const dialog = this.dialog.open(InfoModalComponent, {
						width: "600px",
						panelClass: 'addTagModal',
						data: {
							title: 'Sorry!!',
							msg: `We had a connection issue. Try again later!`,
						}
					});
				});
		}
	}
}
