import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionContainerComponent } from './collection-container/collection-container.component';
import { CollectionCardModule } from '../collection-card/collection-card.module';

@NgModule({
	declarations: [CollectionContainerComponent],
	imports: [CommonModule, CollectionCardModule],
	exports: [CollectionContainerComponent]
})
export class CollectionContainerModule {}
