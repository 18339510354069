import { Component, Input, OnInit } from '@angular/core';
import { Image } from '../../types/image'

interface Banner {
	__component: string;
	id: number;
	title: string;
	subtitle: string;
	body: string;
	darkMode: boolean;
	image: Image;
	size: string;
}

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

	@Input() props: any;
	public data: Banner

	constructor() {}

	ngOnInit(): void {
		this.data = <Banner>this.props
	}
}
