<section
	class="banner-section"
	*ngIf="data"
	[ngClass]="{ dark: data.darkMode }"
>
	<!-- start wrapper -->
	<div class="container">
		<!-- start column -->
		<div class="row">
			<!-- column 100% -->
			<div class="col-12">
				<!-- banner -->
				<div
					role="img"
					aria-label="banner image"
					[ngClass]="{
						'banner-small': data.size == 'small',
						'large-banner': data.size == 'large'
					}"
					style="background: url('{{ data.image.url }}');"
				></div>

				<div class="spacer"></div>
				<div class="spacer"></div>
				<div class="spacer"></div>
				<h1 role="heading" aria-level="1">{{ data.title }}</h1>

				<h2 role="heading" aria-level="2">{{ data.subtitle }}</h2>

				<div class="spacer"></div>
				<div [innerHTML]="data.body | safeHtml | markdown"></div>
				<div class="spacer"></div>
			</div>
		</div>
		<!-- end column -->
	</div>
	<!-- end wrapper -->
</section>
