import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from 'src/app/services/API/api.service';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact/contact.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { InfoModalComponent } from 'src/app/shared/modules/info-modal/info-modal/info-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';

@Component({
	selector: 'app-lesson-bottom',
	templateUrl: './lesson-bottom.component.html',
	styleUrls: ['./lesson-bottom.component.scss']
})
export class LessonBottomComponent implements OnInit {
	public techCards = [
		{
			buttonTitle: 'Launch to get started',
			location: 'public',
			authenticationRequired: false,
			card: [
				{
					content: 'open this lesson?'
				},
				{
					content: 'preview this lesson?'
				},
				{
					content: 'use this lesson with your learners?'
				}
			]
		},
		{
			buttonTitle: 'Enroll to get started',
			authenticationRequired: true,
			location: 'auth',
			card: [
				{
					content: 'enroll your learners in the lesson?'
				},
				{
					content: 'collect data on your learners?'
				},
				{
					content: 'monitor your learners’ progress?'
				},
				{
					content: 'integrate the lesson into your pre-existing online course?'
				}
			]
		},
		{
			buttonTitle: 'Customize to get started',
			authenticationRequired: true,
			location: 'request',
			card: [
				{
					content: 'customize the lesson for your learners?'
				},
				{
					content: 'enroll your learners in the customized version?'
				},
				{
					content:
						'share your customized version with the infiniscope Teaching Network?'
				}
			]
		}
	];

	public domain: string;
	public toggleModelDuplicate: boolean;
	public toggleModelTorus: boolean;
	get authenticated(): boolean {
		return this.api.authenticated;
	}
	public communityId: number = environment.community_id;

	@Input() requireAuthentication: boolean;
	@Input() lesson: any;
	@Input() contentType: string;

	constructor(
		private api: ApiService,
		public localStorage: LocalstorageService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
		public router: Router,
		private dialog: MatDialog,
		private contactServcice: ContactService,
		private auth: AuthenticationService,
		private miniMetricService: MiniMetricsService
	) {}

	ngOnInit(): void {
		this.domain = window.location.origin;
	}

	public goToPage(url: string) {
		if (!url) return;
		if (isPlatformBrowser(this.platformId)) {
			this.window.nativeWindow.open(url, '_blank');
			this.toggleModelTorus = false;
			this.toggleModelDuplicate = false;
		}
	}

	public handleClick(location: string) {
		if (location == 'public') {
			this.miniMetricService.increaseWatch(this.contentType, this.lesson.id);
			this.goToPage(this.lesson.publicUrl);
		} else if (location === 'request') {
			this.toggleModelTorus = false;
			if (this.lesson.customizeUrl) {
				if (!this.lesson.customizeUrl.includes('argos.education')) {
					this.goToPage(this.lesson.customizeUrl);
				} else {
					this.goToPage(
						`${this.lesson.customizeUrl}?id_token=${this.localStorage.getItem(
							'cognito_id_token'
						)}&community_id=${this.communityId}&error_url=${
							environment.infiniscope_url + this.router.url
						}`
					);
				}
			} else {
				this.toggleModelDuplicate = true;
			}
			document.getElementById('exampleModalLongTitle').focus();
		} else if (location === 'auth') {
			if (isPlatformBrowser(this.platformId)) {
				this.toggleModelTorus = true;
				this.toggleModelDuplicate = false;
			}
			document.getElementById('exampleModalLongTitle').focus();
		}
	}

	public requestDuplication() {
		const user = this.auth.currentUserValue;
		if (user) {
			this.miniMetricService.increaseShare(this.contentType, this.lesson.id);
			this.contactServcice
				.sendContentCopyRequest(
					this.lesson?.id + ' - ' + this.lesson?.title,
					this.lesson?.objectType,
					user.id
				)
				.then((res) => {
					this.toggleModelTorus = false;
					this.toggleModelDuplicate = false;
					this.dialog.open(InfoModalComponent, {
						width: '600px',
						panelClass: 'addTagModal',
						data: {
							title: 'Success',
							msg:
								'Your request was sent by email successfully, please wait for a response by email on the email address you use with Infiniscope.'
						}
					});
				})
				.catch((err) => {
					this.toggleModelTorus = false;
					this.toggleModelDuplicate = false;
					console.log(err);
					this.dialog.open(InfoModalComponent, {
						width: '600px',
						panelClass: 'addTagModal',
						data: {
							title: 'Sorry! An error occured.',
							msg: err.error.message
						}
					});
				});
		}
	}
}
