<button class="cardContainer w-100 p-0 m-0 border-0 text-left" [ngClass]="{'collection': collection}">
	<div class="cardThumbnailContainer px-2 pb-2 pt-1" (mouseOver)="openCard = true">
		<div class="title mb-3 text-white font-weight-bold" [ngClass]="{'text-white': !collection, 'text-black': collection}">
			{{
			lesson?.title?.length > 22
			? (lesson?.title | slice: 0:22) + '&hellip;'
			: lesson?.title
			}}
		</div>
		<div class="content imageContainer" [ngClass]="{ smaller: smaller }" [ngStyle]="{
				'background-image': 'url(\'' + lesson?.thumbnail?.url + '\')'
			}">
		</div>
	</div>
</button>
