<div id="mobile-eductor-bar" class="educator-mobile">
	<div class="educator">
		<p>{{ footerData.educatorModeLabel }}</p>
		<div class="switch-slider">
			<input
				type="checkbox"
				class="toggle"
				id="toggle-mobile"
				[checked]="educatorModeOn"
				(change)="toggleMode()"
			/>
			<label for="toggle-mobile">
				<span class="on">On</span>
				<span class="off">Off</span>
			</label>
		</div>
	</div>
</div>
<footer
	id="app-footer-section"
	class="footer-section mt-auto"
	[ngClass]="
		currentPage == '/search' || currentPage == '/join'
			? 'remove-footer-margin'
			: ''
	"
>
	<div class="footer-inner">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<div class="footer-content">
						<div class="row">
							<div class="offset-lg-1 col-lg-6 col-md-6 col-6">
								<ul role="list">
									<li *ngFor="let link of footerData.Links" role="listitem"><a [target]="link.openInNewTab ? '_blank' : '_self'" [href]="link.linkUrl">{{ link.linkText }}</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container-fluid">
				<div class="copy-right-text">
					<div class="row">
						<div class="col-lg-12">
							<ul>
								<li *ngFor="let logo of footerData.logos">
									<a [href]="logo.caption" target="_blank">
										<img [src]="logo.url" [alt]="logo.alternativeText" />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
