import {
	Component,
	OnInit,
	ApplicationRef,
	Inject,
	PLATFORM_ID
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import {
	AddTagModalComponent,
	ITopic
} from 'src/app/shared/modules/add-tag-modal/add-tag-modal/add-tag-modal.component';
import { environment } from 'src/environments/environment';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { isPlatformBrowser } from '@angular/common';
import marked from 'marked';
import { ContactService } from 'src/app/services/contact/contact.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { InfoModalComponent } from 'src/app/shared/modules/info-modal/info-modal/info-modal.component';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';

@Component({
	selector: 'app-visualization',
	templateUrl: './visualization.component.html',
	styleUrls: ['./visualization.component.scss']
})
export class VisualizationComponent implements OnInit {
	torus_url = environment.torus_url;
	public visualization: any;
	public apiUrl = environment.api_url;
	loadFlag: boolean = false;
	id: any;
	sub: any;
	public likes: number = 0;
	public shares: number = 0;
	public watches: number = 0;
	public bookmarks: number = 0;
	public toggleModelDuplicate: boolean = false;
	public toggleModelEmbed: boolean = false;
	public techCards = [
		{
			buttonTitle: 'Preview',
			authenticationRequired: false,
			location: 'public',
			card: [
				{
					content: 'Open this visualization?'
				},
				{
					content: 'Preview this visualization?'
				},
				{
					content: 'Use this visualization with your learners?'
				}
			]
		},
		{
			buttonTitle: 'Embed',
			authenticationRequired: false,
			location: 'embed',
			card: [
				{
					content: 'Add this visualization to your existing digital lesson?'
				},
				{
					content: 'Add this visualization to your student facing website?'
				},
				{
					content:
						'Add this visualization to your existing Argos or Tour It lesson?'
				}
			]
		},
		{
			buttonTitle: 'Create',
			authenticationRequired: false,
			location: 'request',
			card: [
				{
					content: 'Create a new digital lesson with this visualization?'
				},
				{
					content: 'Make a copy of the visualization?'
				},
				{
					content: 'Customize the visualization?'
				}
			]
		}
	];

	public domain: string;

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	constructor(
		private event: EventService,
		private router: Router,
		public api: ApiService,
		private route: ActivatedRoute,
		private appRef: ApplicationRef,
		private dialog: MatDialog,
		private localStorage: LocalstorageService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
		private contactServcice: ContactService,
		private auth: AuthenticationService,
		private miniMetricService: MiniMetricsService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	public get educatorMode(): boolean {
		return this.event.educatorMode;
	}

	ngOnInit(): void {
		this.loadFlag = true;
		// this.domain = window.location.origin;
		this.sub = this.route.params.subscribe((params) => {
			this.id = params.id;
		});
		this.getVisualizationData(this.id);
		// document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
		this.api.get('minimetrics?lesson.id=' + this.id, true).subscribe((res) => {
			if (!res) return;
			this.likes += res.filter((r) => r.type === 'like').length;
			this.shares += res.filter((r) => r.type === 'share').length;
			this.watches += res.filter((r) => r.type === 'watch').length;
			this.bookmarks += res.filter((r) => r.type === 'bookmark').length;
		});

		this.auth.refreshToken();
	}

	getVisualizationData(id) {
		this.likes = 0;
		this.bookmarks = 0;
		this.shares = 0;
		this.watches = 0;

		this.api
			.get('visualizations/' + id, null)
			.toPromise()
			.then((res) => {
				console.log(res);
				this.likes += res.likes || 0;
				this.bookmarks += res.bookmarks || 0;
				this.watches += res.watches || 0;
				this.shares += res.shares || 0;
				this.visualization = {
					...res,
					description: marked(res.description || ''),
					centralQuestion: marked(res.centralQuestion || ''),
					instructionalObjective: marked(res.instructionalObjective || ''),
					learningOutcomes: marked(res.learningOutcomes || ''),
					prerequisites: marked(res.prerequisites || '')
				};
			});

		this.miniMetricService
			.getMiniMetrics('visualization', id)
			.subscribe((res) => {
				this.likes += res.likes;
				this.bookmarks += res.bookmarks;
				this.shares += res.shares;
				this.watches += res.watches;
			});
	}

	ngOnDestroy() {
		// this.sub.unsubscribe();
	}

	public goToPage(url: string) {
		if (!url) return;
		if (isPlatformBrowser(this.platformId)) {
			if (url === this.torus_url) {
				url =
					url +
					`?community_id=${
						environment.community_id
					}&id_token=${this.localStorage.getItem(
						'cognito_id_token'
					)}&error_url=${environment.infiniscope_url + this.router.url}`;
			}
			this.window.nativeWindow.open(url, '_blank');
			this.toggleModelDuplicate = false;
		}
	}

	public handleClick(location: string) {
		console.log(location);
		if (location == 'public') {
			this.miniMetricService.increaseWatch('visualization', this.id);
			this.toggleModelDuplicate = false;
			this.goToPage(this.visualization.publicUrl);
		} else if (location === 'request') {
			this.toggleModelDuplicate = true;
			document.getElementById('exampleModalLongTitle').focus();
		} else if (location === 'embed') {
			if (isPlatformBrowser(this.platformId)) {
				this.toggleModelDuplicate = false;
				this.toggleModelEmbed = true;
			}
			document.getElementById('exampleModalLongTitle').focus();
		}
	}

	public requestDuplication() {
		const user = this.auth.currentUserValue;
		if (user) {
			this.contactServcice
				.sendContentCopyRequest(
					this.visualization?.id,
					this.visualization?.objectType,
					user.id
				)
				.then((res) => {
					this.toggleModelDuplicate = false;
					this.dialog.open(InfoModalComponent, {
						width: '600px',
						panelClass: 'addTagModal',
						data: {
							title: 'Success',
							msg:
								'Your request was sent by email successfully, please wait for a response by email on the email address you use with Infiniscope.'
						}
					});
				})
				.catch((err) => {
					this.toggleModelDuplicate = false;
					console.log(err);
					this.dialog.open(InfoModalComponent, {
						width: '600px',
						panelClass: 'addTagModal',
						data: {
							title: 'Sorry! An error occured.',
							msg: err.error.message
						}
					});
				});
		}
	}

	public async searchForTopic(topic: ITopic) {
		if (this.event.educatorMode) {
			this.editTag(topic);
		} else {
			if (this.router.url.indexOf('/search') !== 0) {
				await this.router.navigate(['search']);
				await this.appRef.tick();
			}
			this.event.globalEvent({
				action: 'search',
				dataob: {
					input: topic.title
				}
			});
		}
	}

	public async editTag(topic: ITopic) {
		const dialog = await this.dialog.open(AddTagModalComponent, {
			width: '400px',
			panelClass: 'addTagModal',
			data: {
				lessonId: this.visualization.id,
				topic
			}
		});
		dialog.afterClosed().subscribe((updatedTopic) => {
			if (updatedTopic) {
				this.visualization.topicTags = this.visualization.topicTags.map((t) => {
					if (t.id === updatedTopic.id) {
						return updatedTopic;
					} else {
						return t;
					}
				});
			}
		});
	}

	public get topics() {
		if (!this.visualization?.topicTags) return [];
		return this.visualization.topicTags.filter((t) => t.approved);
	}

	public mdToHtml(md: string): string {
		if (!md) return '';
		return md.split('\n').join('<br>');
	}

	public splitByLine(content: string): string[] {
		if (!content) return [];
		return content.split('\n');
	}
}
