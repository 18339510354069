<div class="modal-content">
	<div class="modal-header">
		<h2 class="modal-title" id="exampleModalLongTitle">
			{{ data.title }} Submission
		</h2>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true" (click)="dismiss()">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="main">
			<div class="info">
				<h5>Before submitting your content to the community</h5>
				<p>
					Please ensure that it meets the criteria listed below.
				</p>
				<div class="content-item">
					<div class="item item-title">
						<div class="container">
							<div class="row">
								<div class="col-6">
									<label class="label-title">Content Check</label>
								</div>
								<div class="col-6 input-col">
									<label class="yes-label">Yes</label>
									<label class="no-label">No</label>
								</div>
							</div>
						</div>
					</div>
					<ng-container *ngFor="let item of items; let i = index">
						<div class="item">
							<hr />
							<div class="container">
								<div class="row">
									<div class="col-10">
										<label class="item-desc" [for]="item.description">{{
											item.description
										}}</label>
									</div>
									<div class="col-2 input-col">
										<div class="form-check form-check-inline">
											<input
												class="form-check-input yes-input"
												type="radio"
												name="{{'inlineRadioOptions'+i}}"
												id="{{'inlineRadioYes'+i}}"
												[value]="item.yes"
												[(ngModel)]="item.yes"
												(change)="valueUpdate('yes', i)"
											/>
										</div>
										<div class="form-check form-check-inline">
											<input
												class="form-check-input no-input"
												type="radio"
												name="{{'inlineRadioOptions'+i}}"
												id="{{'inlineRadioNo'+i}}"
												[value]="item.no"
												[(ngModel)]="item.no"
												(change)="valueUpdate('no', i)"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
				<div>
					<p class="anchor">
						<a href="#"
							>All materials used is available under public domain, creative
							commons 1.0 (CC1.0), or falls under copyright "Fair Use."
							Infiniscope is not responsable for copyright infringement that may
							occur in Contributed Content or in an educator's workspace.
							Members submitting content to the Infiniscope Teaching Network
							bear all responsibility for verifying copyright laws have not been
							violated in content they are sharing. By clicking "yes", you agree
							to this policy.</a
						>
					</p>
				</div>
				<div>
					<button type="submit" value="Submit" (click)="submit()" [disabled]="!formCheck" [ngClass]="{'btn-disable': !formCheck}" class="btn">Continue</button>
				</div>
			</div>
		</div>
	</div>
</div>
