import { SubmitTourItComponent } from './pages/submit-tour-it/submit-tour-it/submit-tour-it.component';
import { EventComponent } from './pages/event/event/event.component';
import { VisualizationComponent } from './pages/visualization/component/visualization/visualization.component';
import { ProfilePageComponent } from './pages/profile-page/components/profile-page/profile-page.component';
import { ReportIssueComponent } from './pages/report-issue/components/report-issue.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/components/home/home.component';
import { LessonComponent } from './pages/lesson/components/lesson/lesson.component';
import { SearchComponent } from './pages/search/components/search/search.component';
import { SignUpComponent } from './pages/auth/components/sign-up/sign-up.component';
import { CollectionComponent } from './pages/collection/components/collection/collection.component';
import { ContactComponent } from './pages/contact/components/contact.component';
import { BookmarkComponent } from './pages/bookmark/components/bookmark/bookmark.component';
import { PageComponent } from './pages/page/components/page.component';
import { ImportComponent } from './pages/import/components/import.component';
import { ToolPageComponent } from './pages/tool-page/component/tool-page/tool-page.component';
import { SubmissionFormComponent } from './pages/submission-form/components/submission-form.component';
import { ContributedContentComponent } from './pages/contributed-content/components/contributed-content/contributed-content.component';
import { SubmitContentComponent } from './pages/submit-content/submit-content/submit-content.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		redirectTo: '',
		pathMatch: 'full'
	},
	{ path: 'home', component: HomeComponent },
	{ path: 'importexport', component: ImportComponent },
	{ path: 'search', component: SearchComponent },
	{ path: 'join', component: SignUpComponent },
	{ path: 'lesson/:id', component: LessonComponent },
	{ path: 'collection/:id', component: CollectionComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'report-issue', component: ReportIssueComponent },
	{ path: 'profile', component: ProfilePageComponent },
	{ path: 'bookmarks', component: BookmarkComponent },
	{ path: 'visualization/:id', component: VisualizationComponent },
	{ path: 'content/:id', component: ContributedContentComponent },
	{ path: 'tools', component: ToolPageComponent },
	{ path: 'join-slack', component: SubmissionFormComponent },
	{ path: 'page/:slug', component: PageComponent },
	{
		path: 'submit-content',
		component: SubmitContentComponent,
		children: [{ path: 'tour-it', component: SubmitTourItComponent }]
	},
	{ path: 'tour-it', component: SubmitTourItComponent },
	{ path: 'events', component: EventComponent },
	{ path: '**', redirectTo: 'home' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			relativeLinkResolution: 'legacy',
			initialNavigation: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
