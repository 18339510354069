<section>
	<div class="center">
		<div class="container">

			<div class="row border-bottom">
				<div class="col-12">
					<h2>{{ user.name }}</h2>
					<i class="fas fa-info-circle" matTooltip="Name edits should be by request only (contact@infiniscope.org)"
						matTooltipPosition="after"></i>
				</div>
				<div class="col-md-4 col-12 vertical-line">


					<!-- <img [src]="croppedImage" /> -->

					<div class="photo-profile">
						<img [src]="
							croppedImage
								? croppedImage
									: user?.profilePicture
										? user?.profilePicture?.url
										: placeholderImg
						" alt="profile picture" />

						<div class="icon-change" (click)="openCropper()"></div>
					</div>


					<div class="basic-info">
						<!-- <label class="educational">Name of Educational Institution</label><br>
							<label class="join-data"></label> -->
						<i>{{ user?.educationalName }}</i><br />
						Joined: {{ user.created_at | date: 'MM/YYYY' }}
					</div>
					<h4>Notification Preferences</h4>
					<label class="notification-label">Not receiving email notifications? <a [href]="'/contact'">Let us
							know!</a></label>
					<!-- <h5>Frequency of Notifications</h5>
					<select class="form-select" aria-label="Default select example" [compareWith]="compareFn"
						[(ngModel)]="user.notificationFrequency">
						<option [ngValue]="'daily'">Daily</option>
						<option [ngValue]="'weekly'">Weekly</option>
						<option [ngValue]="'monthly'">Monthly</option>
						<option [ngValue]="null" selected>None</option>
					</select> -->
					<!-- <h5>Notification Types</h5>
					<div class="form-check" style="left: 20px;">
						<input class="form-check-input" type="checkbox" id="flexCheckDefault" [(ngModel)]="user.newEvent" />
						<label class="form-check-label" for="flexCheckDefault">
							New events
						</label>
					</div>
					<div class="form-check" style="left: 20px;">
						<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
							[(ngModel)]="user.newContent" />
						<label class="form-check-label" for="flexCheckDefault">
							New content
						</label>
					</div>
					<div class="form-check" style="left: 20px;">
						<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
							[(ngModel)]="user.newAnnouncements" />
						<label class="form-check-label" for="flexCheckDefault">
							New announcement
						</label>
					</div>
					<div class="form-check" style="left: 20px;">
						<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
							[(ngModel)]="user.newTraining" />
						<label class="form-check-label" for="flexCheckDefault">
							New training
						</label>
					</div>-->
				</div>
				<div class="col-md-8 col-12">
					<div class="row btn-div">
						<button type="submit" value="Submit" (click)="submit()" class="btn" [disabled]="!user.id">
							Update Profile
						</button>
					</div>
					<h4>Profile Details</h4>
					<div>
						<label class="input-label">Email</label><label class="required-label">(Required)</label>
						<div class="input-group">
							<input type="text" class="form-control" placeholder="Username" aria-describedby="basic-addon1"
								[(ngModel)]="user.email" disabled="true" />
						</div>
						<div class="input-group mb-3" style="font-size: 80%;">
							<a href="https://confirmsubscription.com/h/d/05BB1AEFEA8416A3" target="_blank">Need to change your email? Click here.</a>
						</div>
					</div>
					<form>

					</form>
					<div>
						<label class="input-label">Old Password</label><label class="required-label">(Required)</label>
						<div class="input-group mb-3">
							<span class="input-placeholder">
								<input [type]="hidePW ? 'password' : 'text'" class="form-control" placeholder="Password"
									aria-describedby="basic-addon1" [(ngModel)]="oldPassword" [ngClass]="{ dots: oldPassword != '' }" />
								<mat-icon class="icon-placeholder" (click)="hidePassword()">{{
									hidePW ? 'visibility_off' : 'visibility'
									}}</mat-icon>
							</span>
						</div>
					</div>
					<div>
						<label class="input-label">New Password</label><label class="required-label">(Required)</label>
						<div class="input-group mb-3">
							<span class="input-placeholder">
								<input [type]="hidePW ? 'password' : 'text'" class="form-control" placeholder="Password"
									aria-describedby="basic-addon1" [(ngModel)]="password" [ngClass]="{ dots: password != '' }" />
								<mat-icon class="icon-placeholder" (click)="hidePassword()">{{
									hidePW ? 'visibility_off' : 'visibility'
									}}</mat-icon>
							</span>
						</div>
					</div>
					<div>
						<label class="input-label">Retype Password</label><label class="required-label">(Required)</label>
						<div class="input-group mb-3">
							<span class="input-placeholder">
								<input [type]="hidePW ? 'password' : 'text'" class="form-control" placeholder="Password"
									aria-describedby="basic-addon1" [(ngModel)]="pwRetype" [ngClass]="{ dots: pwRetype != '' }" />
								<mat-icon class="icon-placeholder" (click)="hidePassword()">{{ hidePW ? 'visibility_off' :
									'visibility'
									}}</mat-icon>
							</span>
						</div>
					</div>
					<div class="password-requirements">
						Passwords must be a minimum of 9 characters, start with a letter, and include
						upper and lowercase letters, a special character !@$%&*, and a number.
					</div>
					<hr />
					<div>
						<label class="input-label">Educational Institution</label><label class="required-label">(Required)</label>
						<div class="input-group mb-3">
							<input type="text" class="form-control" placeholder="Name" aria-label="Name"
								aria-describedby="basic-addon1" [(ngModel)]="user.educationalName" />
						</div>
						<div class="input-group mb-3">
							<select class="form-select" [ngClass]="{
									'placeholder-select-option': !user.educationalType
								}" aria-label="Default select example" [(ngModel)]="user.educationalType"
								(ngModelChange)="selectTypeHandler($event)" (change)="selectTypeHandler($event)">
								<option [ngValue]="'k-12'">K-12 School</option>
								<option [ngValue]="'after-school'">After-school program</option>
								<option [ngValue]="'museum'">Museum program</option>
								<option [ngValue]="'summer'">Summer program</option>
								<option [ngValue]="'library'">Library program</option>
								<option [ngValue]="'camp'">Camp</option>
								<option [ngValue]="'other'">Other</option>
								<option [ngValue]="null" selected disabled hidden>Type</option>
							</select>
						</div>
						<div class="input-group mb-3">
							<input type="text" class="form-control" placeholder="City" aria-label="City"
								aria-describedby="basic-addon1" [(ngModel)]="user.educationalCity" />
						</div>
						<div class="input-group mb-3">
							<input type="text" class="form-control" placeholder="State" aria-label="State"
								aria-describedby="basic-addon1" [(ngModel)]="user.educationalState" />
						</div>
						<div class="input-group mb-3">
							<input type="text" class="form-control" placeholder="Zip" aria-label="Zip" aria-describedby="basic-addon1"
								[(ngModel)]="user.educationalZipcode" />
						</div>
					</div>

					<hr />
					<div>
						<label class="input-label">Subject taught</label>
						<div class="input-group mb-3">
							<input type="text" class="form-control" placeholder="Name" aria-label="Name"
								aria-describedby="basic-addon1" [(ngModel)]="user.subjectTaught" />
						</div>

						<label class="input-label">Grade level</label>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="useGLK4" [(ngModel)]="user.kFour" />
							<label class="form-check-label" for="userGLK4">
								K-4
							</label>
						</div>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="userGL58"
								[(ngModel)]="user.fiveEight" />
							<label class="form-check-label" for="userGL58">
								5-8
							</label>
						</div>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="userGL912"
								[(ngModel)]="user.nineTwelve" />
							<label class="form-check-label" for="userGL912">
								9-12
							</label>
						</div>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="userGLUni"
								[(ngModel)]="user.university" />
							<label class="form-check-label" for="userGLUni">
								University
							</label>
						</div>
					</div>

					<hr />
					<div>
						<label class="input-label">Which of the following are you interested in?</label>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="userIIReady" [(ngModel)]="user.interestReady2UseContent" />
							<label class="form-check-label" for="userIIReady">
								Ready-to-use content
							</label>
						</div>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="userIIVirtual" [(ngModel)]="user.interestVirtualTours" />
							<label class="form-check-label" for="userIIVirtual">
								Creating virtual tours
							</label>
						</div>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="userIIDigital" [(ngModel)]="user.interestDigitalLessons" />
							<label class="form-check-label" for="userIIDigital">
								Creating digital lessons
							</label>
						</div>
						<div class="form-check" style="left: 20px;">
							<input class="form-check-input" type="checkbox" value="" id="userIINASA" [(ngModel)]="user.interestNASAContent" />
							<label class="form-check-label" for="userIINASA">
								NASA content and resources
							</label>
						</div>
					</div>

				</div>
			</div>
			<div class="row btn-div">
				<button type="submit" value="Submit" (click)="submit()" class="btn" [disabled]="!user.id">
					Update Profile
				</button>
			</div>
		</div>
	</div>
</section>