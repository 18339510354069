import { ApplicationRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';
import { ISubscription } from 'rxjs/Subscription';

@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
	public educatorModeOn: boolean = this.event.educatorMode;
	private subscription: ISubscription;
	searchOpen: boolean = false;
	public searchInput: string;
	@Input() props: any;
	constructor(
		private event: EventService,
		private router: Router,
		private appRef: ApplicationRef
	) {}

	ngOnInit(): void {
		this.searchInput = '';
		this.event.currentData.subscribe((e) => {
			if (e.action === 'search') {
				if ((e as Object).hasOwnProperty('dataob')) {
					if ((e as Object)['dataob'].input) {
						this.searchInput = (e.dataob as any).input;
					}
				}
				document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
				this.router.navigateByUrl('/search?searchQuery=' + this.searchInput);
			}
		});
		this.subscription = this.event.currentData.subscribe((data: any) => {
			if (data.action === 'set_flag_mode') {
				this.educatorModeOn = data.dataobj.educatorModeOn;
			}
		});
	}

	// Mobile searchbar show or hide
	toggleSearch() {
		if (this.searchOpen) {
			this.searchOpen = false;
		} else {
			this.searchOpen = true;
		}
	}

	// On search event
	public async search(e?: any) {
		this.event.searchQuery = this.searchInput;
		if (e && e.key !== 'Enter') return;
		this.event.globalEvent({
			action: 'search',
			dataob: {
				input: this.searchInput
			}
		});
		if (this.router.url.indexOf('/search') !== 0) {
			await this.router.navigate(['search']);
			await this.appRef.tick();
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
