import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EventService } from 'src/app/services/event/event.service';
import { ApiService } from 'src/app/services/API/api.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
	events: any[];

	constructor(
		private route: ActivatedRoute,
		private api: ApiService,
		private router: Router,
		private event: EventService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
		private auth: AuthenticationService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		this.getEvents();
		this.auth.refreshToken();
	}

	async getEvents() {
		await this.api.get('events?_limit=12&_sort=date:desc').toPromise().then((res) => {
			// this.events = res;
		});
	}

	public goToPage(url: string) {
		if (!url) return;
		if (isPlatformBrowser(this.platformId)) {
			this.window.nativeWindow.open(url, '_blank');
		}
	}
}
