import { isPlatformBrowser } from '@angular/common';
import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	HostListener,
	Inject,
	PLATFORM_ID
} from '@angular/core';

import { NavigationStart, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ISubscription } from 'rxjs/Subscription';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { environment } from '../../../../../../environments/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	@ViewChild('stickyMenu') menuElement: ElementRef;
	environment: any = environment;
	private subscription: ISubscription;
	sticky: boolean = false;
	public stickyDelay: number = 0;
	elementPosition: any = 50;
	currentPage = '';
	pageData: any = {};
	headerData: any = {};
	public educatorModeOn: boolean = this.event.educatorMode;
	private reducedHeaderPages = ['/join'];
	public reducedHeader: boolean = false;

	public get authenticated(): boolean {
		return this.api.authenticated;
	}

	public get stickyLogo(): boolean {
		if (this.sticky) return false;
		const timeSpan = Date.now() - this.stickyDelay;
		return (this.sticky || this.isMobile) && (timeSpan > 500);
	}

	public get heroLogo(): boolean {
		if (this.sticky) return true;
		if (this.isMobile) return false;
		const timeSpan = Date.now() - this.stickyDelay;
		return (!this.sticky) || (this.stickyDelay === 0 || timeSpan < 500);
	}

	constructor(
		public api: ApiService,
		private router: Router,
		private event: EventService,
		private deviceService: DeviceDetectorService,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
	) {
		// Set Current Page
		// clear alert message on route change
		router.events.subscribe((event: any) => {
			this.currentPage = this.router.url;
			if (event instanceof NavigationStart) {
				this.reducedHeader =
					this.reducedHeaderPages.indexOf(event.url) >= 0 && !this.isMobile;
			}
		});
		// Receive event and data from another component
		this.subscription = this.event.currentData.subscribe((data: any) => {
			if (data.action === 'set_page') {
				this.currentPage = data.dataobj.page;
				if (this.currentPage !== '' && this.currentPage !== '/') {
					this.sticky = true;
					this.stickyDelay = Date.now();
				}
			}
			if (data.action === 'set_flag_mode') {
				this.educatorModeOn = data.dataobj.educatorModeOn;
			}
		});
	}

	public get isMobile(): boolean {
		return this.deviceService.isMobile();
	}

	ngOnInit() {
		this.api
			.get('header', null)
			.subscribe((data) => {
				this.headerData = data;
			});
		this.getpageDetail('home-page');
	}

	// Get home page data from api
	getpageDetail(slug: any) {
		this.api.get(slug).subscribe(
			(result) => {
				this.pageData = result;
			},
			() => {}
		);
	}

	// Set logo sticky effect on scroll down page
	@HostListener('window:scroll', ['$event']) handleScroll() {
		if(isPlatformBrowser(this.platformId)) {
			const windowScroll = this.window.nativeWindow.pageYOffset;
			if (windowScroll >= this.elementPosition) {
				this.sticky = true;
				if (this.stickyDelay === 0) this.stickyDelay = Date.now();
			} else {
				if (window.location.pathname === '' || window.location.pathname === '/') {
					this.sticky = false;
					this.stickyDelay = 0;
				}
			}
		}
	}

	// Redirect to another route or page
	gotoRoute(route: string) {
		this.currentPage = route;
		this.router.navigate(['/' + route]);
		if (document.getElementById('Featured-0'))
			document
				.getElementById('Featured-0')
				.getElementsByTagName('button')
				.item(0)
				.focus();
	}

	// Unsubscribe event before leave component
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
