import { NgModule } from '@angular/core';
import { MarkdownPipe } from './markdown/markdown.pipe';

@NgModule({
	declarations: [
		MarkdownPipe,
	],
	imports: [],
	exports: [
		MarkdownPipe,
	]
})
export class MarkdownModule {}
