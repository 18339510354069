import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitContentModalComponent } from './submit-content-modal/submit-content-modal.component';
import { SubmitErrorComponent } from './submit-error/submit-error.component';

@NgModule({
	declarations: [SubmitContentModalComponent, SubmitErrorComponent],
	imports: [CommonModule]
})
export class SubmitContentModalModule {}
