<p>Latest browser version supported</p>

<div class="tech-support row col-12 mx-0 px-0">
	<ul>
		<li
		class="col-2 px-0 mx-0"
		[ngClass]="{ 'd-none': !browser.available }"
		*ngFor="let browser of browsers"
		>
			<div class="tech-box" *ngIf="browser.available">
				<img [src]="browser.icon" alt="browser icon">
				<p>{{ browser.title }}</p>
			</div>
		</li>
	</ul>
</div>

<p>Device support</p>

<div class="tech-support row col-12 mx-0 px-0">
	<ul>
		<li
		class="col-2 px-0 mx-0"
		[ngClass]="{ 'd-none': !hardware.available }"
		*ngFor="let hardware of hardwares"
		>
		<div class="tech-box">
			<span class="icon-tech"  *ngIf="hardware.available">
				<i [class]="hardware.icon"></i>
			</span>
			<p>{{ hardware.title }}</p>
		</div>
		</li>
	</ul>
</div>
