import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CacheService {
	private cache: any = {};

	constructor() {}

	public getCache(route: string): any {
		return this.cache[route];
	}

	public setCache(route: string, data: any): void {
		this.cache[route] = data;
	}
}
