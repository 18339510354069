import { ApplicationRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import {
	AddTagModalComponent,
	ITopic
} from 'src/app/shared/modules/add-tag-modal/add-tag-modal/add-tag-modal.component';

@Component({
	selector: 'app-lesson-right',
	templateUrl: './lesson-right.component.html',
	styleUrls: ['./lesson-right.component.scss']
})
export class LessonRightComponent implements OnInit {
	@Input() lesson: any;
	@Input() contentType: string;
	public get educatorMode(): boolean {
		return this.event.educatorMode;
	}

	public get authenticated(): boolean {
		return this.api.authenticated;
	}

	constructor(
		private router: Router,
		private event: EventService,
		private appRef: ApplicationRef,
		private dialog: MatDialog,
		private api: ApiService
	) {}

	ngOnInit(): void {}

	public async searchForTopic(topic: ITopic) {
		if (!this.authenticated || !this.educatorMode) {
			return;
		}
		if (this.router.url.indexOf('/search') !== 0) {
			await this.router.navigate(['search']);
			await this.appRef.tick();
		}
		this.event.globalEvent({
			action: 'search',
			dataob: {
				input: topic.title
			}
		});
	}

	public async editTag(topic: ITopic) {
		const dialog = await this.dialog.open(AddTagModalComponent, {
			width: '400px',
			panelClass: 'addTagModal',
			data: {
				lessonId: this.lesson.id,
				topic
			}
		});
		dialog.afterClosed().subscribe((updatedTopic) => {
			if (updatedTopic) {
				this.lesson.topicTags = this.lesson.topicTags.map((t) => {
					if (t.id === updatedTopic.id) {
						return updatedTopic;
					} else {
						return t;
					}
				});
			}
		});
	}

	public get topics() {
		if (!this.lesson.topicTags) return [];
		return this.lesson.topicTags.filter((t) => t.approved);
	}
}
