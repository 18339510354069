import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnsComponent } from './columns/columns.component';
import { MarkdownModule } from 'src/app/pipes/markdown/markdown.module';
import { SafeHtmlModule } from 'src/app/pipes/safehtml/safehtml.module';

@NgModule({
	declarations: [ColumnsComponent],
	imports: [CommonModule, MarkdownModule, SafeHtmlModule],
	exports: [ColumnsComponent]
})
export class ColumnsModule {}
