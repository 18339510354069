import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { withLatestFrom } from 'rxjs/operators';

@Component({
	selector: 'app-see-more',
	templateUrl: './see-more.component.html',
	styleUrls: ['./see-more.component.scss']
})
export class SeeMoreComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialogRef<SeeMoreComponent>,
		private event: EventService,
		private router: Router
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: '/other' }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {}

	public dismiss(): void {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: '/' }
		};
		this.event.globalEvent(actionOb);
		this.dialog.close();
	}
}
