<main>
    <div class="text-center">
        <input class="key" type="text" [(ngModel)]="apiKey" placeholder="Input API key">
        <input class="form-control" type="file"
                (change)="onChange($event)">

        <button (click)="onUpload()"
            class="btn btn-success">
            Upload Users
        </button>
				<h1>OR</h1>
				<button (click)="onDownload()"
            class="btn btn-success">
            Download Users
        </button>
    </div>

    <!--Flag variable is used here-->
    <div class="container" *ngIf="loading">
        <h3>Loading ...</h3>
    </div>
    <div class="container error" *ngIf="errorMsg.length > 0">
        <h3>{{errorMsg === 'Forbidden' ? 'Not authorized (did you enter your API key?)' : errorMsg}}</h3>
    </div>
    <div class="container success" *ngIf="successMsg.length > 0">
        <h3>{{successMsg}}</h3>
    </div>
</main>
