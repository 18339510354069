<div class="modal-content">
	<div class="modal-header">
		<h2 class="modal-title" id="exampleModalLongTitle">
			{{ modalTitle }}
		</h2>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true" (click)="dismiss()">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="tools">
			<div class="tool-wrap">
				<div class="list-wrapper">
					<div class="list-tool">
						<ul>
							<li class="opt-sign" *ngFor="let tool of socialMedia">
								<a [href]="tool.url" target="_blank">
									<img [src]="tool.logo.url" [alt]="tool.logo.alternativeText"/>
									<p>{{tool.name}}</p>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

