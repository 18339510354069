import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContributedContentComponent } from './components/contributed-content/contributed-content.component';
import { LessonModule } from '../lesson/lesson.module';
import { LessonBannerModule } from 'src/app/shared/modules/lesson-banner/lesson-banner.module';
import { TechnicalDetailsModule } from 'src/app/shared/modules/technical-details/technical-details.module';

@NgModule({
	declarations: [
		ContributedContentComponent,
	],
	imports: [CommonModule, LessonModule, LessonBannerModule, TechnicalDetailsModule]
})
export class ContributedContentModule {}
