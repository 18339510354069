import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-lesson-right-detail',
	templateUrl: './lesson-right-detail.component.html',
	styleUrls: ['./lesson-right-detail.component.scss']
})
export class LessonRightDetailComponent implements OnInit {
	@Input() lesson: any;

	ngOnInit(): void {}

	public mdToHtml(md: string): string {
		if (!md) return '';
		return md.split('\n').join('<br>');
	}

	public splitByLine(content: string): string[] {
		if (!content) return [];
		return content.split('\n');
	}
}
