import { resolve } from 'dns';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../model/user.model';
import { ApiService } from 'src/app/services/API/api.service';
import { environment } from 'src/environments/environment';
import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
	CognitoUserAttribute,
	CookieStorage
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

declare const gtag;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(
		private http: HttpClient,
		public api: ApiService,
		private localStorage: LocalstorageService,
		private router: Router,
		private alertService: AlertService,
		private window: WindowRef
	) {
		this.currentUserSubject = new BehaviorSubject<User>(
			JSON.parse(this.localStorage.getItem('currentUser') ?? '{}')
		);
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	async login(identifier: string, password: string) {
		let authenticationDetails = new AuthenticationDetails({
			Username: identifier,
			Password: password
		});
		let poolData = {
			UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
			ClientId: environment.cognitoAppClientId, // Your client id here
			Storage: new CookieStorage({ domain: environment.cookie_url })
		};

		let userPool = new CognitoUserPool(poolData);
		let userData = {
			Username: identifier,
			Pool: userPool,
			Storage: new CookieStorage({ domain: environment.cookie_url })
		};
		var cognitoUser = new CognitoUser(userData);
		let tokens: any = await new Promise((resolve, reject) => {
			cognitoUser.authenticateUser(authenticationDetails, {
				onSuccess: (result: any) => {
					resolve(result);
				},
				onFailure: (err) => {
					console.log(err);
					reject(err.message || JSON.stringify(err));
				}
			});
		});
		return this.api
			.get(
				`auth/cognito/callback?id_token=${tokens
					.getIdToken()
					.getJwtToken()}&access_token=${tokens
					.getAccessToken()
					.getJwtToken()}&refresh_token=${tokens.getRefreshToken().getToken()}`
			)
			.pipe(
				map((user) => {
					// login successful if there's a jwt token in the response
					if (user && user.jwt) {
						try {
							gtag('event', 'logging_state', {
								event_category: 'Existing User',
								event_label: 'Logged In'
							});
						} catch (error) {
							console.warn('Failed to add logging state to google analytics');
						}
						// store user details and jwt token in local storage to keep user logged in between page refreshes
						this.localStorage.setItem('token', user.jwt);
						this.localStorage.setItem(
							'cognito_id_token',
							tokens.getIdToken().getJwtToken()
						);
						this.localStorage.setItem('currentUser', JSON.stringify(user.user));
						this.currentUserSubject.next(user.user);
						this.api.token = user.jwt;
					}

					return user;
				})
			)
			.toPromise();
	}

	register(user: any) {
		return this.http.post(
			decodeURI(environment.api_url + 'auth/local/register'),
			user
		);
	}

	logout(skipNav: boolean = false) {
		// remove user from local storage to log user out
		try {
			gtag('event', 'logging_state', {
				event_category: 'Existing User',
				event_label: 'Logged Out'
			});
		} catch (error) {
			console.warn('Failed to add logging state to google analytics');
		}
		this.localStorage.removeItem('currentUser');
		this.localStorage.removeItem('token');
		this.localStorage.removeItem('cognito_id_token');
		delete this.api.token;
		this.currentUserSubject.next(null);
		const poolData = {
			UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
			ClientId: environment.cognitoAppClientId, // Your client id here
			Storage: new CookieStorage({ domain: environment.cookie_url })
		};

		var userPool = new CognitoUserPool(poolData);
		var cognitoUser = userPool.getCurrentUser();
		cognitoUser?.signOut();
		if(!skipNav) {
			this.window.nativeWindow.open('/', '_self');
			this.router.navigate(['/']);
		}
	}

	updateUser(data) {
		this.localStorage.setItem('currentUser', JSON.stringify(data));
		this.currentUserSubject.next(data);
	}

	// Triggers the modal though the even.service
	// Currently the modal is in the header.component
	triggerModalLogout() {
		this.logout(true);
		alert('Your session has expired. Please sign in.');
		setTimeout(() => { // delay required for local testing so menu closed on logout
			let ele = document.getElementById('img-user-profile-icon');
			ele?.click();
		}, 1000);
	}

	refreshToken() {
		if (this.api.authenticated) {
			try {
				gtag('event', 'logging_state', {
					event_category: 'Existing User',
					event_label: 'Page Visit'
				});
			} catch (error) {
				console.warn('Failed to add logging state to google analytics');
			}

			let poolData = {
				UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
				ClientId: environment.cognitoAppClientId, // Your client id here
				Storage: new CookieStorage({ domain: environment.cookie_url })
			};

			var userPool = new CognitoUserPool(poolData);
			var cognitoUser = userPool.getCurrentUser();

			cognitoUser.getSession((err, session) => {
				if (err) {
					console.log('authtentication.service', err, session);
					// alert(err.message || JSON.stringify(err));
					this.triggerModalLogout();
					return;
				}
				this.localStorage.setItem(
					'cognito_id_token',
					session.getIdToken().getJwtToken()
				);
			});
		}
	}
}
