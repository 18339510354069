import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lesson } from 'src/app/model/lesson.model';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { environment } from 'src/environments/environment';
import marked from 'marked';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';

@Component({
	selector: 'app-contributed-content',
	templateUrl: './contributed-content.component.html',
	styleUrls: ['./contributed-content.component.scss']
})
export class ContributedContentComponent implements OnInit {
	public lesson: Lesson;
	public apiUrl = environment.api_url;
	loadFlag: boolean = false;
	id: any;
	sub: any;
	public likes: number = 0;
	public shares: number = 0;
	public watches: number = 0;
	public bookmarks: number = 0;

	constructor(
		private event: EventService,
		private router: Router,
		public api: ApiService,
		private route: ActivatedRoute,
		private miniMetricService: MiniMetricsService,
		private authenticationService: AuthenticationService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		this.loadFlag = true;
		this.sub = this.route.params.subscribe((params) => {
			this.id = params.id;
		});
		this.getLessonData(this.id);
		document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });

		this.authenticationService.refreshToken();
	}

	getLessonData(id) {
		this.likes = 0;
		this.bookmarks = 0;
		this.shares = 0;
		this.watches = 0;

		this.api
			.get('contributed-contents/' + id, null)
			.toPromise()
			.then((res) => {
				this.likes += res.likes || 0;
				this.bookmarks += res.bookmarks || 0;
				this.lesson = {
					...res,
					description: marked(res.description || ''),
					centralQuestion: marked(res.centralQuestion || ''),
					instructionalObjective: marked(res.instructionalObjective || ''),
					learningOutcomes: marked(res.learningOutcomes || ''),
					prerequisites: marked(res.prerequisites || '')
				};
			});
		this.miniMetricService.getMiniMetrics('contributed_content', id).subscribe((res) => {
			this.likes += res.likes;
			this.bookmarks += res.bookmarks;
			this.shares += res.shares;
			this.watches += res.watches;
		});
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}
}
