<main role="main" class="flex-shrink-0 middle-section search-page">
	<div class="middle-inner">
		<div class="search-column" *ngIf="!loading">
			<app-search-form
				(searchToggleEvent)="searchToggle($event)"
				(checkboxUpdate)="checkboxUpdate($event)"
			>
			</app-search-form>
			<section
				class="featured-section login-featured-section search-filter-pad"
				[ngClass]="searchStatus ? 'pl-zero' : ''"
			>
				<div class="search-div-bg"></div>
				<div class="featured-inner">
					<div class="header">
						<h2>Content Library</h2>
						<select
							class="w-100 px-2 py-1"
							placeholder="Sort by"
							aria-label="Sort by"
							aria-pressed="false"
							[(ngModel)]="sortBy"
							[ngClass]="{'mobile': isMobile,'desktop':!isMobile}"
							(change)="changeSortSeletor()"
						>
							<option [value]="null">Sort by</option>
							<option value="date">Date Created</option>
							<option value="name">Content Name</option>
						</select>
					</div>
					<div class="container-fluid">
						<!--
						<div
							class="row position-fixed p-2 submitLesson"
							[ngClass]="{ disabled: authenticated === false }"
							*ngIf="educatorModeOn"
							(click)="authenticated ? goToSubmitLesson() : loginModalToggle()"
						>
							<div class="col-3 mx-0 px-0 h-100 text-center">
								<div
									class="icon mx-auto my-auto align-self-center position-relative"
								>
									<i class="fas fa-plus position-absolute"></i>
								</div>
							</div>
							<div class="offset-1 col-8 px-0 textContent font-weight-bold">
								Submit Your<br />Own Lesson
							</div>
						</div>
						-->
						<!-- <app-slider-community *ngIf="educatorModeOn"></app-slider-community> -->
						<app-lesson-container
							sliderTitle="Lessons"
							[lessons]="filteredLessons"
							[slider]="isMobile ? false : slider"
							[clipHorizontally]="true"
							[clipVertically]="true"
							[minSize]="isMobile ? 2 : null"
							[smaller]="isMobile ? true : false"
							*ngIf="lessonLibrary && filteredLessons.length > 0"
						>
						</app-lesson-container>
						<app-collection-container
							sliderTitle="Collections"
							[collections]="filteredCollections"
							[slider]="isMobile ? false : slider"
							[clipHorizontally]="true"
							[clipVertically]="true"
							[minSize]="isMobile ? 2 : null"
							[smaller]="isMobile ? true : false"
							*ngIf="collectionLibrary && filteredCollections.length > 0"
						>
						</app-collection-container>
						<app-lesson-container
							sliderTitle="Visualizations"
							[lessons]="filteredVisualizations"
							[slider]="isMobile ? false : slider"
							[clipHorizontally]="true"
							[clipVertically]="true"
							[minSize]="isMobile ? 2 : null"
							[smaller]="isMobile ? true : false"
							[auth]="authenticated"
							(openSeeMore)="callLoginModalToggleFromBtn($event)"
							*ngIf="
								visualizationLibrary &&
								educatorModeOn &&
								filteredVisualizations.length > 0
							"
						>
						</app-lesson-container>
						<app-lesson-container
							sliderTitle="Contributed"
							[lessons]="filteredContributed"
							[slider]="isMobile ? false : slider"
							[clipHorizontally]="true"
							[clipVertically]="true"
							[minSize]="isMobile ? 2 : null"
							[smaller]="isMobile ? true : false"
							[auth]="authenticated"
							(openSeeMore)="callLoginModalToggleFromBtn($event)"
							*ngIf="
								contributedLibrary &&
								educatorModeOn &&
								filteredContributed.length > 0
							"
						>
						</app-lesson-container>
						<!-- <app-visualization-container sliderTitle="Visualization Library" [visualizations]="visualizationSlides"
						[slider]="slider" [clipHorizontally]="true" [clipVertically]="true" [minSize]="isMobile ? 2 : null"
						[smaller]="isMobile ? true : false" *ngIf="visualizationLibrary && educatorModeOn"></app-visualization-container> -->
					</div>
				</div>
			</section>
		</div>
	</div>
	<div
		class="modal fade modal-auth"
		[ngClass]="toggleModal ? 'show' : ''"
		id="modal-signin"
		[ngStyle]="{ display: toggleModal == true ? 'block' : 'none' }"
	>
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title" id="exampleModalLongTitle">
						Interested in seeing more?
					</h2>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span aria-hidden="true" (click)="loginModalToggle()">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="login-form-main">
						<div class="login-info">
							<p>
								Login or create an account for full access to simulations,
								community created content, and collaboration tools.
							</p>
						</div>
						<app-login
							title="loginModal"
							forgotPasswordRightAlignment="true"
						></app-login>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div [ngClass]="toggleModal ? 'modal-backdrop fade show' : ''"></div>
</main>
