import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolPageComponent } from './component/tool-page/tool-page.component';

@NgModule({
	declarations: [ToolPageComponent],
	imports: [CommonModule, RouterModule]
})
export class ToolPageModule {}
