<div class="col-lg-12">
	<div class="content-right">
		<h2>Learning Outcomes</h2>

		<div
			class="lesson-item-description"
			[innerHTML]="lesson.learningOutcomes | safeHtml | markdown"
		></div>
	</div>

	<div class="content-right pre-requesti">
		<h2>Prerequisites</h2>

		<div
			class="lesson-item-description"
			[innerHTML]="lesson.prerequisites | safeHtml | markdown"
		></div>
	</div>
</div>
