<main role="main" class="flex-shrink-0 middle-section" *ngIf="loadFlag">
	<div class="middle-inner">
		<app-lesson-banner
			[bannerType]="'lesson'"
			*ngIf="lesson"
			[lesson]="lesson"
			[bookmarks]="bookmarks"
			[likes]="likes"
		></app-lesson-banner>

		<section class="lession-section">
			<div class="section-inner">
				<div class="container">
					<div class="row">
						<div class="col-lg-3 col-md-4 br-01">
							<app-lesson-left
							*ngIf="lesson"
								[lesson]="lesson"
								[watches]="watches"
								[shares]="shares"
							></app-lesson-left>
						</div>

						<div class="col-lg-9 col-md-8">
							<app-lesson-right *ngIf="lesson" [lesson]="lesson" [contentType]="'lesson'"></app-lesson-right>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</main>
