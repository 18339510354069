import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonBannerComponent } from './lesson-banner/lesson-banner.component';
import { CommunityContributionComponent } from './lesson-banner/community-contribution/community-contribution.component';
import { LessonCountsComponent } from './lesson-banner/lesson-counts/lesson-counts.component';
import { LessonTitleComponent } from './lesson-banner/lesson-title/lesson-title.component';

@NgModule({
	declarations: [
		LessonBannerComponent,
		CommunityContributionComponent,
		LessonCountsComponent,
		LessonTitleComponent
	],
	imports: [CommonModule],
	exports: [
		LessonBannerComponent,
		CommunityContributionComponent,
		LessonCountsComponent,
		LessonTitleComponent
	]
})
export class LessonBannerModule {}
