<div class="collectionContainer row col-12 mx-0 px-0" #collectionContainer>
	<div class="title col-12 mx-0 px-0 my-2 pl-2" *ngIf="sliderTitle">
		<h4 class="text-white font-weight-bold my-0">{{ sliderTitle }}</h4>
	</div>
	<div class="sliderArrowContainer row col-1 mx-0 px-0 py-2" *ngIf="slider">
		<button class="sideArrow border-0 offset-3 col-12 col-md-6 p-2 text-center d-flex" [disabled]="getCollections().length <= 4" (click)="moveSlider(false)" aria-label="previous">
			<i class="fas fa-chevron-left align-self-center mx-auto"></i>
		</button>
	</div>
	<div class="sliderContainer row col-10 mx-0 px-0" *ngIf="slider">
		<app-collection-card class="col-lg-3 col-md-6 col-12 mx-0 p-2 custom_collection_card" [ngStyle]="{
				left: getCardSpacing() * (i - sliderIndex) + '%',
				transition: sliderTransition ? sliderTransitionTime + 's ease' : 'none'
			}" *ngFor="let collection of collections; let i = index"
			[smaller]="smaller" [collection]="collection"
			[left]="i - sliderIndex === 0 && getCardSpacing() < 100"
			[right]="i - sliderIndex === 3 && getCardSpacing() < 100"
			(click)="openCollection(collection)"></app-collection-card>
	</div>
	<div class="sliderArrowContainer row col-1 mx-0 px-0 py-2" *ngIf="slider">
		<button class="sideArrow border-0 offset-3 col-12 col-md-6 p-2 text-center d-flex" [disabled]="getCollections().length <= 4" (click)="moveSlider(true)" aria-label="next">
			<i class="fas fa-chevron-right align-self-center mx-auto"></i>
		</button>
	</div>
	<div class="row col-12 mx-0 px-0 my-2" *ngIf="!slider">
		<app-collection-card [ngClass]="{
				'col-lg-6': maxSize === 2,
				'col-6': minSize === 2,
				'col-12': !minSize
			}" class="col-lg-3 col-md-6 col-12 mx-0 p-2" *ngFor="let collection of collections; let i = index"
			[smaller]="smaller" [collection]="collection"
			[left]="i % convertSpacing(getCardSpacing()) === 0 && getCardSpacing() < 100"
			[right]="i % convertSpacing(getCardSpacing()) === convertSpacing(getCardSpacing()) - 1 && getCardSpacing() < 100"
			(click)="openCollection(collection)"></app-collection-card>
	</div>
</div>