import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/API/api.service';

@Component({
	selector: 'app-most-popular',
	templateUrl: './most-popular.component.html',
	styleUrls: ['./most-popular.component.scss']
})
export class MostPopularComponent implements OnInit {
	public mostPopulars = [];
	@Input() formGroup: FormGroup;

	constructor(private api: ApiService) {}

	ngOnInit(): void {
		this.getTopics();
	}

	// toggle most popular tags class
	activateClass(popular) {
		popular.active = !popular.active;
		this.formGroup.controls.popularTopics.setValue(
			this.mostPopulars.filter((t) => t.active).map((t) => t.name)
		);
	}

	// show all/less tags on click
	tagsShow: boolean = false;
	showAll() {
		this.tagsShow = !this.tagsShow;
	}

	// get topics data from api
	getTopics() {
		this.api
			.get('topics?approved=true', null)
			.toPromise()
			.then((res) => {
				this.mostPopulars = res.map(function (a) {
					return {
						name: a.title,
						value: a.title
					};
				});
			});
	}
}
