import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportIssueComponent } from './components/report-issue.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
	declarations: [ReportIssueComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RecaptchaModule,
		RecaptchaFormsModule
	]
})
export class ReportIssueModule {}
