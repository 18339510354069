import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'infiniscope-website';

	static isBrowser = new BehaviorSubject<boolean>(null);

	constructor(@Inject(PLATFORM_ID) private platformId: any, private meta: Meta) {
		AppComponent.isBrowser.next(isPlatformBrowser(platformId));
	}

	ngOnInit() {
		if(!environment.production) {
			this.meta.addTag({ name: 'robots', content: 'noindex' });
		}
	}
}
