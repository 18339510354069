import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EventService } from './../../../services/event/event.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/API/api.service';
//import { PageService } from 'src/app/services/page/page.service';

@Component({
	selector: 'app-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
	public pageData: any;
	constructor(
		private route: ActivatedRoute,
		private api: ApiService,
		private router: Router,
		private event: EventService,
		private authenticationService: AuthenticationService
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		const slug = this.route.snapshot.paramMap.get('slug');
		if (slug) {
			this.api.get('pages/?slug=' + slug, null).subscribe((data) => {
				this.pageData = data[0];
			});
		} else {
			this.router.navigate(['/']);
		}

		this.authenticationService.refreshToken();
	}
}
