import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, ApplicationRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Collection } from 'src/app/model/collection';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';

@Component({
	selector: 'app-collection-container',
	templateUrl: './collection-container.component.html',
	styleUrls: ['./collection-container.component.scss']
})
export class CollectionContainerComponent implements OnInit {
	@Input() sliderTitle: string;
	@Input() collections: Collection[];
	@Input() slider: boolean = true;
	@Input() maxSize: number = 4;
	@Input() minSize: number;
	@Input() smaller: boolean = false;
	@Input() clipVertically: boolean = false;
	@Input() clipHorizontally: boolean = false;
	public sliderIndex: number = 0;
	public sliderTransition: boolean = true;
	public sliderTransitionTime: number = 0.5;

	constructor(
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: any,
		private window: WindowRef,
	) { }

	ngOnInit(): void { }

	public getCollections(): Collection[] {
		if (!this.collections || this.collections.length === 0) return [];
		const ret = [...this.collections];
		return ret;
	}

	public getCardSpacing() {
		if (isPlatformBrowser(this.platformId)) {
			const width = this.window.nativeWindow.innerWidth;
			let ret = 0;
			if (width > 992) ret = 25;
			else if (width > 768) ret = 50;
			else ret = 100;
			return ret >= 100 / this.maxSize ? ret : 100 / this.maxSize;
		}
	}

	public convertSpacing(spacing: number) {
		if (spacing === 100) return 1;
		if (spacing === 50) return 2;
		if (spacing === 25) return 4;
	}

	public openCollection(collection) {
		this.router.navigate(['/collection/' + collection.id]);
	}

	public async moveSlider(dir: boolean) {
		let firstSlide = this.sliderIndex === 0;
		let lastSlide = this.sliderIndex >= this.collections.length - 1; //  - (this.maxSize - 1)
		if ((firstSlide && !dir) || (lastSlide && dir)) {
			this.sliderTransition = false;
			this.sliderIndex = lastSlide ? -1 : this.collections.length; //  - (this.maxSize - 1)
			await new Promise<void>((resolve) => setTimeout(() => resolve(), 0));
			this.sliderTransition = true;
		}
		this.sliderIndex += dir ? 1 : -1;
	}
}
