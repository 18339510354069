import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/API/api.service';

@Injectable({
	providedIn: 'root'
})
export class ContactService {
	constructor(private api: ApiService) {}

	sendEmail(form: any) {
		return this.api
			.post('contact',form)
			.toPromise();
	}

	sendContentCopyRequest(objectId: string, objectType: string, userId: number) {
		return this.api
			.post('contact/copy', { objectId, objectType, userId })
			.toPromise();
	}
}
