import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
	selector: 'app-image-cropper-modal',
	templateUrl: './image-cropper-modal.component.html',
	styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent implements OnInit {
	imageChangedEvent: any = '';
	imageName: string = '';
	croppedImage: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<ImageCropperModalComponent>
	) {}

	ngOnInit(): void {}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}
	imageLoaded(image: LoadedImage) {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}

	close(type: string) {
		if (type == 'cancel') {
			this.croppedImage = '';
		}
		this.dialogRef.close({
			croppedImage: this.croppedImage,
			imageName: this.imageName
		});
	}
}
