import { ApiService } from 'src/app/services/API/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event/event.service';
import { MatDialog } from '@angular/material/dialog';
import { SubmitContentModalComponent } from './../../../shared/modules/submit-content-modal/submit-content-modal/submit-content-modal.component';
import { SubmitErrorComponent } from 'src/app/shared/modules/submit-content-modal/submit-error/submit-error.component';

@Component({
	selector: 'app-submit-content',
	templateUrl: './submit-content.component.html',
	styleUrls: ['./submit-content.component.scss']
})
export class SubmitContentComponent implements OnInit {
	@Input() requireAuthentication: boolean;

	constructor(
		private api: ApiService,
		private event: EventService,
		private router: Router,
		private dialog: MatDialog
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		this.domain = window.location.origin;
	}

	public techCards = [
		{
			title: 'Tour It',
			desc:
				'Tour it is a tool that enables you to create virtual tours of any location to establish a learners connection to or sense of place. Have you created a virtual tour using the Tour it tool? Choose Submit a Tour below',
			buttonTitle: 'Submit a Tour',
			authenticationRequired: true,
			disabled: false,
			card: [
				{
					content: 'Do you have the URL of the virtual tour ready?'
				},
				{
					content: 'Do you have an objective, description, and topics prepared?'
				},
				{
					content: 'Have you verified all assets work properly?'
				},
				{
					content:
						'Have you tested it with learners for effectiveness and to share the length of the lesson?'
				}
			]
		},
		{
			title: 'Argos Lesson',
			desc:
				'Argos enables you to create or modify lessons that are adaptive to the individual needs of learners using an open source platform. Have you created a lesson using the Argos tool? Choose Submit an Argos below.',
			buttonTitle: 'Submit an Argos Lesson',
			authenticationRequired: true,
			disabled: true,
			card: [
				{
					content: 'Do you have the URL of your Argos lesson ready?'
				},
				{
					content: 'Do you have an objective, description, and topics prepared?'
				},
				{
					content: 'Have you verified all assets work properly?'
				},
				{
					content:
						'Have you tested it with learners for effectiveness and to share the length of the lesson?'
				}
			]
		},
		{
			title: 'Other Materials',
			desc:
				'Have you created a lesson or lesson content using the other free educational products like Google Docs or YouTube? If it has a URL and it is an educational product, you can contribute that too! Choose Submit Other Materials below.',
			buttonTitle: 'Submit Other Materials',
			authenticationRequired: true,
			disabled: false,
			card: [
				{
					content: 'Do you have the URL of your materials prepared?'
				},
				{
					content: 'Do you have an objective, description, and topics prepared?'
				},
				{
					content: 'Have you tested it with learners for effectiveness?'
				}
			]
		}
	];

	public domain: string;

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	openModal(card) {
		const dialogRef = this.dialog.open(SubmitContentModalComponent, {
			width: '600px',
			panelClass: 'addTagModal',
			data: card
		});

		dialogRef.afterClosed().subscribe((res) => {
			console.log(res)
			if(res !== 'dismiss') {
				const dialogRef2 = this.dialog.open(SubmitErrorComponent, {
					width: '600px',
					panelClass: 'addTagModal',
					data: res
				});
			} else if(res) {
				this.router.navigate(['/tour-it']);
			}
		})
	}
}
