import { Component, Inject, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/API/api.service';

interface addTagData {
	lessonId: string;
	topic?: ITopic;
}

export interface ITopic {
	title: string;
	description: string;
	id: string,
}

@Component({
	selector: 'app-add-tag-modal',
	templateUrl: './add-tag-modal.component.html',
	styleUrls: ['./add-tag-modal.component.scss']
})
export class AddTagModalComponent implements OnInit {
	public form: FormGroup;
	public loading: boolean = false;
	private currentId: string;
	public confirming: boolean = false;

	constructor(
		private fb: FormBuilder,
		private api: ApiService,
		@Inject(MAT_DIALOG_DATA) public data: addTagData,
		private dialog: MatDialogRef<AddTagModalComponent>
	) {
		this.form = fb.group({
			name: new FormControl('', [Validators.required]),
			description: new FormControl('')
		});
	}

	async ngOnInit() {
		if (this.data.topic) {
			this.form.controls.name.setValue(this.data.topic.title);
			this.form.controls.description.setValue(this.data.topic.description);
			this.currentId = this.data.topic.id;
		}
	}

	public confirm() {
		this.confirming = true;
	}

	public regress() {
		this.confirming = false;
	}

	public get ctrl() {
		return this.form.controls;
	}

	public validateSubmition(): boolean {
		return (this.form.status === 'VALID');
	}

	public async submit() {
		this.loading = true;
		if (this.form.status !== 'VALID') {
			this.loading = false;
			return this.form.markAllAsTouched();
		}
		let lesson;
		if (this.currentId) {
			lesson = await this.api
				.put('/topics/' + this.currentId, {
					title: this.form.value.name,
					description: this.form.value.description
				})
				.toPromise();
		} else {
			lesson = await this.api
				.post('lessons/topic', {
					title: this.form.value.name,
					description: this.form.value.description,
					id: this.data.lessonId
				})
				.toPromise();
		}
		this.dialog.close(lesson);
		this.loading = false;
	}
}
