<header class="header" [ngClass]="educatorModeOn ? 'header-color2 ' : ''
	">

	<!-- skip nav -->
	<a href="#app-header" class="skipnav">Skip nav</a>

	<!-- Fixed navbar -->
	<nav id="app-header" class="navbar navbar-expand-md navbar-dark" #stickyMenu [class.fixed-top]="sticky"
		[ngClass]="{ 'reduce-header': reducedHeader }">
		<div class="main-header" *ngIf="!reducedHeader">
			<div class="container-fluid">
				<div class="row row-100 col-header">
					<div class="col-lg-12 col">
						<div class="logo-heder sticky" [hidden]="!stickyLogo && !isMobile">
							<div class="container p-0">
								<a class="navbar-brand smoth-scroll show-in-web" aria-label="Infiscope Logo"
								routerLink="/" *ngIf="pageData.logo">
									<img src="{{ pageData.logo.url }}" class="menu-logo" alt="Infiscope Logo" />
								</a>
								<a class="navbar-brand smoth-scroll show-in-mobile" routerLink="/"
									aria-label="Infiscope Logo">
									<img src="assets/images/mobile-logo.png" class="menu-logo" alt="Infiscope Logo" />
								</a>
							</div>
						</div>

						<div class="logo-container" [ngClass]="{'sticky': sticky, 'hide': !heroLogo}" *ngIf="!isMobile">
							<div class="container p-0">
								<a routerLink="/" aria-label="Infiscope Logo" *ngIf="pageData.logo">
									<img src="{{ pageData.logo.url }}" class="menu-logo" alt="Infiscope Logo" />
								</a>
								<!-- <a routerLink="/" aria-label="Infiscope Logo">
									<img src="assets/images/mobile-logo.png" class="menu-logo" alt=" " />
								</a> -->
							</div>
						</div>

						<div class="header-control">
							<div class="search-bar">
								<!-- Educator Mode -->
								<app-educator-mode [props]="{label: headerData?.educatorModeLabel}"></app-educator-mode>
								<!-- Search Bar -->
								<app-search-bar class="mw-475" [props]="{hint: headerData?.searchBoxHint}"></app-search-bar>
							</div>

							<app-notification></app-notification>

							<div class="top-header-right" *ngIf="educatorModeOn && authenticated">
								<app-tools></app-tools>
							</div>
							<div class="top-header-right">
								<app-profile></app-profile>
							</div>
						</div>



					</div>
				</div>
			</div>
		</div>

		<!-- Sub Header: isMobile -->
		<app-sub-header class="w-100"
			*ngIf="isMobile"
			[props]="{firstMenuData: headerData?.FirstHeaderMenu,
				secondMenuData: headerData?.SecondHeaderMenu,
				thirdMenuData: headerData?.ThirdHeaderMenu,
				fourthMenuData: headerData?.FourthHeaderMenu,
				fifthMenuData: headerData?.FifthHeaderMenu}"
			[logoUrl]="reducedHeader ? pageData.logo.url : ''"
			[ngClass]="{ 'reduce-header': reducedHeader || sticky }"
		>
		</app-sub-header>
		<!-- Sub Header: !isMobile -->
		<app-sub-header class="w-100"
		*ngIf="!isMobile"
		[props]="{firstMenuData: headerData?.FirstHeaderMenu,
				secondMenuData: headerData?.SecondHeaderMenu,
				thirdMenuData: headerData?.ThirdHeaderMenu,
				fourthMenuData: headerData?.FourthHeaderMenu,
				fifthMenuData: headerData?.FifthHeaderMenu}"
			[logoUrl]="reducedHeader ? pageData.logo.url : ''"
			[ngClass]="{ 'reduce-header': reducedHeader || sticky }"
		>
		</app-sub-header>
	</nav>
</header>
<router-outlet></router-outlet>
