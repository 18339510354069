<div class="modal-content">
	<div class="modal-header">
		<h2 class="modal-title" id="exampleModalLongTitle" tabindex="-1">
			{{data.title}}
		</h2>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true" (click)="dismiss()">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="login-form-main">
			<div class="login-info">
				<p>
					{{data.msg}}
				</p>
			</div>
		</div>
	</div>
</div>
