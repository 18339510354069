<div class="modal-content">
	<div class="modal-header">
		<h2 tabindex="-1" class="modal-title" id="exampleModalLongTitle">
			{{ data.title }} Submission
		</h2>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true" (click)="dismiss()">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="main">
			<div class="info">
				<h5>Something isn't right</h5>
				<p>
					Your lesson has not met one or more criteria for contribution. Please review your lesson or find a collaborator to discuss possible modifications.
				</p>

				<div class="img-div">
					<img src="./../../../../../assets/images/submit_error.png" alt="submit error">
				</div>

				<div>
					<button type="submit" value="Submit" (click)="dismiss()" class="btn">Back to Contributed Content</button>
				</div>
			</div>
		</div>
	</div>
</div>
