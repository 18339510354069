import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderModule } from './pages/section/header/header.module';
import { HomeModule } from './pages/home/home.module';
import { SearchModule } from './pages/search/search.module';
import { LessonModule } from './pages/lesson/lesson.module';
import { FooterComponent } from './pages/section/footer/footer.component';
import { CollectionModule } from './pages/collection/collection.module';
import { WindowRef } from './services/windowRef/window-ref.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactModule } from './pages/contact/contact.module';
import { ReportIssueModule } from './pages/report-issue/report-issue.module';
import { ProfilePageModule } from './pages/profile-page/profile-page.module';
import { BookmarkModule } from './pages/bookmark/bookmark.module';
import { VisualizationModule } from './pages/visualization/visualization.module';
import { PageModule } from './pages/page/page.module';
import { VisualizationContainerModule } from './shared/modules/visualization-container/visualization-container.module';
import { ToolPageModule } from './pages/tool-page/tool-page.module';
import { MarkdownModule } from './pipes/markdown/markdown.module';
import { SubmissionFormModule } from './pages/submission-form/submission-form.module';
import { ContributedContentModule } from './pages/contributed-content/contributed-content.module';
import { SubmitContentModule } from './pages/submit-content/submit-content.module';
import { EventModule } from './pages/event/event.module';
import { InfoModalModule } from './shared/modules/info-modal/info-modal.module';
import { SubmitTourItModule } from './pages/submit-tour-it/submit-tour-it.module';
import { SocialMediaModalModule } from './shared/modules/social-media-modal/social-media-modal/social-media-modal.module';

@NgModule({
	declarations: [AppComponent, FooterComponent],
	imports: [
		HttpClientModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		HeaderModule,
		HomeModule,
		SearchModule,
		LessonModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CollectionModule,
		FormsModule,
		ReactiveFormsModule,
		ContactModule,
		ReportIssueModule,
		ProfilePageModule,
		BookmarkModule,
		VisualizationModule,
		PageModule,
		VisualizationContainerModule,
		ToolPageModule,
		MarkdownModule,
		SubmissionFormModule,
		ContributedContentModule,
		SubmitContentModule,
		EventModule,
		InfoModalModule,
		SubmitTourItModule,
		SocialMediaModalModule,
	],
	providers: [WindowRef],
	bootstrap: [AppComponent]
})
export class AppModule {}
