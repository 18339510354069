import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionCardComponent } from './collection-card/collection-card.component';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [CollectionCardComponent],
	imports: [CommonModule, RouterModule],
	exports: [CollectionCardComponent]
})
export class CollectionCardModule {}
