<section>
	<div class="center">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-12">
					<h2>Your Bookmarked Experiences</h2>
					<p>
						For easy access, bookmark your favorite lessons, collections,
						visualizations, and contributed content
					</p>
				</div>
				<div class="col-md-4 col-12 button-col" *ngIf="deleteButton">
					<button class="remove-button" (click)="deleteSelected()">
						Remove selected bookmarks <mat-icon>delete</mat-icon>
					</button>
				</div>
			</div>
			<div class="row min-h-row">
				<ng-container *ngIf="arr.length > 0; else noBookmarks">
					<ng-container *ngFor="let i of arr">
						<div class="col-md-4 col-6">
							<div class="card" [ngClass]="{ 'selected-card': selected }">
								<div class="row">
									<div class="col-10 card-title">
										<label class="card-title">Kingdom in Peril: Ep {{ i }}</label>
									</div>
									<div class="col-2 selector">
										<div class="form-check">
											<input
												class="form-check-input position-static"
												[(ngModel)]="selected"
												(change)="changeColso($event)"
												type="checkbox"
											/>
										</div>
									</div>
								</div>
								<div class="row">
									<img src="./../../../../../assets/images/image.jpg" alt="placeholder image" />
								</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
				<ng-template #noBookmarks>
					<div class="col-12">
						<div class="no-bookmark">
							There are no bookmarks yet.
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</section>
