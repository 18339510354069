<div [ngClass]="{ blackBackground: authenticated }" style="margin-bottom: 35px;">
	<!-- <div class="common-title-header-div">
		<div class="row">
			<div class="col-lg-6 col-md-6 left-title">
				<div class="heading-left">
					<div class="heading">
						<h2>Community</h2>
					</div>
				</div>
			</div>
			<div *ngIf="!isMobile" class="col-lg-6 col-md-6 right-title">
				<div class="heading-right">
					<div class="button-group-box">
						<a href="#" class="btn btn-link mr-20">What's the Hive?</a>
						<a *ngIf="!authenticated" href="#" target="_blank" class="btn btn-join">Join the Hive!</a>
						<a *ngIf="authenticated" href="https://community.infiniscope.org/" target="_blank" class="btn btn-join">Go
							to the Hive!</a>
					</div>
				</div>
			</div>
		</div>
	</div> -->

	<div class="contentContainer">
		<div class="selector" role="tablist">
			<button type="button" role="tab" aria-selected="true" class="btn btn-community" (click)="communityTab = !communityTab" [ngClass]="{ 'btn-active': communityTab }">Community</button>
			<button type="button" role="tab" aria-selected="false" class="btn btn-community" (click)="communityTab = !communityTab" [ngClass]="{ 'btn-active': !communityTab }">Upcoming Events</button>
		</div>
		<div class="container content-div" [ngClass]="communityTab ? 'd-none' : 'd-block'">
			<div class="row row-padding" *ngIf="events">
				<div class="col-12 col-lg-6 general-col">
					<div class="content-card">
						<h2>{{events[2]?.title}}</h2>
						<p>{{ events[2]?.date | date: 'MM/dd/YYYY' }}</p>
						<img class="first-card" *ngIf="!events[2]?.picture" src="./../../../../../assets/images/image.jpg" alt=" ">
						<img class="first-card" *ngIf="events[2]?.picture" [src]="events[2]?.picture.url" [alt]="events[2]?.picture.alternativeText || events[2]?.title">
						<div class="desc desc-mobile">
							<p class="text-preview">{{events[2]?.description}}
							</p>
							<div class="div-btn">
								<button aria-label="learn more about Authoring Workshop" [disabled]="!events[2]?.url" [ngClass]="{'btn-disabled': !events[2]?.url}" (click)="openLink(events[2]?.url)" class="btn btn-card">Learn More</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-3 general-col">
					<div class="content-card">
						<h2>{{events[1]?.title}}</h2>
						<p>{{ events[1]?.date | date: 'MM/dd/YYYY' }}</p>
						<img class="second-card" *ngIf="!events[1]?.picture" src="./../../../../../assets/images/image.jpg" alt=" ">
						<img class="second-card" *ngIf="events[1]?.picture" [src]="events[1]?.picture.url" [alt]="events[1]?.picture.alternativeText || events[1]?.title">
						<div class="desc desc-two desc-mobile-two">
							<p class="text-preview preview-two">{{events[1]?.description}}
							</p>
							<div class="div-btn">
								<button aria-label="learn more about Authoring Workshop" [disabled]="!events[1]?.url" [ngClass]="{'btn-disabled': !events[1]?.url}" (click)="openLink(events[1]?.url)" class="btn btn-card">Learn More</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-3 general-col">
					<div class="col-12 inside-cols">
						<div class="content-card last-card">
							<h2>{{events[0]?.title}}</h2>
							<p>{{ events[0]?.date | date: 'MM/dd/YYYY' }}</p>
							<img *ngIf="!events[0]?.picture" src="./../../../../../assets/images/image.jpg" alt=" ">
							<img *ngIf="events[0]?.picture" [src]="events[0]?.picture.url" [alt]="events[0]?.picture.alternativeText || events[0]?.title">
							<div class="desc desc-three desc-mobile-two">
								<p class="text-preview preview-three">{{events[0]?.description}}
								</p>
								<div class="div-btn">
									<button aria-label="learn more about Authoring Workshop" [disabled]="!events[0]?.url" [ngClass]="{'btn-disabled': !events[0]?.url}" (click)="openLink(events[0]?.url)" class="btn btn-card">Learn More</button>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 inside-cols">
						<button class="btn btn-container" (click)="loginModelToggel()" *ngIf="!authenticated">Sign up/login to see more</button>
						<button class="btn btn-container" *ngIf="authenticated" (click)="openLink('/page/events', true)" routerLinkActive="router-link-active" >See all events</button>
					</div>
				</div>
			</div>
		</div>
		<div [ngClass]="communityTab ? 'd-block' : 'd-none'">
			<div class="row comm_main" style="padding: 30px;">
				<div class="col-md-8" *ngIf="authenticated">
					<div class="pint-main" id="pint-main" style="background: #fff;" tabindex="0">
						<a data-pin-do="embedUser" data-pin-board-width="800" data-pin-scale-height="590" data-pin-scale-width="100" href="https://pinterest.com/infiniscope/hivespace/"></a>
					</div>
				</div>
				<div class="col-md-8" style="overflow: hidden;" *ngIf="!authenticated">
					<button class="btn slider-overlay-center-btn" (click)="loginModelToggel()">Sign up/login for access</button>
					<div class="pint-main" id="pint-main" style="pointer-events: none; position: relative;">
						<div id="overlay"></div>
						<a data-pin-do="embedUser" data-pin-board-width="800" data-pin-scale-height="590" data-pin-scale-width="100" href="https://pinterest.com/infiniscope/hivespace/" ></a>
					</div>
				</div>
				<div class="col-md-4" style="overflow: hidden;">
					<div style="background: #fff; margin-bottom: 30px;" *ngIf="authenticated && isMobile">
						<div class="fb-group" data-href="https://www.facebook.com/groups/318377162831355" data-width="302" data-show-metadata="false"> </div>
					</div>
					<div style="background: #fff; margin-bottom: 30px;" *ngIf="authenticated && !isMobile">
						<div class="fb-group" data-href="https://www.facebook.com/groups/318377162831355" data-width="403" data-show-metadata="false"> </div>
					</div>
					<div style="margin-bottom: 30px;position: relative;" *ngIf="!authenticated">
						<button class="btn slider-overlay-center-btn" (click)="loginModelToggel()">Sign up/login for access</button>
						<div class="fb-main" id="fb-main" style="pointer-events: none; ">
							<div id="fb-overlay"></div>
							<div class="embed-responsive embed-responsive-16by9">
							  <div  class="fb-group embed-responsive-item" style="overflow-x: hidden; max-width: 100%;" data-href="https://www.facebook.com/groups/318377162831355" data-width="500" data-show-metadata="false"> </div>
							</div>
						</div>
					</div>
					<div *ngIf="post" class="blogger" style="background: #fff;">
						<a href="{{ post.url }}" target="_blank">
							<img src="assets/images/blogger.png" alt="Blogger Icon" />
						</a>
						<a href="{{ post.url }}" target="_blank">{{ post.title }}</a>
						<div class="content" [innerHTML]="post.content"></div>
						<!--
						<iframe src="https://infiniscope.blogspot.com/" title="Blogger" width="100%" height="255px"></iframe>
						-->
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="col-3 p-2 cardContainer d-lg-block d-none" *ngFor="let post of getCommunityPostsBySize(4)"> -->
			<!-- <div class="col-12 p-2 h-100 pb-5 position-relative cardContent" [ngClass]="{ authenticated: authenticated }">

			</div> -->
		<!-- </div> -->
		<!-- <div class="col-3 p-2 cardContainer d-lg-block d-none" *ngFor="let post of getCommunityPostsBySize(4)">
			<div class="col-12 p-2 h-100 pb-5 position-relative cardContent" [ngClass]="{ authenticated: authenticated }">
				<div class="col-12 mx-0 px-0 text-white font-weight-bold community-title">
					{{ post.title }}
				</div>
				<div class="col-12 mx-0 px-0 my-4 prag-text" [ngClass]="{
						'text-white': !authenticated,
						'text-yellow': authenticated
					}">
					{{ post.content }}
				</div>
				<div class="row col-12 mt-2 mx-0 pl-0 pr-3 position-absolute community-footer">
					<div class="col-12 mx-0 px-0 text-white post-name">
						{{ post.name }}
					</div>
					<div class="col-8 mx-0 px-0 date">
						{{ post.date | date: 'MMMM d, yyyy, hh:mm a' }}
					</div>
					<div class="row col-4 mx-0 px-0 post-comment-main">
						<div class="row col-6 mx-0 px-0 text-center post-comment">
							<i class="col-6 mx-0 px-0 fas fa-comment-alt"></i>
							<small class="col-6 mx-0 px-0">{{ post.comments }}</small>
						</div>
						<div class="row col-6 mx-0 px-0 text-center post-like">
							<i class="col-6 mx-0 px-0 fas fa-heart"></i>
							<small class="col-6 mx-0 px-0">{{ post.likes }}</small>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="col-6 p-2 cardContainer d-md-block d-lg-none d-none" *ngFor="let post of getCommunityPostsBySize(2)">
			<div class="col-12 p-2 h-100 pb-5 position-relative cardContent">
				<div class="col-12 mx-0 px-0 text-white font-weight-bold">
					{{ post.title }}
				</div>
				<div class="col-12 mx-0 px-0 my-4 text-white">
					{{ post.content }}
				</div>
				<div class="row col-12 mx-0 pl-0 pr-3 position-absolute">
					<div class="col-12 mx-0 px-0 text-white">
						{{ post.name }}
					</div>
					<div class="col-8 mx-0 px-0 date">
						{{ post.date | date: 'MMMM d, yyyy, hh:mm a' }}
					</div>
					<div class="row col-4 mx-0 px-0 text-white">
						<div class="row col-6 mx-0 px-0 text-center">
							<i class="col-6 mx-0 px-0 fas fa-comment-alt"></i>
							<small class="col-6 mx-0 px-0">{{ post.comments }}</small>
						</div>
						<div class="row col-6 mx-0 px-0 text-center">
							<i class="col-6 mx-0 px-0 fas fa-heart"></i>
							<small class="col-6 mx-0 px-0">{{ post.likes }}</small>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="col-12 p-2 cardContainer d-sm-block d-xs-none d-md-none d-lg-none"
			*ngFor="let post of getCommunityPostsBySize(1)">
			<div class="col-12 p-2 h-100 pb-5 position-relative cardContent">
				<div class="col-12 mx-0 px-0 text-white font-weight-bold">
					{{ post.title }}
				</div>
				<div class="col-12 mx-0 px-0 my-4 text-white">
					{{ post.content }}
				</div>
				<div class="row col-12 mx-0 pl-0 pr-3 position-absolute">
					<div class="col-12 mx-0 px-0 text-white">
						{{ post.name }}
					</div>
					<div class="col-8 mx-0 px-0 date">
						{{ post.date | date: 'MMMM d, yyyy, hh:mm a' }}
					</div>
					<div class="row col-4 mx-0 px-0 text-white">
						<div class="row col-6 mx-0 px-0 text-center">
							<i class="col-6 mx-0 px-0 fas fa-comment-alt"></i>
							<small class="col-6 mx-0 px-0">{{ post.comments }}</small>
						</div>
						<div class="row col-6 mx-0 px-0 text-center">
							<i class="col-6 mx-0 px-0 fas fa-heart"></i>
							<small class="col-6 mx-0 px-0">{{ post.likes }}</small>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
	<!-- <div *ngIf="isMobile" class="col-lg-6 col-md-6 right-title">
		<div class="heading-right">
			<div class="button-group-box">
				<a *ngIf="!authenticated" href="#" class="btn btn-join">Join the Hive!</a>
				<a *ngIf="authenticated" href="https://community.infiniscope.org/" class="btn btn-join">Go to the Hive!</a>
				<a href="#" class="btn btn-link mr-20">What's the Hive?</a>
			</div>
		</div>
	</div> -->
</div>
