import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { RequestOptions, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CacheService } from '../cache/cache.service';
import { LocalstorageService } from '../localstorage/localstorage.service';


const API_URL = environment.api_url;
@Injectable({
	providedIn: 'root'
})
export class ApiService {
	headers: any;
	options: RequestOptions;
	public token: string = '';

	constructor(
		public http: HttpClient,
		private cache: CacheService,
		private localStorage: LocalstorageService
	) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json'
		});
		this.headers = headers;
		this.checkStorage();
	}

	private checkStorage() {
		const token = this.localStorage.getItem('token');
		if (token) this.token = token;
	}

	get authenticated(): boolean {
		return Boolean(this.token);
	}

	public get user(): any {
		return JSON.parse(this.localStorage.getItem('currentUser') ?? '{}');
	}

	// Post api call without autentication or token
	submit(modal: string, myData: any): Observable<any> {
		return this.http
			.post(API_URL + modal, myData, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	// Get api call without autentication or token
	get(route: string, cache: boolean = false): Observable<any> {
		if (cache) {
			return new Observable((subscriber) => {
				subscriber.next(this.cache.getCache(route));
				this.http
					.get(API_URL + route, { headers: this.getAuthHeader() })
					.toPromise()
					.then((res) => {
						this.cache.setCache(route, res);
						subscriber.next(res);
					});
			});
		}
		return this.http
			.get(API_URL + route, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	// Post api call
	post(route: string, body?: any): Observable<any> {
		return this.http
			.post(API_URL + route, body, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	// Put api call
	put(route: string, body?: any): Observable<any> {
		return this.http
			.put(API_URL + route, body, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	// Delete api call
	delete(route: string): Observable<any> {
		return this.http
			.delete(API_URL + route, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	getAuth(modal: string, myData: any): Observable<any> {
		return this.http
			.get(API_URL + modal, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	// Post api call with access token
	postAuth(modal: string, myData: any): Observable<any> {
		return this.http
			.post(API_URL + modal, myData, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	// Put api call with access token
	putAuth(modal: string, myData: any): Observable<any> {
		return this.http
			.put(API_URL + modal, myData, { headers: this.getAuthHeader() })
			.map(this.extractData)
			.catch(this.handleError);
	}

	getBloggerPosts(): Observable<any> {
		return this.http.get("https://www.googleapis.com/blogger/v3/blogs/2204537641383108714/posts?key=AIzaSyCGNPdimFi4IQbXwtLdqqoIJtq_9G-A568")
	}

	getAuthHeader() {
		let header = new HttpHeaders();
		if (this.token) {
			header = header.set('Authorization', 'Bearer ' + this.token);
		}
		return header;
	}

	private extractData(res: Response) {
		const result = res;
		return result || {};
	}

	private handleError(error: any) {
		return throwError(error);
	}

	public upload(body): Promise<any> {
		let h: HttpHeaders = new HttpHeaders();
		if (this.token) {
			h = h.set('Authorization', 'Bearer ' + this.token);
		}
		return this.http
			.post(API_URL + 'upload', body, {
				headers: h
			})
			.toPromise();
	}
}
