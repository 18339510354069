<div class="p-3 addTagContainer" [formGroup]="form" [hidden]="confirming">
	<div>
		<label class="mb-2">Tag Name</label>
		<input
			type="text"
			(focus)="ctrl.name.markAsUntouched()"
			[ngClass]="{ 'border-danger': ctrl.name.touched && ctrl.name.errors }"
			placeholder="Enter Tag Name"
			formControlName="name"
		/>
	</div>
	<div>
		<label class="mb-2">Description</label>
		<textarea
			type="text"
			(focus)="ctrl.description.markAsUntouched()"
			style="box-sizing: content-box;"
			[ngClass]="{
				'border-danger': ctrl.description.touched && ctrl.description.errors
			}"
			placeholder="Enter Tag Description"
			formControlName="description"
			rows="5"
			maxlength="200"
		></textarea>
	</div>
	<hr />
	<div>
		<button [disabled]="!validateSubmition()" (click)="confirm()" class="blue">Submit</button>
	</div>
</div>

<div class="p-0 m-0 row confirmationTagContainer" [hidden]="!confirming">
	<div class="py-3 px-4 col-12 bg-faded-lightish">
		<h2 class="m-0">Are you sure?</h2>
	</div>
	<div class="p-4 m-0 col-12 descriptive-text">
		Clicking Confirm will add this tag to the Infiniscope Website for approval by the admin. Once added, any changes you wish to make must be made by contacting the admin.
		<br><br>
		Are you ready to confirm?
	</div>
	<div class="px-4 py-0 pb-4 m-0 col-12 row">
		<div class="pl-0 pr-1 col-6">
			<button [disabled]="loading" (click)="regress()" class="m-0 col-12 white">Cancel</button>
		</div>
		<div class="pr-0 pl-1 col-6">
			<button [disabled]="loading" (click)="submit()" class="m-0 col-12 blue">Confirm</button>
		</div>
	</div>
	<!-- <div>
		<label class="mb-2">Description</label>
		<textarea
			type="text"
			(focus)="ctrl.description.markAsUntouched()"
			style="box-sizing: content-box;"
			[ngClass]="{
				'border-danger': ctrl.description.touched && ctrl.description.errors
			}"
			placeholder="Enter Tag Description"
			formControlName="description"
			rows="5"
			maxlength="200"
		></textarea>
	</div>
	<hr />
	<div>
		<button [disabled]="loading" (click)="submit()" class="blue">Submit</button>
	</div> -->
</div>

