import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ISubscription } from 'rxjs/Subscription';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { SeeMoreComponent } from 'src/app/shared/modules/see-more/see-more/see-more.component';
import { Post } from 'src/app/model/post.model';


@Component({
	selector: 'app-slider-community',
	templateUrl: './slider-community.component.html',
	styleUrls: ['./slider-community.component.scss']
})
export class SliderCommunityComponent implements OnInit {
	public communityPosts = [];
	private subscription: ISubscription;
	public educatorModeOn: boolean = this.event.educatorMode;
	public currentPage = '';
	public isMobile: boolean = false;

	public post: Post;

	public communityTab: boolean = true;

	public date: Date = new Date();
	public events: any;

	public get authenticated() {
		return this.api.authenticated;
	}

	constructor(
		private event: EventService,
		private api: ApiService,
		private deviceService: DeviceDetectorService,
		private dialog: MatDialog,
		@Inject(PLATFORM_ID) private platformId: any,
	) {}

	async ngOnInit() {
		this.getRecentPost()
		this.subscription = this.event.currentData.subscribe((data: any) => {
			if (data.dataobj) this.currentPage = data.dataobj.page;
			if (data.action === 'set_flag_mode') {
				this.educatorModeOn = data.dataobj.educatorModeOn;
			}
		});
		/*
		if(isPlatformBrowser(this.platformId)) {
			this.forum.getTopics()
				.then((posts) => this.processTopics(posts))
				.catch(err => console.log(err));
		}
		*/

		this.isMobile = this.deviceService.isMobile();
		await this.getEvents();
	}

	// on click show model popup
	toggelModel: boolean = false;
	public async loginModelToggel() {
		// this.toggelModel = !this.toggelModel;
		const dialog = await this.dialog.open(SeeMoreComponent, {
			width: '600px',
			panelClass: 'addTagModal'
		});
		dialog.afterClosed().subscribe((lesson) => {});
	}

	private processTopics(topics: any[]) {
		const titles: string[] = [
			'Featured Post',
			'Most Recent Post',
			'Top Post',
			'Featured Event'
		];
		topics
			.map((t) => t.topics)
			.forEach((t, i) => {
				if (t.length === 0) {
					this.communityPosts.push({
						title: titles[i],
						content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
						name: 'Community Member',
						date: new Date(),
						comments: Math.round(Math.random() * 99),
						likes: Math.round(Math.random() * 99)
					});
				} else {
					this.communityPosts.push({
						title: titles[i],
						content: t[0].title,
						name: t[0].user.displayname,
						date: new Date(t[0].timestamp),
						comments: t[0].postcount,
						likes: t[0].upvotes
					});
				}
			});
	}

	public getCommunityPostsBySize(size: number = 4) {
		return this.communityPosts.slice(0, size);
	}

	// Unsubscribe event before leave component
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	public getUserTemp() {

	}

	async getEvents() {
		await this.api.get('events?_limit=12&_sort=date:desc').toPromise().then((res) => {
			this.events = res;
		});
	}

	async getRecentPost() {
		await this.api.getBloggerPosts().toPromise().then(async (res) => {
			const posts: Post[] = res.items;
			this.post = posts[0];
		});
		
	}

	openLink(url: string, sameTab: boolean) {
		if(sameTab) {
			window.open(url, '_self')
		} else {
			window.open(url,'_blank')
		}
	}
}
