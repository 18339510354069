import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentDisplayerComponent } from './content-displayer/content-displayer.component';
import { TechnicalDetailsModule } from '../technical-details/technical-details.module';

@NgModule({
	declarations: [ContentDisplayerComponent],
	imports: [CommonModule, TechnicalDetailsModule],
	exports: [ContentDisplayerComponent]
})
export class ContentDisplayerModule {}
