import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lesson } from 'src/app/model/lesson.model';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/event/event.service';
import { environment } from 'src/environments/environment';
import marked from 'marked';
import { MiniMetricsService } from 'src/app/services/minimetrics/minimetrics.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from 'src/app/shared/modules/info-modal/info-modal/info-modal.component';

@Component({
	selector: 'app-lesson',
	templateUrl: './lesson.component.html',
	styleUrls: ['./lesson.component.scss']
})
export class LessonComponent implements OnInit, AfterViewInit {
	public lesson: Lesson;
	public apiUrl = environment.api_url;
	loadFlag: boolean = false;
	id: any;
	error: any;
	sub: any;
	public likes: number = 0;
	public shares: number = 0;
	public watches: number = 0;
	public bookmarks: number = 0;

	constructor(
		private event: EventService,
		private router: Router,
		public api: ApiService,
		private route: ActivatedRoute,
		private miniMetricService: MiniMetricsService,
		private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private dialog: MatDialog
	) {
		const actionOb = {
			action: 'set_page',
			redirect_to: '',
			dataobj: { page: this.router.url }
		};
		this.event.globalEvent(actionOb);
	}

	ngOnInit(): void {
		this.loadFlag = true;
		this.sub = this.route.params.subscribe((params) => {
			this.id = params.id;
		});

		this.getLessonData(this.id);
		document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });

		this.authenticationService.refreshToken();
	}

	ngAfterViewInit() {
		this.route.queryParams.subscribe(params => {
			this.error = params.error;
			console.log(this.error)
			if(this.error) {
				this.dialog.open(InfoModalComponent, {
					width: "600px",
					panelClass: 'addTagModal',
					data: {
						title: 'Error',
						msg: this.error,
					}
				});

			}
		});
	}

	getLessonData(id) {
		this.likes = 0;
		this.shares = 0;
		this.watches = 0;
		this.bookmarks = 0;

		this.api
			.get('lessons/' + id, null)
			.toPromise()
			.then((res) => {
				this.likes += res.likes || 0;
				console.log(`Likes are ${this.likes}`)
				this.bookmarks += res.bookmarks || 0;
				this.watches += res.watches || 0;
				this.shares += res.shares || 0;
				this.likes += res.likes || 0;
				this.lesson = {
					...res,
					description: marked(res.description || ''),
					centralQuestion: marked(res.centralQuestion || ''),
					instructionalObjective: marked(res.instructionalObjective || ''),
					learningOutcomes: marked(res.learningOutcomes || ''),
					prerequisites: marked(res.prerequisites || ''),
				};
			});
		this.miniMetricService.getMiniMetrics('lesson', id).subscribe((res) => {
			this.likes += res.likes;
			this.bookmarks += res.bookmarks;
			this.shares += res.shares;
			this.watches += res.watches;
		});
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}
}
