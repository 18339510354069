<div class="col-auto text-right">
	<div role="status" aria-live="polite"></div>
	<div class="banner-bottom-share" aria-label="lesson counts">
		<ul>
			<!--
			<li>
				<button>
					<i class="fa fa-bookmark"></i>
					{{ bookmarks }}
				</button>
			</li>
			-->
			<li>
				<button (click)="toggleLike.emit()" aria-label="favorites" disabled>
					<i class="fa fa-heart"></i>
					{{ likes }}
				</button>
			</li>
			<li>
				<button aria-label="shares" disabled>
					<i class="fa fa-share-alt"></i>
				</button>
			</li>
		</ul>
	</div>
</div>
