import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-lesson-left',
	templateUrl: './lesson-left.component.html',
	styleUrls: ['./lesson-left.component.scss']
})
export class LessonLeftComponent implements OnInit {
	@Input() lesson: any;
	@Input() watches: number = 0;
	@Input() shares: number = 0;

	ngOnInit(): void {}
}
