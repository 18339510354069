import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
	ControlContainer,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup
} from '@angular/forms';

@Component({
	selector: 'app-checkbox-group',
	templateUrl: './checkbox-group.component.html',
	styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent implements OnInit, OnChanges {
	@Input() name: string;
	@Input() id: string;
	@Input() data: string;
	@Input() checkeds: boolean;
	@Input() noPadding: boolean = false;
	@Input() educatorMode: boolean = false;
	@Output() updateData: EventEmitter<any> = new EventEmitter();
	searchform: FormGroup;

	constructor(
		private fb: FormBuilder,
		private controlContainer: ControlContainer,
		private changeRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.searchform = <FormGroup>this.controlContainer.control;
	}

	onCheckboxChange(e) {
		const checkArray: FormArray = this.searchform.get(this.name) as FormArray;

		if (e.target.checked) {
			checkArray.push(new FormControl(e.target.value));
		} else {
			let i: number = 0;
			checkArray.controls.forEach((item: FormControl) => {
				if (item.value == e.target.value) {
					checkArray.removeAt(i);
					return;
				}
				i++;
			});
		}

		this.updateData.emit(this.controlContainer.value);
	}

	ngOnChanges(changes: SimpleChanges): void {

	}
}
