<section
	class="gallary-section"
	*ngIf="data"
	[ngClass]="{ dark: data.darkMode }"
>
	<div class="main-title">
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-12">
					<div class="spacer"></div>
					<h2>{{ data.title }}</h2>
					<div class="spacer"></div>
					<h3>{{ data.subtitle }}</h3>
					<div class="spacer"></div>
					<p>{{ data.description }}</p>
					<div class="spacer"></div>
				</div>
			</div>
		</div>
	</div>

	<!-- Card  -->
	<div class="container">
		<!-- Card Item -->
		<ul class="page-thumb card">
			<!-- list item -->
			<li *ngFor="let item of data.Items">
				<button class="page-thumb-item">
					<img [src]="item.photo.url" [alt]="item.photo.alternativeText" />
					<div class="caption-item">
						<h3 class="name">{{ item.name }}</h3>
						<h4 class="title">{{ item.role }}</h4>
					</div>
				</button>
			</li>
		</ul>
	</div>

	<!-- Gallery Pop Up -->
	<div class="gallery">
		<!-- close button -->
		<div class="main-close-gallery">
			<button class="close-btn" onclick="closeGallery()">
				<img src="assets/images/close_white_24dp.svg" alt=" " />
			</button>
		</div>

		<!-- navigation -->
		<div class="main-nav-gallery">
			<button class="prev-btn" onclick="prevSlide()">
				<img src="assets/images/chevron_left_white_24dp.svg" alt=" " />
			</button>

			<button class="next-btn" onclick="nextSlide()">
				<img src="assets/images/chevron_right_white_24dp.svg" alt=" " />
			</button>

			<button class="prev-btn-thumb" onclick="prevThumb()">
				<img src="assets/images/arrow_circle_left_white_24dp.svg" alt=" " />
			</button>

			<button class="next-btn-thumb" onclick="nextThumb()">
				<img src="assets/images/arrow_circle_right_white_24dp.svg" alt=" " />
			</button>
		</div>

		<!-- gallery slider -->
		<div class="gallery-view">
			<div class="gallery-slider">
				<div class="item-list">
					<!-- item 01 -->
					<div
						class="item"
						[ngClass]="{ active: i == 0 }"
						*ngFor="let item of data.Items; let i = index"
					>
						<div class="item-content">
							<div
								class="image-item"
								style="background-image: url('{{ item.photo.url }}');"
							></div>

							<div class="caption-item">
								<h3 class="name">{{ item.name }}</h3>
								<h4 class="title">{{ item.role }}</h4>
								<div class="spacer"></div>
								<div [innerHTML]="item.bio | safeHtml | markdown"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Thumbnail Slider -->

		<div class="slider-thumb-view">
			<ul class="slider-thumb js-thumb">
				<!-- list thumbnail -->

				<li
					[ngClass]="{ active: i == 0 }"
					*ngFor="let item of data.Items; let i = index"
				>
					<button
						class="thumb-img"
						style="background: url('{{ item.photo.url }}');"
					></button>
				</li>
			</ul>
		</div>
	</div>
</section>
