<div class="row">
	<div class="col-lg-12">
		<div class="banner-top">
			<h1>{{ title }}</h1>
			<h2>
				<b>Release Date {{ createdAt | date: 'longDate' }}</b>
				<span>Version 1.0</span>
			</h2>
		</div>
	</div>
</div>
