import { type } from 'os';
import { environment } from 'src/environments/environment';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from 'src/app/services/API/api.service';
import { WindowRef } from 'src/app/services/windowRef/window-ref.service';
import { LocalstorageService } from 'src/app/services/localstorage/localstorage.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'app-tools',
	templateUrl: './tools.component.html',
	styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {
	public domain: string;
	public creative: any;
	public hivespace: any;
	public public_url: string = environment.infiniscope_url

	// public get isMobile(): boolean {
	// 	return this.deviceService.isMobile();
	// }

	public focused: boolean = false;
	public communityId: number = environment.community_id;

	constructor(
		private api: ApiService,
		public window: WindowRef,
		public localStorage: LocalstorageService,
		public router: Router,
		@Inject(PLATFORM_ID) private platformId: any,
		// private auth: AuthenticationService,
		// private deviceService: DeviceDetectorService
	) {
		// this.auth.currentUser.subscribe((user) => (this.user = user));
	}

	get authenticated(): boolean {
		return this.api.authenticated;
	}

	ngOnInit(): void {
		this.getTools();
	}

	getTools() {
		this.api.get('tools').toPromise().then((res: any) => {
			res.tool = res.tool.map(tool => {
				if (tool.name === 'Blogger') {
					tool.allowUnauthorized = true;
				}
				return tool;
			});
			this.creative = res.tool.filter((f) => f.type == 'creative');
			this.hivespace = res.tool.filter((f) => f.type == 'hivespace');
		});
	}

	public partnerLinkClicked(link: string) {
		this.goToPage(`${link}?id_token=${this.localStorage.getItem('cognito_id_token')}&community_id=${this.communityId}&error_url=${this.public_url + this.router.url}`);
	}

	public goToPage(url: string) {
		if (!url) return;
		if(isPlatformBrowser(this.platformId)) {
			this.window.nativeWindow.open(url, '_blank');
		}
	}
}
